import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import installablePlugins from "./InstallablePlugins";

export const PluginInstallerContext = React.createContext();

export const phases = {
	UNSET: 0,
	SETTING_SITE: 1,
	FETCHING_EMAIL: 2,
	INSTALLING_PLUGIN: 3,
	REVOKING_TOKEN: 4,
	FINISHED_INSTALLING: 5,
	INSTALLATION_FAILURE: 6,
};

/**
 * @param {object} children the children.
 *
 * @returns {object} the children, with access to the context.
 */
export const PluginInstallerContextProvider = ( { children } ) => {
	const currentUrl = new URL( window.location.href );

	const siteParameter      = currentUrl.searchParams.get( "site_url" ) || "";
	const userLoginParameter = currentUrl.searchParams.get( "user_login" ) || "";
	const passwordParameter  = currentUrl.searchParams.get( "password" ) || "";

	const [ site, setSite ]           = useState( siteParameter );
	const [ userLogin, setUserLogin ] = useState( userLoginParameter );
	const [ password, setPassword ]   = useState( passwordParameter );

	// Determine the phase based on the presence of the query parameters.
	let startingPhase = phases.SETTING_SITE;
	if ( siteParameter && userLoginParameter && passwordParameter ) {
		startingPhase = phases.FETCHING_EMAIL;
	}

	const [ phase, setPhase ] = useState( startingPhase );

	// Set in the state, and as default, because it is possible we will expand this with other plugins in the future.
	const [ pluginToInstall ]                                         = useState( installablePlugins.find( plugin => plugin.isDefaultSelection ) );
	const [ pluginInstallationSuccess, setPluginInstallationSuccess ] = useState( false );

	/**
	 * Deletes the user_login and password query parameters from the URL.
	 * This is done to:
	 * 1. Prevent the user credentials from being included in referrer headers for any requests made by the browser on this page.
	 * 2. prevent the user from accidentally sharing their credentials.
	 */
	useEffect( () => {
		if ( ! currentUrl.searchParams.has( "password" ) ) {
			return;
		}
		const newUrl = new URL( currentUrl );
		newUrl.searchParams.delete( "user_login" );
		newUrl.searchParams.delete( "password" );
		window.history.replaceState( window.history.state, "", newUrl );
	}, [ currentUrl ] );

	const contextValue = {
		pluginToInstall,
		phase,
		setPhase,
		site,
		setSite,
		userLogin,
		setUserLogin,
		password,
		setPassword,
		pluginInstallationSuccess,
		setPluginInstallationSuccess,
	};

	return (
		<PluginInstallerContext.Provider value={ contextValue }>
			{ children }
		</PluginInstallerContext.Provider>
	);
};

PluginInstallerContextProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
