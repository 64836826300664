import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import { Button, Label } from "@yoast/ui-library";

import * as styles from "./styles.scss";

const messages = defineMessages( {
	heading: {
		id: "siteDangerZone.heading",
		defaultMessage: "Disconnect this site",
	},
	siteRemovalExplanation: {
		id: "siteDangerZone.siteDisconnect.explanation",
		defaultMessage: "This will revoke the licenses of the active plugins listed" +
				" above and remove the site from your MyYoast account. This" +
				" will NOT delete your actual website or the plugins" +
				" themselves. You can always re-add the plugins later.",
	},
	siteRemovalButtonText: {
		id: "siteDangerZone.siteDisconnect.buttonText",
		defaultMessage: "Disconnect",
	},
	siteRemovalLoadingButtonText: {
		id: "siteDangerZone.siteDisconnect.loadingButtonText",
		defaultMessage: "Disconnecting site...",
	},
} );

/**
 * Creates Site danger zone element
 *
 * @param {object} props Properties for this element.
 * @returns {ReactElement} SiteDangerZone element.
 * @constructor
 */
function SiteDangerZone( props ) {
	const buttonText = props.removing ?
			props.intl.formatMessage( messages.siteRemovalLoadingButtonText ) :
			props.intl.formatMessage( messages.siteRemovalButtonText );

	return (
			<div className={ styles.container }>
				<Label
					label={ props.intl.formatMessage( messages.heading ) }
				/>
				<p>{ props.intl.formatMessage( messages.siteRemovalExplanation ) }</p>
				<div className={ styles.buttonWrapper }>
					<Button
						variant="error"
						disabled={ props.removing }
						onClick={ props.onRemove }
					>
						{ buttonText }
					</Button>
				</div>
			</div>
	);
}

SiteDangerZone.propTypes = {
	onRemove: PropTypes.func.isRequired,
	removing: PropTypes.bool.isRequired,
	intl: intlShape.isRequired,
};

export default injectIntl( SiteDangerZone );
