import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as styles from "./ResetPasswordSuccessPageStyles.scss";
import logo from "../../images/my-yoast-academy-logo.svg";
import icon from "../../images/greeting.png";
import { Button, Title } from "@yoast/ui-library";
import { NavLink } from "react-router-dom";

const messages = defineMessages( {
	successTitle: {
		id: "reset.title",
		defaultMessage: "Success!",
	},
	successMessage: {
		id: "reset.message",
		defaultMessage: "You've changed your password successfully. Click on the button bellow to login to MyYoast using your new password",
	},
	continueButton: {
		id: "continue.button",
		defaultMessage: "Continue to MyYoast",
	},
} );

/**
 * Page to show the password change was succeeded.
 */
class ResetPasswordSuccessPage extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<div className={ styles.firstColumn }>
				<img src={ logo } alt="" />
				<img src={ icon } alt="MyYoast" />
				<Title size="2">
					<FormattedMessage { ...messages.successTitle } />
				</Title>
				<p>
					<FormattedMessage { ...messages.successMessage } />
				</p>

				<div>
					<Button as={ NavLink } to="/login">
						<FormattedMessage { ...messages.continueButton } />
					</Button>
				</div>
			</div>
		);
	}
}
export default injectIntl( ResetPasswordSuccessPage );

ResetPasswordSuccessPage.propTypes = {
	intl: intlShape.isRequired,
};
