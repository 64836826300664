/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
/* Internal dependencies */
import { emailConstraints } from "./CommonConstraints";
import logo from "../../images/my-yoast-academy-logo.svg";
import ValidationInputField from "../ValidationInputField";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { Title, Button, Link } from "@yoast/ui-library";
import { NavLink } from "react-router-dom";
import * as styles from "./ResetPasswordEmailPageStyles.scss";

const messages = defineMessages( {
	passwordResetTitle: {
		id: "reset.password",
		defaultMessage: "Password reset",
	},
	labelEmail: {
		id: "email.address",
		defaultMessage: "Email address",
	},
	emailAddressMessage: {
		id: "email.address.message",
		defaultMessage: "Please enter your email address. You will receive a link to create a new password via email.",
	},
	sendButton: {
		id: "reset.button",
		defaultMessage: "Send password reset email",
	},
	loadingButton: {
		id: "reset.button.loading",
		defaultMessage: "Sending password reset email...",
	},
	backButton: {
		id: "back.button",
		defaultMessage: "Back to login form",
	},
} );

/**
 * Page to send a email to reset the password of an account.
 */
class ResetPasswordEmailPage extends React.Component {
	/**
	 * Constructor.
	 *
	 * @param {object} props The props.
	 */
	constructor( props ) {
		super( props );

		// Default state.
		this.state = {
			email: this.props.email,
			errors: [],
			validating: false,
		};

		this.handleSubmit = this.handleSubmit.bind( this );

		this.onUpdateEmail = this.onUpdateEmail.bind( this );

		// Validation constraints.
		this.constraints = {
			email: emailConstraints( this.props.intl ),
		};
	}

	/**
	 * Updates the specified field in the state,
	 * to be used as callback functions in text input fields.
	 *
	 * @param {string} value the input field value.
	 * @param {array} errors The email related errors.
	 * @param {boolean} validating Whether or not the email is in the process of being validated.
	 *
	 * @returns {void}
	 */
	onUpdateEmail( value, errors = [], validating = false ) {
		this.setState( {
			email: value,
			errors: errors,
			validating: validating,
		} );
	}

	/**
	 * Provides the email address to send a pass word reset link to.
	 *
	 * @param { object } event The button click event.
	 *
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		if ( this.canSubmit() === false ) {
			return;
		}
		this.props.attemptResetPasswordEmail( { email: this.state.email } );
	}

	/**
	 * Checks whether or not the form may be submitted.
	 *
	 * @returns {boolean} Whether or not the form may be submitted
	 */
	canSubmit() {
		return ! isEmpty( this.state.email ) && this.state.errors.length === 0 && ! this.props.loading && ! this.state.validating;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		if ( this.props.passwordRequestSent ) {
			return ( <Redirect to={ "/forgot-password/check-your-email" } /> );
		}

		let buttonText = messages.sendButton;

		if ( this.props.loading ) {
			buttonText = messages.loadingButton;
		}
		return (
			<div className={ styles.firstColumn }>
				<img src={ logo } alt="MyYoast - Yoast Academy" />
				<Title size="2">
					<FormattedMessage { ...messages.passwordResetTitle } />
				</Title>
				<p>
					<FormattedMessage { ...messages.emailAddressMessage } />
				</p>
				<form onSubmit={ this.handleSubmit }>
					<ErrorDisplay error={ this.props.error } />
					<ValidationInputField
						id="email"
						name="email"
						type="email"
						label={ this.props.intl.formatMessage( messages.labelEmail ) }
						errors={ [] }
						onChange={ this.onUpdateEmail }
						constraint={ emailConstraints( this.props.intl ) }
						value={ this.state.email }
					/>

					<div className={ styles.actions }>
						<Button
							type="submit"
							disabled={ ! this.canSubmit() }
							aria-disabled={ ! this.canSubmit() }
						>
							<FormattedMessage { ...buttonText } />
						</Button>
						<Link as={ NavLink } to="../login">
							<FormattedMessage { ...messages.backButton } />
						</Link>
					</div>
				</form>
			</div>
		);
	}
}

ResetPasswordEmailPage.propTypes = {
	intl: intlShape.isRequired,
	email: PropTypes.string,
	passwordRequestSent: PropTypes.bool,
	loading: PropTypes.bool,
	error: ErrorPropTypeShape,
	attemptResetPasswordEmail: PropTypes.func.isRequired,
};

ResetPasswordEmailPage.defaultProps = {
	email: "",
	passwordRequestSent: false,
	loading: false,
	error: null,
};

export default injectIntl( ResetPasswordEmailPage );
