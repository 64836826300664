/* eslint-disable max-len */

import { defineMessages } from "react-intl";
import React from "react";
import classNames from "classnames";

const messages = defineMessages( {
	informationRetrievalQuestion: {
		id: "pluginInstaller.faq.informationRetrieval",
		defaultMessage: "What information does the Yoast installer retrieve?",
	},
	howLongQuestion: {
		id: "pluginInstaller.faq.howLong",
		defaultMessage: "How long will the Yoast installer have access to my site and what will it do?",
	},
	passwordStorageQuestion: {
		id: "pluginInstaller.faq.passwordStorage",
		defaultMessage: "Does Yoast store the password and if yes, where and for how long?",
	},
	securityMeasuresQuestion: {
		id: "pluginInstaller.faq.securityMeasures",
		defaultMessage: "What security measures did Yoast take to ensure a safe installation?",
	},
	whatTasksQuestion: {
		id: "pluginInstaller.faq.whatTasks",
		defaultMessage: "What tasks does the Yoast installer perform to install the Yoast plugin automatically?",
	},
	workWithCustomPermalink: {
		id: "pluginInstaller.faq.workWithCustomPermalink",
		defaultMessage: "Does the installer work on sites with a custom admin permalink?",
	},
} );

// Answers to the FAQ questions. Not as an intl message because they include can include html, and getting that to look proper is a pain.
const answers = {
	informationRetrievalAnswer: (
		<>
			<p>
				{ /* eslint-disable-next-line react/no-unescaped-entities */ }
				By completing the installation flow, the installer will have had access to the following information:
				<ul>
					<li>The URL of the target website.</li>
					<li>The user&apos;s e-mail address logged into the target website.</li>
					<li>The application password to the target website.</li>
				</ul>
			</p>
			<p className={ classNames( "yst-font-bold", "yst-mt-2" ) }>
				Note that Yoast does not keep any of this data after installation is complete.
			</p>
		</>
	),
	howLongAnswer: (
		<p>
			The Yoast installer has the same access and permissions as the user who allowed the access, but only for the short time needed to install Yoast (usually a few seconds). The installer automatically adds the Yoast plugin to your website and instantly revokes its own access.
		</p>
	),
	passwordStorageAnswer: (
		<p>
			We do not store any password in any way.
		</p>
	),
	securityMeasuresAnswer: (
		<p>
			<ol>
				<li>We aim to keep a permanent record of every request we send to the site using their credentials. This record includes:
					<ul>
						<li>The URL we used</li>
						<li>The request method (e.g., GET, POST, PUT, and DELETE)</li>
						<li>When the request started</li>
						<li>When the request is finished</li>
						<li>The HTTP status code returned from the website</li>
						<li>Error messages and response bodies.</li>
					</ul>
				</li>
				<li>The users’ credentials are used solely to retrieve the email address associated with the account on the users’ site. If the installation is aborted or the credentials cannot be revoked automatically, we will email the users advising manual removal of the credentials.</li>
				<li>We prevent any application password from being logged or stored on our infrastructure.</li>
				<li>We only request information from the website necessary to perform the installation and revoke the credentials afterward.</li>
				<li>If an error is detected during the installation/activation process, the plugin will try automatically deactivate to prevent any disruption to the site.</li>
				<li>The Yoast installer is exclusively deployed to install Yoast plugins</li>
			</ol>
		</p>
	),
	whatTasksAnswer: (
		<p>
			<ol>
				<li>Get access using the provided credentials.</li>
				<li>Get the contact email address (store along with the site URL for about 15 minutes)</li>
				<li>Complete the installation of the Yoast plugin.</li>
				<li>Revoke our access. This is typically done within five seconds under normal circumstances.This might take longer on slower sites. If this fails, the user will receive an email asking them to revoke our access manually.</li>
				<li>Remove the stored email address and site URL.</li>
				<li>Once the above steps are finished or the installer tab is closed, we delete all the stored credentials.</li>
			</ol>
		</p>
	),
	workWithCustomPermalinkAnswer: (
		<p>
			Yes, only if you are logged into your WordPress admin. If you receive a 404 or another error, try logging in to your WordPress from your admin account and restart the installer.
		</p>
	),
};

const FAQData  = [
	{
		question: messages.informationRetrievalQuestion,
		answer: answers.informationRetrievalAnswer,
	},
	{
		question: messages.howLongQuestion,
		answer: answers.howLongAnswer,
	},
	{
		question: messages.passwordStorageQuestion,
		answer: answers.passwordStorageAnswer,
	},
	{
		question: messages.securityMeasuresQuestion,
		answer: answers.securityMeasuresAnswer,
	},
	{
		question: messages.whatTasksQuestion,
		answer: answers.whatTasksAnswer,
	},
	{
		question: messages.workWithCustomPermalink,
		answer: answers.workWithCustomPermalinkAnswer,
	},
];

export default FAQData;
