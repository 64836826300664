import React, { useContext, useEffect, useState } from "react";
import { phases, PluginInstallerContext } from "./PluginInstallerContext";
import { Button, Paper, Spinner, Title } from "@yoast/ui-library";
import { defineMessages, FormattedMessage } from "react-intl";
import * as styles from "./styles.scss";
import classNames from "classnames";
import { doRequest, getMyYoastHost, prepareInternalRequest } from "../../../../shared-frontend/functions/api";

const messages = defineMessages( {
	title: {
		id: "pluginInstaller.pluginInstallProcessor.title",
		defaultMessage: "Install {pluginName}",
	},
	titleInstallationSuccessful: {
		id: "pluginInstaller.pluginInstallProcessor.titleInstallationSuccessful",
		defaultMessage: "{pluginName} is installed! 🎉",
	},
	progressInstallingAndActivating: {
		id: "pluginInstaller.pluginInstallProcessor.progressInstallingAndActivating",
		defaultMessage: "Installing & activating plugin…",
	},
	progressRevokingCredentials: {
		id: "pluginInstaller.pluginInstallProcessor.progressRevokingCredentials",
		defaultMessage: "Revoking credentials…",
	},
	firstTimeConfigurationButton: {
		id: "pluginInstaller.pluginInstallProcessor.firstTimeConfigurationButton",
		defaultMessage: "Start improving your SEO!",
	},
	installationFailedMessage: {
		id: "pluginInstaller.pluginInstallProcessor.installationFailedMessage",
		defaultMessage: "We were unable to install the plugin.",
	},
	restartInstallationButton: {
		id: "pluginInstaller.pluginInstallProcessor.restartInstallationButton",
		defaultMessage: "Return to plugin installation start",
	},
	userDoesNotHaveCapability: {
		id: "pluginInstaller.pluginInstallProcessor.userDoesNotHaveCapability",
		defaultMessage: "You do not have the required role to install the plugin on the site. " +
			"Please make sure you are logged in with a user that has the capability to install plugins. " +
			"This is usually the site administrator.",
	},
	invalidCredentials: {
		id: "pluginInstaller.pluginInstallProcessor.invalidCredentials",
		defaultMessage: "The access is either not given to us or the access has been revoked. " +
			"Please restart the plugin installation using the button below, and give us access again.",
	},
	genericInstallationFailure: {
		id: "pluginInstaller.pluginInstallProcessor.genericInstallationFailure",
		defaultMessage: "The installation failed. Please try again later.",
	},
	updateNotice: {
		id: "pluginInstaller.pluginInstallProcessor.updateNotice",
		defaultMessage: "A version of {pluginName} was already installed on this site. " +
			"Please check for available updates in your WordPress admin area.",
	},
} );

const DOES_NOT_HAVE_CAPABILITY_ERROR_CODE = "DOES_NOT_HAVE_THE_CAPABILITY_ON_THE_SITE";
const INVALID_CREDENTIALS_ERROR_CODE      = "INVALID_CREDENTIALS";

// All error codes that can be returned by the API, mapped to their corresponding messages.
const mapErrorCodeToMessage = {
	[ DOES_NOT_HAVE_CAPABILITY_ERROR_CODE ]: messages.userDoesNotHaveCapability,
	[ INVALID_CREDENTIALS_ERROR_CODE ]: messages.invalidCredentials,
};

/**
 * Component for rendering the plugin installation process.
 * @returns {Element} The component.
 */
// eslint-disable-next-line complexity,require-jsdoc
const PluginInstallProcessor = () => {
	const { pluginToInstall, site, phase, setPhase, userLogin, password } = useContext( PluginInstallerContext );

	const [ installationErrorCode, setInstallationErrorCode ]                     = useState( "" );
	const [ installationAttemptErrorMessage, setInstallationAttemptErrorMessage ] = useState( "" );
	const [ showUpdateNotice, setShowUpdateNotice ]                             = useState( false );

	/**
	 * Returns the API URL for the given route.
	 * @param {string} route The route.
	 * @returns {string} The API URL.
	 */
	function getRoute( route ) {
		return `PluginInstallation/${ route }`;
	}

	/**
	 * Handles the response.
	 * @param {object} response The response.
	 * @returns {void} nothing.
	 */
	function handleResponse( response ) {
		const errorCode = response.errorCode;

		if ( ! errorCode ) {
			setInstallationAttemptErrorMessage( messages.genericInstallationFailure );
		}

		setInstallationErrorCode( errorCode );

		if ( response.success === false ) {
			if ( Object.keys( mapErrorCodeToMessage ).includes( errorCode ) ) {
				setInstallationAttemptErrorMessage( mapErrorCodeToMessage[ errorCode ] );
			}
		}

		setShowUpdateNotice( response.wasAlreadyInstalled === true );
	}

	/**
	 * Fetches the contact email for the given site.
	 * @param {object} body The body of the request.
	 * @returns {Promise<null|string>} The contact email, or null if it could not be fetched.
	 */
	async function fetchContactEmail( body ) {
		// getContactEmail
		const apiURL       = getRoute( "getContactEmail" );
		try {
			const responseJson = await doRequest( prepareInternalRequest( apiURL, "POST", body, { signal: AbortSignal.timeout( 15000 ) } ) );
			if ( responseJson.success === true ) {
				return responseJson.contactEmail;
			}
		} catch ( error ) {
			// Fail silently. We will not be able to send an email about the installation failure.
			console.error( error );
			return null;
		}

		return null;
	}

	/**
	 * Installs and activates the plugin.
	 * @param {object} body The body of the request.
	 * @returns {Promise<boolean>} Whether the installation was successful.
	 */
	async function installAndActivatePlugin( body ) {
		const apiURL         = getRoute( "installAndActivatePlugin" );

		let responseJson;
		try {
			responseJson = await doRequest( prepareInternalRequest( apiURL, "POST", body, { signal: AbortSignal.timeout( 15000 ) } ) );
		} catch ( error ) {
			console.error( error );
			setInstallationAttemptErrorMessage( messages.genericInstallationFailure );
		}

		handleResponse( responseJson );

		return responseJson.success;
	}

	/**
	 * Revokes the token.
	 * @param {object} body The body of the request.
	 * @returns {Promise<boolean>} Whether the token was revoked.
	 */
	async function revokeToken( body ) {
		const apiURL = getRoute( "revokeToken" );

		let responseJson;
		try {
			responseJson = await doRequest( prepareInternalRequest( apiURL, "POST", body, { signal: AbortSignal.timeout( 15000 ) } ) );
		} catch ( error ) {
			// Fail silently. The user will be informed of the token revocation failure via the fallback.
			console.error( error );
			return false;
		}
		return responseJson.success;
	}

	/**
	 * Handles the installation flow.
	 * @returns {Promise<void>} A promise that resolves when the installation flow is done.
	 */
	async function handleInstallationFlow() {
		const body = {
			siteUrl: site,
			credentials: {
				username: userLogin,
				password: password,
			},
			plugin: pluginToInstall.slug,
		};

		const email = await fetchContactEmail( body );
		if ( email ) {
			body.contactEmail = email;
		}


		setPhase( phases.INSTALLING_PLUGIN );

		let installationSuccess = false;
		try {
			installationSuccess = await installAndActivatePlugin( body );
		} catch ( error ) {
			setInstallationAttemptErrorMessage( messages.genericInstallationFailure );
		} finally {
			setPhase( phases.REVOKING_TOKEN );

			// If the credentials are invalid, there should be no token to revoke.
			if ( installationErrorCode !== INVALID_CREDENTIALS_ERROR_CODE ) {
				// If the token revocation fails, we send an email about it.
				await revokeToken( body );
			}
		}

		if ( installationSuccess ) {
			setPhase( phases.FINISHED_INSTALLING );
			return;
		}

		setPhase( phases.INSTALLATION_FAILURE );
	}

	// Start the installation process on mount.
	useEffect( () => {
		handleInstallationFlow();
	}, [] );

	/**
	 * Returns the progress message based on the current phase.
	 * @returns {object|null} The progress message, or null if no message should be shown.
	 */
	function fetchingProgressMessage() {
		switch ( phase ) {
			case phases.FETCHING_EMAIL:
			case phases.INSTALLING_PLUGIN:
				return messages.progressInstallingAndActivating;
			case phases.REVOKING_TOKEN:
				return messages.progressRevokingCredentials;
			default:
				return null;
		}
	}

	/**
	 * Returns the link to the installation flow.
	 * @returns {void} nothing.
	 */
	function redirectToInstallationFlow() {
		let link = site;
		// ensure trailing slash
		if ( ! link.endsWith( "/" ) ) {
			link += "/";
		}

		link = `${ link }wp-admin/admin.php?page=wpseo_dashboard#/first-time-configuration`;

		window.location.href = link;
	}

	/**
	 * Redirects to the plugin installer start.
	 * @returns {void} nothing.
	 */
	function redirectToPluginInstallerStart() {
		let myYoastBase = getMyYoastHost();

		// trim trailing slashes
		if ( myYoastBase.endsWith( "/" ) ) {
			myYoastBase = myYoastBase.slice( 0, -1 );
		}

		window.location.href = `${ myYoastBase }/automatic-install`;
	}

	const progressMessage = fetchingProgressMessage();

	const isInProgress           = [
		phases.FETCHING_EMAIL,
		phases.INSTALLING_PLUGIN,
		phases.REVOKING_TOKEN,
	].includes( phase );
	const isFinishedSuccessfully = phase === phases.FINISHED_INSTALLING;
	const hasFailed              = phase === phases.INSTALLATION_FAILURE;

	const titleMessage = isFinishedSuccessfully ? messages.titleInstallationSuccessful : messages.title;

	return (
		<Paper className={ classNames( styles.paper, styles.fullWidth ) }>
			<div className={ styles.siteForm }>
				<Title
					level={ 2 }
					className={ classNames( "yst-title", ( ( isFinishedSuccessfully || hasFailed ) ? "" : styles.centralAligned ) ) }
				>
					<FormattedMessage
						{ ...titleMessage }
						values={ { pluginName: pluginToInstall.displayName } }
					/>
				</Title>

				{ isInProgress && <div>
					<div className={ classNames( styles.fullWidth, styles.loadingState, "yst-pt-6" ) }>
						<Spinner variant={ "primary" } size={ "8" } />
					</div>
					<div className={ classNames( styles.centralAligned, styles.pinkText, "yst-pt-3" ) }>
						{ progressMessage && <p><FormattedMessage { ...progressMessage } /></p> }
					</div>
				</div> }

				{
					hasFailed && <div className={ classNames( styles.fullWidth, "yst-pt-6" ) }>
						<p><FormattedMessage { ...messages.installationFailedMessage } /></p>
						{
							installationAttemptErrorMessage &&
							<p className={ "yst-mt-2" }><FormattedMessage { ...installationAttemptErrorMessage } /></p>
						}

						<div className={ "yst-mt-4" }>
							<Button
								size="default"
								type="button"
								onClick={ () => redirectToPluginInstallerStart() }
							>
								<FormattedMessage { ...messages.restartInstallationButton } />
							</Button>
						</div>
					</div>
				}

				{ isFinishedSuccessfully && <div>
					<div className={ "yst-mt-4" }>
						<strong>Site</strong>
						<p>{ site }</p>
					</div>

					<div className={ "yst-mt-4" }>
						<strong>Status</strong>
						<p className={ styles.greenText }>Installed</p>
						{ showUpdateNotice &&
							<p><FormattedMessage { ...messages.updateNotice } values={ { pluginName: pluginToInstall.displayName } } /></p>
						}
					</div>

					<div className={ "yst-mt-4" }>
						<Button
							size="default"
							type="button"
							onClick={ () => redirectToInstallationFlow() }
						>
							<FormattedMessage { ...messages.firstTimeConfigurationButton } />
						</Button>
					</div>
				</div> }

			</div>
		</Paper>
	);
};

export default PluginInstallProcessor;
