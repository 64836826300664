import PropTypes from "prop-types";
import React from "react";
import * as styles from "./SitesStyles.scss";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/outline";
import { Button } from "@yoast/ui-library";
import { defineMessages, injectIntl, intlShape } from "react-intl";

const messages = defineMessages( {
	manage: {
		id: "site.overview.manage", defaultMessage: "Manage",
	},
} );

/**
 * Returns the rendered Sites component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered Sites component.
 */
function Sites( props ) {
	return ( <ul id="sites-list" className={ styles.sitesList }>
		{ props.sites.map( function( site ) {
			/**
			 * A callback for when the manage button is clicked for a specific site.
			 *
			 * @returns {void}
			 */
			const onManageHandler = () => {
				props.onManage( site.id );
			};

			return ( <li
				key={ site.id }
				className={ styles.sitesListItem }
			>
				<GlobeAsiaAustraliaIcon className={ styles.siteIcon } />
				<p className={ styles.siteName }>{ site.siteName }</p>
				<div className={ styles.siteActions }>
					<Button
						size="small"
						variant="tertiary"
						onClick={ onManageHandler }
					>
						{ props.intl.formatMessage( messages.manage ) }
					</Button>
				</div>
			</li> );
		} ) }
	</ul> );
}

export default injectIntl( Sites );

Sites.propTypes = {
	sites: PropTypes.arrayOf( PropTypes.object ),
	onManage: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
};

Sites.defaultProps = {
	sites: [],
};
