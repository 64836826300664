import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link, Title } from "@yoast/ui-library";
import * as styles from "./DownloadsPageStyles.scss";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Downloads } from "./Downloads";

const messages = defineMessages( {
	downloadPageTitle: {
		id: "downloads.page.title",
		defaultMessage: "Downloads",
	},
	downloadPageDescription: {
		id: "downloads.page.description",
		defaultMessage: "Need help installing Yoast plugins? Read our { link }.",
	},
	installationGuides: {
		id: "downloads.page.installationGuides",
		defaultMessage: "installation guides",
	},
} );

/**
 * Returns the rendered Downloads Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The downloads page.
 */
const DownloadsPage = ( props ) => {
	return (
		<>
			<div className={ styles.downloadsPageHeader }>
				<Title as="h1" size="1">
					<FormattedMessage { ...messages.downloadPageTitle } />
				</Title>
				<p>
					<FormattedMessage
						{ ...messages.downloadPageDescription }
						values={ {
							link: <Link
								className={ styles.downloadPageLink } target="_blank"
								href="https://yoa.st/myyoast-installation"
							>
								<span>{ props.intl.formatMessage( messages.installationGuides ) } </span>
								<ArrowTopRightOnSquareIcon className={ styles.downloadPageLinkIcon } />
							</Link>,
						} }
					/>
				</p>
			</div>
			<Downloads { ...props } />
		</>
	);
};

DownloadsPage.propTypes = {
	intl: intlShape.isRequired,
};

export default injectIntl( DownloadsPage );
