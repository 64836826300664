import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { SidebarNavigation } from "@yoast/ui-library";
import * as styles from "./styles.scss";
import { NavLink } from "react-router-dom";
import { Logo } from "../Logo";
import UserStatus from "../../containers/UserStatus";
import { UserIcon } from "@heroicons/react/24/outline";

/**
 *
 * @param {Object} props The props to use.
 *
 * @returns {Element} The rendered menu items.
 */
const MenuItems = props => {
	return <div>
		{
			props.menuRoutes
				.filter( page => page.showInMenu )
				.map( page => {
					const title = props.intl.formatMessage( page.title );
					const label = (
						<span className={ styles.menuItem }>
							<span className="yst--ml-1">{ page.iconSource }</span>
							{ title }
							{ page.isExternal && page.externalLinkIconSource }
						</span>
					);

					return page.isExternal ? (
						<SidebarNavigation.SubmenuItem
							as="a"
							id={ `main-nav__${ page.titleKey }` }
							key={ page.titleKey }
							label={ label }
							href={ page.pathname.pathname }
							target="_blank"
						/>
					) : (
						<SidebarNavigation.SubmenuItem
							as={ NavLink }
							exact={ true }
							id={ `main-nav__${ page.titleKey }` }
							key={ page.titleKey }
							label={ label }
							to={ page.path }
						/>
					);
				} )
		}

		<SidebarNavigation.MenuItem
			icon={ UserIcon }
			id="submenuitem-sidebar-1"
			label="Account"
			defaultOpen={ true }
		>
			{
				props.menuRoutes
					.filter( page => page.showInAccountMenu )
					.map( page => {
						const title = props.intl.formatMessage( page.title );
						const label = (
							<span className={ styles.menuItem }>
								{ page.iconSource } { title }
								{ page.isExternal && page.externalLinkIconSource }
							</span>
						);

						return page.isExternal ? (
							<SidebarNavigation.SubmenuItem
								as="a"
								id={ `main-nav__${ page.titleKey }` }
								key={ page.titleKey }
								label={ label }
								href={ page.pathname.pathname }
								target="_blank"
							/>
						) : (
							<SidebarNavigation.SubmenuItem
								as={ NavLink }
								exact={ true }
								id={ `main-nav__${ page.titleKey }` }
								key={ page.titleKey }
								label={ label }
								to={ page.path }
							/>
						);
					} )
			}
		</SidebarNavigation.MenuItem>
	</div>;
};

/**
 * The main menu.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered component.
 */
const MainMenu = props => {
	return (
		<div className={ styles.sideMenu }>
			<SidebarNavigation>
				<div className={ styles.mobileMenu }>
					<SidebarNavigation.Mobile
						openButtonId="button-open-settings-navigation-mobile"
						closeButtonId="button-close-settings-navigation-mobile"
						openButtonScreenReaderText="Open menu"
						closeButtonScreenReaderText="Close menu"
						aria-label="Menu"
					>
						<MenuItems menuRoutes={ props.menuRoutes } intl={ props.intl } />
					</SidebarNavigation.Mobile>
				</div>
				<div className={ styles.menuHeader }>
					<Logo context="sidebar" size="200px" />
					<UserStatus />
				</div>
				<SidebarNavigation.Sidebar className={ styles.desktopMenu }>
					<MenuItems menuRoutes={ props.menuRoutes } intl={ props.intl } />
				</SidebarNavigation.Sidebar>
			</SidebarNavigation>
		</div>
	);
};

export default injectIntl( MainMenu );

MainMenu.propTypes = {
	menuRoutes: PropTypes.array.isRequired,
	intl: intlShape.isRequired,
};
