import React, { useEffect, useState } from "react";
import { PageSection } from "../../PageSection";

import * as styles from "./styles.scss";

export const SubscriptionActionSection = ( props ) => {
  const getLines = ( lines ) => {
    return lines.map( ( line, i ) => {
      if( ! line ) {
        return;
      }
      return (
        <div className={ styles.detailsLine } key={ `section-${i}` }>
          { line.action &&
          <div className={ styles.lineAction }>
            { line.action }
          </div>
          }
        </div>
      )
    } );
  }

  return (
    <PageSection title={ props.title }>
      <div className={ styles.subscriptionActionSection }>
        { getLines( props.lines ) }
      </div>
    </PageSection>
  );
}
