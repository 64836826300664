import { connect } from "react-redux";

import SubscriptionCancel from "../components/Subscription/SubscriptionCancel";
import { cancelSubscription } from "shared-frontend/redux/actions/subscriptions";
import PropTypes from "prop-types";
import { getCancelSubscriptionState } from "../redux/selectors/ui/subscriptions";


/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	const subscriptionsCancelState = getCancelSubscriptionState( state );

	return {
		loading: subscriptionsCancelState.cancelLoading,
		success: subscriptionsCancelState.cancelSuccess,
		error: subscriptionsCancelState.cancelError,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		cancelSubscription: ( subscriptionId, amount ) => {
			dispatch( cancelSubscription( subscriptionId, amount ) );
		},
	};
};

const SubscriptionEditModalContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SubscriptionCancel );

SubscriptionEditModalContainer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default SubscriptionEditModalContainer;
