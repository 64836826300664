/* External dependencies */
import { createSelector } from "reselect";
import _flatMap from "lodash/flatMap";
import without from "lodash/without";


/* Internal dependencies */
import { createAllOfEntitySelector, createEntityByIdSelector } from "./factories";

/**
 * Returns all subscriptions in the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All subscriptions.
 */
export const getSubscriptions = createAllOfEntitySelector( "subscriptions" );

/**
 * Returns all subscriptions in the state ordered by ID.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All subscriptions ordered by ID.
 */
export const getAllSubscriptionsById = createEntityByIdSelector( "subscriptions" );


/**
 * Returns the subscriptions that are active or pending-cancel.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The subscriptions that are active or pending-cancel.
 */
export const getValidSubscriptions = createSelector(
	getSubscriptions,
	subscriptions => {
		return subscriptions
			.filter( subscription => subscription.status === "active" || subscription.status === "pending-cancel" );
	},
);

/**
 * Returns the non-provisioned subscriptions that are active or pending-cancel.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The subscriptions that are active or pending-cancel.
 */
export const getActivatableSubscriptions = createSelector(
	getValidSubscriptions,
	subscriptions => {
		return subscriptions
			.filter( subscription => ! subscription.provisionerId );
	},
);

/**
 * Returns the subscriptions that are active.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The subscriptions that are active.
 */
export const getActiveSubscriptions = createSelector(
	getActivatableSubscriptions,
	subscriptions => subscriptions.filter( subscription =>
		subscription.status === "active",
	),
);

/**
 * Returns the subscriptions that will need to be manually renewed within the month to remain active for the renewalNotification container.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All active subscriptions that require manual renewal, have a renewal URL and  expire within the month.
 */
export const getUpcomingRenewalSubscriptions = createSelector(
	getActiveSubscriptions,
	subscriptions => {
		let upcomingRenewals = subscriptions.map( subscription => {
			const nextPayment  = subscription.nextPayment ? new Date( subscription.nextPayment ) : null;
			const endDate      = subscription.endDate ? new Date( subscription.endDate ) : null;
			const monthFromNow = new Date();
			monthFromNow.setMonth( monthFromNow.getMonth() + 1 );

			const expiresWithinMonth = ( nextPayment && nextPayment < monthFromNow ) || ( endDate && endDate < monthFromNow );
			const isUpcomingRenewal  = subscription.renewalSecret &&
				expiresWithinMonth &&
				subscription.requiresManualRenewal;

			if ( ! isUpcomingRenewal ) {
				return null;
			}

			return {
				id: subscription.id,
				name: subscription.name,
				hasNextPayment: subscription.nextPayment !== null,
				nextPayment: nextPayment || endDate,
				endDate: subscription.endDate ? new Date( subscription.endDate ) : null,
				status: subscription.status,
				subscriptionNumber: subscription.subscriptionNumber,
				renewalSecret: subscription.renewalSecret,
				product: subscription.product,
				limit: subscription.limit,
			};
		} );

		// Removing nulls from the array.
		upcomingRenewals = without( upcomingRenewals, null );

		// Sorting upcoming renewals by nextPayment date.
		upcomingRenewals = upcomingRenewals.sort( ( a, b ) => {
			return new Date( a.nextPayment || a.endDate ) - new Date( b.nextPayment || b.endDate );
		} );

		return upcomingRenewals;
	},
);

export const getActivatableSubscriptionsWithProductInformation = createSelector(
	getValidSubscriptions,
	subscriptions => subscriptions.map( subscription => {
		return {
			...subscription,
			productLogo: subscription.product.icon,
			price: subscription.product.price,
		};
	} ),
);

export const getProductSwitches = createSelector(
	getSubscriptions,
	( subscriptions ) => {
		if ( ! subscriptions ) {
			return {};
		}
		return _flatMap( subscriptions, subscription => subscription.productSwitches );
	},
);
