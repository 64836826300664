import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import logo from "../../images/my-yoast-academy-logo.svg";
import icon from "../../images/greeting.png";
import { Title } from "@yoast/ui-library";
import * as styles from "./SendResetEmailSuccessPageStyles.scss";

const messages = defineMessages( {
	successMessageTitle: {
		id: "reset.message.title",
		defaultMessage: "Check your email!",
	},
	successMessage: {
		id: "reset.message",
		defaultMessage: "We've sent you an email with a link to reset your password, so check your inbox to continue.",
	},
} );

/**
 * Page to show the password change was succeeded.
 */
class ResetPasswordSuccessPage extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<div className={ styles.firstColumn }>
				<img src={ logo } alt="" />
				<img src={ icon } alt="MyYoast" />
				<Title size="2">
					<FormattedMessage { ...messages.successMessageTitle } />
				</Title>
				<p>
					<FormattedMessage { ...messages.successMessage } />
				</p>
			</div>
		);
	}
}

ResetPasswordSuccessPage.propTypes = {
	intl: intlShape.isRequired,
	image: PropTypes.string,
};

ResetPasswordSuccessPage.defaultProps = {
	image: icon,
};

export default injectIntl( ResetPasswordSuccessPage );
