import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import logo from "../../images/my-yoast-academy-logo.svg";
import { Title } from "@yoast/ui-library";

const messages = defineMessages( {
	header: {
		id: "signup.defaultAside.header",
		defaultMessage: "Welcome!",
	},
	message: {
		id: "signup.defaultAside.message",
		defaultMessage: "MyYoast is the portal to all things Yoast. Whether you want to comment on a post, " +
			"take our free SEO for beginners training or find a product you have purchased: it's all there!",
	},
} );

/**
 * Display's the left side with the Signup branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function DefaultSignupAside() {
	return (
		<>
			<img className="asideLogo" src={ logo } alt="MyYoast - Yoast Academy" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.message } />
			</p>
		</>
	);
}

export default injectIntl( DefaultSignupAside );
