import { connect } from "react-redux";
import Install from "../components/install/Install";
import { mapDispatchToProps, mapStateToProps, mergeProps } from "./SitesPage";
import { getUserId } from "../redux/selectors/ui/user";

export default connect(
	( state ) => (
		{
			...mapStateToProps( state ),
			customerId: getUserId( state ),
		}
	),
	mapDispatchToProps,
	mergeProps,
)( Install );
