import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Alert } from "@yoast/ui-library";

import SubscriptionTable, { SubscriptionProps } from "./SubscriptionsTable";
import Search from "../Search/Search";
import { speak } from "@wordpress/a11y";
import util from "util";
import _debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import SuggestedAction from "../SuggestedAction";
import { PageHeader } from "../PageHeader";
import SubscriptionDetailModal from "../modal/SubscriptionDetailModal";
import { ShopButton } from "../ShopButton";
import noop from "lodash/noop";
import useRequest from "../../reactHooks/useRequest";
import { getUserId } from "shared-frontend/functions/auth";

import * as styles from "./styles.scss";

const messages = defineMessages( {
	pageSubscriptionsLoaded: {
		id: "menu.account.subscriptions.loaded",
		defaultMessage: "Account subscriptions page loaded",
	},
	searchLabel: {
		id: "search.label.subscriptions",
		defaultMessage: "Search subscriptions",
	},
	searchResults: {
		id: "subscriptionsSearch.results",
		defaultMessage: "Number of subscriptions found: %d",
	},
	manageLicenseElsewhere: {
		id: "subscriptions.manageLicenceElsewhere",
		defaultMessage: "Subscriptions bought via a third party are managed by that provider.",
	},
	subscriptionsHeader: {
		id: "subscriptions.description",
		defaultMessage: "You can easily add and manage your subscriptions here. " +
				"Whether you're looking to renew your subscription(s) for another year, " +
				"add a site to your subscription(s), upgrade your subscription(s) to a bundle, " +
				"switch payment methods, or even bid farewell with a cancellation, " +
				"you're in the right place.",
	},
	subscriptionsHeaderTitle: {
		id: "subscriptions.title",
		defaultMessage: "Subscriptions",
	},
} );

const debouncedSpeak = _debounce( speak, 1000 );

/**
 * Returns the rendered SubscriptionsPage component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered SubscriptionsPage component.
 */
const SubscriptionsPage = ( props ) => {
	const [ modalSubscription, setModalSubscription ] = useState( null );
	const userId = getUserId();
	const { data: licenses } = useRequest( `Customers/${userId}/licenses` );

	useEffect( () => {
		props.loadData();

		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.pageSubscriptionsLoaded );
		speak( message );
	}, [] );

	useEffect( () => {
		if ( props.query.length > 0 && props.query ) {
			// Combine grouped and individual subscriptions objects and count them.
			const combinedResultCount = [
				Object.values( props.needsAttentionSubscriptions ),
				Object.values( props.provisionedSubscriptions ),
				Object.values( props.regularSubscriptions ),
				Object.values( props.inactiveSubscriptions ),
			].flat( 2 ).length;

			const message = util.format( props.intl.formatMessage( messages.searchResults ), combinedResultCount );
			debouncedSpeak( message, "assertive" );
		}
	}, [ props.query ] );


	/**
	 * Return the search bar.
	 *
	 * @returns {ReactElement} The rendered Search component.
	 */
	const renderSearch = () => {
		return <div className={ styles.searchWrapper } key="subscriptionSearch">
			<Search
				id="search"
				searchLabel={ props.intl.formatMessage( messages.searchLabel ) }
				descriptionId="search-description"
				query={ props.query }
				onChange={ props.onSearchChange }
			/>
		</div>;
	};

	const noSubscriptionsParagraphs = [
		<FormattedMessage
			id="subscriptions.noSubscriptions.manage"
			key="subscriptions.noSubscriptions.manage"
			defaultMessage="When you buy one of our plugins or services, you start a new subscription which will be billed
			annually. At the end of each billing cycle you can choose to renew your subscription or let it expire."
		/>,
		<FormattedMessage
			id="subscriptions.noSubscriptions.pressButton"
			key="subscriptions.noSubscriptions.pressButton"
			defaultMessage="You don’t seem to have any subscriptions yet, so press the button below to visit our shop."
		/>,
	];

	const noSearchResultsParagraphs = [
		<FormattedMessage
			id="subscriptions.search.noResults"
			key="subscriptions.search.noResults"
			defaultMessage={ "We could not find any subscriptions matching { query }." }
			values={ { query: <strong>{ props.query }</strong> } }
		/>,
	];

	const notices = [];
	if ( licenses && licenses.length > 0 ) {
		notices.push( <Alert key="license-notice" variant="info">
			<FormattedMessage { ...messages.manageLicenseElsewhere } />
		</Alert> );
	}

	const needsAttentionSubscriptions = <SubscriptionTable
		{ ...props }
		subscriptions={ props.needsAttentionSubscriptions }
		needsAttention={ true }
		showDetailsModal={ setModalSubscription }
	/>;

	const regularSubscriptions = <SubscriptionTable
		{ ...props }
		subscriptions={ props.regularSubscriptions }
		isGrouped={ true }
	/>;

	const provisionedSubscriptions = <SubscriptionTable
		{ ...props }
		subscriptions={ props.provisionedSubscriptions }
		isProvisioned={ true }
	/>;

	const inactiveSubscriptions = <SubscriptionTable
		{ ...props }
		subscriptions={ props.inactiveSubscriptions }
		isInactive={ true }
		showDetailsModal={ setModalSubscription }
	/>;

	const hasRegularSubscriptions = ! isEmpty( props.regularSubscriptions );
	const hasAttentionSubscriptions = ! isEmpty( props.needsAttentionSubscriptions );
	const hasProvisionedSubscriptions = ! isEmpty( props.provisionedSubscriptions );
	const hasInactiveSubscriptions = ! isEmpty( props.inactiveSubscriptions );

	if ( hasRegularSubscriptions ||
			hasAttentionSubscriptions ||
			hasProvisionedSubscriptions ||
			hasInactiveSubscriptions ) {
		return <>
			<PageHeader title={ messages.subscriptionsHeaderTitle } message={ messages.subscriptionsHeader } />
			<div className={ styles.subcriptionsPage }>
				{ modalSubscription && <SubscriptionDetailModal
					onClose={ () => setModalSubscription( null ) }
					subscription={ modalSubscription }
					modalOpen={ true }
				/> }
				{ notices }
				{ renderSearch() }
				{ hasAttentionSubscriptions ? needsAttentionSubscriptions : null }
				{ hasRegularSubscriptions ? regularSubscriptions : null }
				{ hasProvisionedSubscriptions ? provisionedSubscriptions : null }
				{ hasInactiveSubscriptions ? inactiveSubscriptions : null }
			</div>
		</>;
	} else if ( props.query.length > 0 ) {
		return <>
			<PageHeader title={ messages.subscriptionsHeaderTitle } message={ messages.subscriptionsHeader } />
			<div className={ styles.subcriptionsPage }>
				{ notices }
				{ renderSearch() }
				<SuggestedAction
					paragraphs={ noSearchResultsParagraphs }
				/>
			</div>
		</>;
	}

	return <>
		<PageHeader title={ messages.subscriptionsHeaderTitle } message={ messages.subscriptionsHeader } />
		<div className={ styles.subcriptionsPage }>
			{ notices }
			<SuggestedAction paragraphs={ noSubscriptionsParagraphs }>
				<div className={ styles.shopButton }>
					<ShopButton size="large" />
				</div>
			</SuggestedAction>
		</div>
	</>;
};

SubscriptionsPage.propTypes = {
	onSearchChange: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
	query: PropTypes.string,
	loadData: PropTypes.func,
	provisionerData: PropTypes.object,
	needsAttentionSubscriptions: SubscriptionProps,
	provisionedSubscriptions: SubscriptionProps,
	regularSubscriptions: SubscriptionProps,
	inactiveSubscriptions: SubscriptionProps,
};

SubscriptionsPage.defaultProps = {
	loadData: noop,
	query: "",
	provisionerData: {},
	needsAttentionSubscriptions: [],
	provisionedSubscriptions: [],
	regularSubscriptions: [],
	inactiveSubscriptions: [],
};

export default injectIntl( SubscriptionsPage );
