import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";
import { generateRenewalUrl } from "../../../functions/generateRenewalUrl";
import { getShopUrl } from "shared-frontend/functions/products";
import { Link } from "@yoast/ui-library";

import { messages } from "./messages";
import * as styles from "./styles.scss";

const SubscriptionDetails = ( props ) => {
	const subscription = props.subscription;

	if ( subscription.status === "on-hold" ) {
		const clickHandler = () => props.showDetailsModal( subscription );

		return <>
			<div className={ styles.error }>{ props.intl.formatMessage( messages.paymentFailed ) }</div>
			<Link as="button" onClick={ clickHandler }>{ props.intl.formatMessage( messages.seeDetails ) }</Link>
		</>;
	}

	const goToYoastSubscriptionsPage = getShopUrl() + "/subscriptions";
	const subscriptionIsMonthly = subscription.name.includes( "(monthly)" );
	const date = subscription.hasEndDate ? subscription.endDate : subscription.nextPayment;
	const OneMonthAgo = new Date();
	OneMonthAgo.setMonth( OneMonthAgo.getMonth() - 1 );
	const expiredLessThanOneMonthAgo = new Date( date ).getTime() > OneMonthAgo.getTime();
	const statuses = [ "expired", "cancelled", "refunded" ];
	const expired = statuses.includes( subscription.status ) ? "Expired" : "Expires";

	const subscriptionCanBeRenewed = [ "active", "on-hold" ].includes( subscription.status ) ||
			( ( ! subscriptionIsMonthly ) && subscription.status === "expired" && expiredLessThanOneMonthAgo );

	let linkText, linkClass, linkTo;
	if ( subscriptionCanBeRenewed ) {
		linkTo = generateRenewalUrl( subscription );
		linkText = props.intl.formatMessage( messages.manualRenewMessage );
		linkClass = "renew_now_button_subs_page";
	} else {
		linkTo = subscriptionIsMonthly ? goToYoastSubscriptionsPage : createCartUrlForSubscription( subscription );
		linkText = props.intl.formatMessage( messages.buyNewMessage );
		linkClass = "buy_subscription_button_subs_page";
	}

	return (
		<>
			<div className={ styles.error }>
				<FormattedMessage
					id="details.redMessageText"
					defaultMessage="{ expired } { date }"
					values={ {
						expired: expired,
						date: date.toLocaleDateString( "en-US", {
							year: "numeric",
							month: "short",
							day: "numeric",
						} ),
					} }
				/>
			</div>
			<Link href={ linkTo } className={ linkClass }>{ linkText }</Link>
		</>
	);
};


export default injectIntl( SubscriptionDetails );
