import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedDate, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Alert, Button, Modal } from "@yoast/ui-library";

import { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { doRequest, getApiUrl, prepareInternalRequest } from "shared-frontend/functions/api";

import * as styles from "./styles.scss";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";

const messages = defineMessages( {
	ariaLabel: {
		id: "addSiteToSubscription.modal.arialabel",
		defaultMessage: "Add site",
	},
	header: {
		id: "addSiteToSubscription.modal.header",
		defaultMessage: "Add site",
	},
	body: {
		id: "addSiteToSubscription.modal.body",
		defaultMessage: "Add an extra site and only pay for the remaining time period.",
	},
	cancel: {
		id: "addSiteToSubscription.modal.cancel",
		defaultMessage: "Cancel",
	},
	confirm: {
		id: "addSiteToSubscription.modal.confirm",
		defaultMessage: "Add site",
	},
	loading: {
		id: "addSiteToSubscription.modal.loading",
		defaultMessage: "Creating order...",
	},
	productHeading: {
		id: "addSiteToSubscription.modal.productSellLine",
		defaultMessage: "Add an extra site for only:",
	},
	productHeadingPrice: {
		id: "addSiteToSubscription.modal.productPrice",
		defaultMessage: "{upgradePrice} (ex. VAT and possible discounts)",
	},
	productBlockName: {
		id: "addSiteToSubscription.modal.productName",
		defaultMessage: "{productName}",
	},
	productBlockBilling: {
		id: "addSiteToSubscription.modal.productBilling",
		defaultMessage: "Next renewal: {nextPaymentDate}",
	},
	productBlockNewLimit: {
		id: "addSiteToSubscription.modal.productNewLimit",
		defaultMessage: "You will upgrade from {limit} to {newLimit} sites",
	},
	refundWarning: {
		id: "addSiteToSubscription.modal.refundWarning",
		defaultMessage: "By clicking the 'Add site' button, I agree to the",
	},
	refundLinkText: {
		id: "addSiteToSubscription.modal.refundLinkText",
		defaultMessage: "refund policy regarding upgrades",
	},

} );

/**
 * Returns the rendered Subscription Upgrade Modal component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered SubscriptionUpgradeModal component.
 */
function AddSiteToSubscriptionModal( props ) {
	const { subscriptionId, subscription, request } = useContext( SubscriptionDetailContext );
	const [ upgradePrice, setUpgradePrice ] = useState( "" );
	const [ isLoading, setIsLoading ] = useState( false );
	const hasBeenRedirectedToCheckout = useRef( false );

	// Check the status for the customer when the component is mounted.
	useEffect( () => {
		if ( ! props.isOpen ) {
			return;
		}

		const request = prepareInternalRequest(
			`Subscriptions/calculateExpansionPrice/${ subscriptionId }/${ subscription.subscriptionNumber }`,
			"GET",
			{},
		);

		/**
		 * Fires the request.
		 *
		 * @returns {void}
		 */
		const fireRequest = async() => {
			setIsLoading( true );
			try {
				const { upgradePriceWithCurrency } = await doRequest( request );
				setUpgradePrice( upgradePriceWithCurrency );
			} catch ( err ) {
				// This is an error that the user doesn't need to be informed of. The default will be the subscribe button.
			} finally {
				setIsLoading( false );
			}
		};
		fireRequest();
	}, [ props.isOpen ] );

	/**
	 * Redirects to the checkout.
	 *
	 * @returns {void}
	 */
	function redirectToCheckout() {
		if ( hasBeenRedirectedToCheckout.current ) {
			return;
		}
		hasBeenRedirectedToCheckout.current = true;
		// eslint-disable-next-line max-len
		window.location = subscription.actions.addSite.url;
	}

	const confirmButtonText = messages.confirm;
	const nextBilling = <FormattedDate
		value={ subscription.validUntil }
		year="numeric"
		month="long"
		day="2-digit"
	/>;

	return (
		<Modal
			isOpen={ props.isOpen }
			onClose={ props.onClose }
			aria-label={ messages.ariaLabel.defaultMessage }
		>
			<Modal.Panel>
				<Modal.Title>
					<FormattedMessage { ...messages.header } />
				</Modal.Title>
				<div className={ styles.subtitle }>
					<FormattedMessage { ...messages.body } />
				</div>
				<div className={ styles.upgradeSubscriptionCard }>

					{ subscription.included.inSubscriptionGroup.products.map( includedProduct => {
						return <div className={ styles.productInfo } key={ subscriptionId }>
							<img
								src={ includedProduct.icon }
								alt={ includedProduct.name }
							/>
							<div>
								<p>
									<FormattedMessage
										{ ...messages.productBlockName }
										values={ { productName: includedProduct.name } }
									/>
								</p>
								<p>
									<strong>
										<FormattedMessage
											{ ...messages.productBlockBilling }
											values={ { nextPaymentDate: nextBilling } }
										/>
									</strong>
								</p>
								<p style={ { opacity: 0.75 } }>
									<i>
										<FormattedMessage
											{ ...messages.productBlockNewLimit }
											values={ {
												newLimit: includedProduct.limit + 1,
												limit: includedProduct.limit,
											} }
										/>
									</i>
								</p>
							</div>
						</div>;
					} ) }

					<div className={ styles.upgradePriceHeader }>
						<p>
							<strong>
								<FormattedMessage { ...messages.productHeading } />
							</strong>
								&nbsp;
							<strong>
								{ ! isLoading && <FormattedMessage
									{ ...messages.productHeadingPrice }
									values={ { upgradePrice: upgradePrice } }
								/> }
							</strong>
						</p>
					</div>

					<div className={ styles.refundWarning }>
						<p>
							<FormattedMessage { ...messages.refundWarning } />
								&nbsp;
							<a href="https://yoast.com/refund-policy/" target="_blank" rel="noopener noreferrer">
								<FormattedMessage { ...messages.refundLinkText } />
							</a>
						</p>
					</div>
				</div>
				{ props.error && <Alert variant="error">{ props.error.message } </Alert> }
				<div className={ styles.actionButtonsContainer }>
					<Button variant="secondary" onClick={ props.onClose }>
						<FormattedMessage { ...messages.cancel } />
					</Button>
					<Button
						variant="primary"
						type="submit"
						onClick={ redirectToCheckout }
					>
						<FormattedMessage { ...confirmButtonText } />
					</Button>
				</div>
			</Modal.Panel>
		</Modal>
	);
}

AddSiteToSubscriptionModal.propTypes = {
	intl: intlShape.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	error: ErrorPropTypeShape,
};

AddSiteToSubscriptionModal.defaultProps = {
	isOpen: false,
};

export default injectIntl( AddSiteToSubscriptionModal );
