import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import _isEmpty from "lodash/isEmpty";
import { Badge, Button, Card, Link, Modal, Title, useToggleState } from "@yoast/ui-library";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import * as styles from "./DownloadItemStyles.scss";
import { downloadShape } from "./proptypes";
import ComposerHelp from "./ComposerHelp";

const messages = defineMessages( {
	downloadButton: {
		id: "product.buttons.download",
		defaultMessage: "Download",
	},
	version: {
		id: "product.product.version",
		defaultMessage: "Version {version}",
	},
	changelog: {
		id: "product.product.changelog",
		defaultMessage: "Changelog",
	},
	composer: {
		id: "product.product.composer",
		defaultMessage: "Install with Composer",
	},
} );

/**
 * The Product component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered Product component.
 */
function DownloadItem( { download } ) {
	const [ composerModalIsOpen, , , openComposerModal, closeComposerModal ] = useToggleState( false );
	return (
		<>
			<Card>
				<div className={ styles.downloadItemHeader }>
					<Title as="h2" size="4">{ download.name.replace( "plugin", "" ).trim() }</Title>
				</div>
				<Card.Content>
					<div className={ styles.downloadItemMeta }>
						<Badge as="span" variant="plain">
							<FormattedMessage { ...messages.version } values={ { version: download.version } } />
						</Badge>
						{ ! _isEmpty( download.changelogUrl ) &&
							<p>
								<Link
									as="a"
									href={ download.changelogUrl }
									target="_blank"
									className={ styles.downloadItemLink }
								>
									<FormattedMessage { ...messages.changelog } />
									<ArrowTopRightOnSquareIcon className={ styles.downloadItemLinkIcon } />
								</Link>
							</p>
						}
					</div>
					{ ! _isEmpty( download.icon ) &&
						<img
							className={ styles.downloadItemImage } src={ download.icon }
							alt=""
							height="300"
							width="300"
						/>
					}
				</Card.Content>
				<Card.Footer className={ styles.downloadItemFooter }>
					<p>
						<Button as="a" href={ download.url }>
							<ArrowDownTrayIcon className={ styles.downloadItemButtonIcon } />
							<FormattedMessage { ...messages.downloadButton } values={ { name: download.name } } />
						</Button>
					</p>
					{ ! _isEmpty( download.composerHandle ) &&
						<>
							<p>or</p>
							<p>
								<Link as="button" onClick={ openComposerModal }>
									<FormattedMessage { ...messages.composer } />
								</Link>
							</p>
						</>
					}
				</Card.Footer>
			</Card>
			{ ! _isEmpty( download.composerHandle ) &&
				<Modal
					isOpen={ composerModalIsOpen }
					onClose={ closeComposerModal }
				>
					<Modal.Panel>
						<ComposerHelp
							onClose={ closeComposerModal }
							productName={ download.name }
							composerHandle={ download.composerHandle }
						/>
					</Modal.Panel>
				</Modal>
			}
		</>
	);
}

DownloadItem.propTypes = {
	download: downloadShape.isRequired,
	intl: intlShape.isRequired,
};

export default injectIntl( DownloadItem );
