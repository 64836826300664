import React, { Component } from "react";
import classNames from "classnames";
import DocumentTitle from "react-document-title";
import { getDocumentTitle } from "../helpers";
import * as styles from "./styles.scss";

/**
 * Wraps a component in a layout with a white background
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The layout with a white background with the wrapped component.
 */
export default ( WrappedComponent, documentTitle ) => {
	return class LightGreyLayout extends Component {
		/**
     * Renders the component
     *
     * @returns {ReactElement} The rendered component.
     */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<div className={ styles.layoutLightGrey }>
						<div className={ classNames( styles.main, styles.lightGrey ) }>
							<WrappedComponent { ...this.props } />
						</div>
					</div>
				</DocumentTitle>
			);
		}
	};
};
