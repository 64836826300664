import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import { orderBy } from "lodash/collection";
import {
	ArrowPathIcon,
	ChevronDoubleUpIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	CreditCardIcon,
	PlusIcon,
} from "@heroicons/react/24/outline";
import { Button, Link, Table } from "@yoast/ui-library";

import AnimatedLoader from "../Loader";
import { PageHeader } from "../PageHeader";
import { PageSection } from "../PageSection";
import { BackButton } from "../BackButton";
import { SubscriptionSection } from "./SubscriptionSection";
import { SubscriptionActionSection } from "./SubscriptionActionSection";
import InvoicesDownloadContainer from "../../containers/InvoicesDownload";
import { UpgradeSubscriptionModal } from "./UpgradeSubscription";
import SubscriptionEditModal from "../../containers/SubscriptionEditModal";
import AddSiteToSubscriptionModal from "./AddSite";
import formatAmount from "../../functions/currency";
import { messages } from "./messages";
import * as styles from "./styles.scss";
import ErrorDisplay from "../../errors/ErrorDisplay";
import { SubscriptionDetailContext } from "../../context/SubscriptionDetailContext";
import { getAccessToken } from "shared-frontend/functions/auth";

const SubscriptionPage = ( props ) => {
	const [ isCoursesOpen, setCoursesIsOpen ] = useState( false );
	const [ isPluginsOpen, setPluginsIsOpen ] = useState( false );
	const [ addSiteModalOpen, setAddSiteModalOpen ] = useState( false );
	const [ upgradeToBundleModalOpen, setUpgradeToBundleModalOpen ] = useState( false );
	const [ cancelModalOpen, setCancelModalOpen ] = useState( false );
	const { subscription,  request: { isLoading, error } } = useContext( SubscriptionDetailContext );

	const getFormattedStatus = ( status ) => {
		const buttonClass = classNames( styles.status, { [ styles.active ]: status === "active" } );
		return <Button className={ buttonClass } size="small" as="span" disabled={ true }>{ status }</Button>;
	};

	const formatDate = ( date ) => {
		if ( ! date ) {
			return "";
		}

		return <FormattedDate
			value={ new Date( date ) }
			year="numeric"
			month="long"
			day="2-digit"
		/>;
	};

	if ( ! subscription && isLoading ) {
		return <AnimatedLoader />;
	}
	if ( error ) {
		error.type = "error";
		return <ErrorDisplay error={ error } />;
	}

	const sitesByName = orderBy( subscription.connectedSites, "url", "asc" );
	const credentials = `?access_token=${ getAccessToken() }`;
	const extendSubscriptionLink = (
		<Link href={ subscription.actions.manualRenewal.url } className={ styles.renewLink }>
			<Button variant="secondary">
				<ArrowPathIcon />
				<FormattedMessage
					{ ...messages.extendSubscription }
					values={ { billingTerm: subscription.billingTerm } }
				/>
			</Button>
		</Link>
	);

	const switchToAutomaticLink = (
		<Link href={ subscription.actions.switchToAutomatic.url + credentials } target="_blank">
			<Button variant="secondary">
				<FormattedMessage { ...messages.switchToAutomatic } />
			</Button>
		</Link>
	);

	const switchPaymentMethodLink = (
		<Link href={ subscription.actions.switchPaymentMethod.url + credentials } className={ styles.renewLink } target="_blank">
			<Button variant="secondary">
				<CreditCardIcon />
				<FormattedMessage
					{ ...messages.switchPaymentMethod }
					values={ { billingTerm: subscription.billingTerm } }
				/>
			</Button>
		</Link>
	);

	const upgradeToBundleActionLink = (
		<Button
			variant="secondary"
			className={ styles.renewLink }
			onClick={ () => setUpgradeToBundleModalOpen( true ) }
		>
			<ChevronDoubleUpIcon />
			<FormattedMessage { ...messages.upgradeToBundleLink } />
		</Button>
	);

	const addSiteToSubscriptionAction = (
		<Button
			variant="secondary"
			className={ styles.renewLink }
			onClick={ () => setAddSiteModalOpen( true ) }
		>
			<PlusIcon />
			<FormattedMessage { ...messages.addSiteToSubscriptionLink } />
		</Button>
	);

	const cancelButton = (
		<Button className={ styles.cancelButton } variant="tertiary" onClick={ () => setCancelModalOpen( true ) }>
			{ props.intl.formatMessage( messages.cancelLink ) }
		</Button>
	);

	const subscriptionDetailLines = [
		{
			order: 1,
			title: props.intl.formatMessage( messages.startDate ),
			value: formatDate( subscription.startDate ),
		},
	];

	subscriptionDetailLines.push( {
		order: 2,
		title: props.intl.formatMessage( subscription.willRenew ? messages.nextBilling : messages.endsOn ),
		value: formatDate( subscription.validUntil ),
	} );

	const subscriptionActionLines = [];

	if ( subscription.actions.addSite.isAvailable ) {
		subscriptionActionLines.push( {
			order: 2,
			action: addSiteToSubscriptionAction,
		} );
	}

	if ( subscription.actions.switchToAutomatic.isAvailable ) {
		subscriptionActionLines.push( { action: switchToAutomaticLink } );
	}

	if ( subscription.actions.switchProduct.isAvailable ) {
		subscriptionActionLines.push( {
			order: 3,
			action: upgradeToBundleActionLink,
		} );
	}

	if ( subscription.actions.manualRenewal.isAvailable ) {
		subscriptionActionLines.push( {
			order: 1,
			action: extendSubscriptionLink,
		} );
	}

	if ( subscription.actions.cancel.isAvailable ) {
		subscriptionActionLines.push( {
			order: 5,
			action: cancelButton,
		} );
	}

	if ( subscription.actions.switchPaymentMethod.isAvailable ) {
		subscriptionActionLines.push( {
			order: 4,
			action: switchPaymentMethodLink,
		} );
	}

	return (
		<section className={ styles.subscription }>
			<PageHeader
				title={ { ...messages.title, defaultMessage: subscription.name } }
				message={ { ...messages.subtitle, defaultMessage: subscription.limit + " site subscription" } }
			>
				<BackButton />
			</PageHeader>
			<SubscriptionSection
				title={ messages.subscriptionDetailsTitle }
				lines={ [
					{
						title: props.intl.formatMessage( messages.subscriptionNumber ),
						value: subscription.subscriptionNumber,
					},
					{
						title: props.intl.formatMessage( messages.subscriptionStatus ),
						value: getFormattedStatus( subscription.status ),
					},
				] }
			/>
			<SubscriptionSection
				title={ messages.paymentDetailsTitle }
				lines={ subscriptionDetailLines.sort( ( a, b ) => a.order - b.order ) }
			/>
			<SubscriptionActionSection
				title={ messages.subscriptionActionTitle }
				lines={ subscriptionActionLines.sort( ( a, b ) => a.order - b.order ) }
			/>
			<PageSection title={ messages.invoicesTitle }>
				<Table>
					<Table.Body>
						<Table.Row>
							<Table.Header>{ props.intl.formatMessage( messages.orderDate ) }</Table.Header>
							<Table.Header>{ props.intl.formatMessage( messages.orderId ) }</Table.Header>
							<Table.Header>{ props.intl.formatMessage( messages.orderItems ) }</Table.Header>
							<Table.Header>{ props.intl.formatMessage( messages.orderTotal ) }</Table.Header>
							<Table.Header>{ props.intl.formatMessage( messages.orderStatus ) }</Table.Header>
							<Table.Header>{ "" }</Table.Header>
						</Table.Row>
						{ subscription.invoices.map( ( invoice, i ) => {
							return (
								<Table.Row key={ `row-${ i }` }>
									<Table.Cell>{ invoice.date }</Table.Cell>
									<Table.Cell>{ invoice.invoiceNumber }</Table.Cell>
									<Table.Cell>{
										invoice.items.map( ( itemName, i ) => {
											return <div key={ `item-${ i }` }>{ itemName } </div>;
										} )
									}</Table.Cell>
									<Table.Cell><FormattedNumber
										value={ formatAmount( invoice.totalAmount ) } style="currency"
										currency={ invoice.currency }
									/></Table.Cell>
									<Table.Cell>{ invoice.status }</Table.Cell>
									<Table.Cell><InvoicesDownloadContainer
										resourceId={ invoice.id }
										type={ invoice.type }
									/></Table.Cell>
								</Table.Row>
							);
						} ) }
					</Table.Body>
				</Table>
			</PageSection>
			<PageSection title={ messages.downloadTitle }>
				{
					subscription.included.inSelf.courses.length > 0 &&
					<Link
						onClick={ () => setCoursesIsOpen( ! isCoursesOpen ) }
						className={ classNames( styles.expandableHeader, styles.first, { [ styles.isOpen ]: isCoursesOpen } ) }
					>
						<span>{ props.intl.formatMessage( messages.yoastCoursesTitle ) }</span>
						{ isCoursesOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
					</Link>
				}
				{
					isCoursesOpen &&
					<SubscriptionSection
						lines={
							subscription.included.inSelf.courses.map( course => {
								return { title: course.name.replace( /(&amp;)/, "&" ) };
							} )
						}
					/>
				}
				{
					subscription.included.inSelf.plugins.length > 0 &&
					<Link
						onClick={ () => setPluginsIsOpen( ! isPluginsOpen ) }
						className={ classNames( styles.expandableHeader, { [ styles.isOpen ]: isPluginsOpen } ) }
					>
						<span>{ props.intl.formatMessage( messages.yoastPluginsTitle ) }</span>
						{ isPluginsOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
					</Link>
				}
				{ isPluginsOpen &&
					<SubscriptionSection
						title={ null }
						lines={
							[
								...subscription.included.inSelf.plugins.map( plugin => {
									const updatedPluginName = plugin.name.replace( /(&amp;)/, "&" );
									const action = plugin.downloadUrl ? (
										<Link href={ plugin.downloadUrl }>
											<Button variant="secondary">
												<FormattedMessage { ...messages.downloadLinkText } />
											</Button>
										</Link> )
										: null;
									return { title: updatedPluginName, action: action };
								} ),
								{
									action: <Link
										href={ "https://yoa.st/myyoast-installation" }
										target="_blank"
									>
										<FormattedMessage { ...messages.installationGuide } />
									</Link>,
								},
							]
						}
					/>
				}
			</PageSection>

			<PageSection title={ messages.connectedSites }>
				<Table>
					<Table.Body>
						{ sitesByName.length
							? sitesByName.map( ( site, i ) => {
								return (
									<Table.Row key={ `site-${ i }` } className={ styles.sitesRow }>
										<Table.Cell>{ site.url }</Table.Cell>
										<Table.Cell className={ styles.sitesTableAction }>
											<Button
												variant="secondary" size="large"
												onClick={ () => props.onManage( site.id ) }
											>Manage</Button>
										</Table.Cell>
									</Table.Row>
								);
							} )
							: <Table.Row><Table.Cell>
								{ props.intl.formatMessage( messages.noConnectedSites ) }
							</Table.Cell></Table.Row>
						}
					</Table.Body>
				</Table>
			</PageSection>

			<SubscriptionEditModal
				isOpen={ cancelModalOpen }
				onClose={ () => setCancelModalOpen( false ) }
			/>
			<AddSiteToSubscriptionModal
				isOpen={ addSiteModalOpen }
				onClose={ () => setAddSiteModalOpen( false ) }
			/>
			<UpgradeSubscriptionModal
				isOpen={ upgradeToBundleModalOpen }
				onClose={ () => setUpgradeToBundleModalOpen( false ) }
			/>
		</section>
	);
};

SubscriptionPage.propTypes = {
	intl: intlShape.isRequired,
	onManage: PropTypes.func.isRequired,
};

export default injectIntl( SubscriptionPage );
