import React, { Component } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import DocumentTitle from "react-document-title";
import { getDocumentTitle } from "../helpers";

import * as styles from "./styles.scss";
import SkipLink from "../../SkipLink";
import RenewalNotificationContainer from "../../../containers/RenewalNotification";
import MobileHeaderContainer from "../../../containers/MobileHeaderContainer";

/**
 * Wraps a component in the single column layout.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The single column layout with the wrapped component.
 */
export default ( WrappedComponent, documentTitle ) => {
	return class SingleLayout extends Component {
		/**
     * Renders the component.
     *
     * @returns {ReactElement} The rendered component.
     */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<div className={ styles.layout }>
						<SkipLink>
							<FormattedMessage id="skiplink" defaultMessage="Skip to main content" />
						</SkipLink>
						<MobileHeaderContainer { ...this.props } detailPage={ true } />
						<div className={ classNames( styles.main, styles.single ) }>
							<div className={ styles.content }>
								<RenewalNotificationContainer />
								<WrappedComponent { ...this.props } />
							</div>
						</div>
					</div>
				</DocumentTitle>
			);
		}
	};
};
