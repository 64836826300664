import { combineReducers } from "redux";

import { allIdsReducer, byIdReducer } from "./sites.js";
import { allIdsSubscriptionsReducer, byIdSubscriptionsReducer } from "./subscriptions.js";
import { allIdsProductsReducer, byIdProductsReducer } from "./products.js";
import { allIdsOrdersReducer, byIdOrdersReducer } from "./orders";
import { allIdsRefundsReducer, byIdRefundsReducer } from "./refunds";
import { allIdsComposerTokensReducer, byIdComposerTokensReducer } from "./composerTokens";
import { allIdsProductGroupsReducer, byIdProductGroupsReducer } from "./productGroups";
import { allIdsProvisionerDataReducer, byIdProvisionerDataReducer } from "./provisionerData";

export { userReducer } from "./user";
export { roleManagementEntitiesReducer } from "./roleManagementEntities";
export { recalculateSubscriptionCountReducer } from "./subscriptions";
export { searchEntitiesReducer } from "./searchEntities";

export const entitiesSitesReducer = combineReducers( {
	byId: byIdReducer,
	allIds: allIdsReducer,
} );

export const entitiesSubscriptionsReducer = combineReducers( {
	byId: byIdSubscriptionsReducer,
	allIds: allIdsSubscriptionsReducer,
} );

export const entitiesProductsReducer = combineReducers( {
	byId: byIdProductsReducer,
	allIds: allIdsProductsReducer,
} );

export const entitiesProductGroupsReducer = combineReducers( {
	byId: byIdProductGroupsReducer,
	allIds: allIdsProductGroupsReducer,
} );

export const entitiesOrdersReducer = combineReducers( {
	byId: byIdOrdersReducer,
	allIds: allIdsOrdersReducer,
} );

export const entitiesRefundsReducer = combineReducers( {
	byId: byIdRefundsReducer,
	allIds: allIdsRefundsReducer,
} );

export const entitiesComposerTokensReducer = combineReducers( {
	byId: byIdComposerTokensReducer,
	allIds: allIdsComposerTokensReducer,
} );

export const entitiesProvisionerDataReducer = combineReducers( {
	byId: byIdProvisionerDataReducer,
	allIds: allIdsProvisionerDataReducer,
} );


export const entitiesReducer = combineReducers( {
	sites: entitiesSitesReducer,
	subscriptions: entitiesSubscriptionsReducer,
	products: entitiesProductsReducer,
	productGroups: entitiesProductGroupsReducer,
	provisionerData: entitiesProvisionerDataReducer,
	orders: entitiesOrdersReducer,
	refunds: entitiesRefundsReducer,
	composerTokens: entitiesComposerTokensReducer,
} );
