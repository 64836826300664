/* External dependencies */
import { connect } from "react-redux";
import { push } from "connected-react-router";

/* Internal dependencies */
import SubscriptionPage from "../components/Subscription";

/* eslint-disable require-jsdoc */
export const mapStateToProps = () => ( {} );

export const mapDispatchToProps = ( dispatch ) => {
	return {
		onManage: ( siteId ) => {
			dispatch( push( "/sites/" + siteId ) );
		},
	};
};

const SubscriptionsPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SubscriptionPage );

export default SubscriptionsPageContainer;
