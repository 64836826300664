import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { retrieveFeed } from "shared-frontend/redux/actions/home";
import getEnv from "shared-frontend/functions/getEnv";
import Link from "../Link.js";
import { Button, Card, Title } from "@yoast/ui-library";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import * as styles from "./BlogCardStyles.scss";

const messages = defineMessages( {
	loading: {
		id: "blogcard.loading",
		defaultMessage: "Retrieving recent blog posts...",
	},
	header: {
		id: "home.blogcard.header",
		defaultMessage: "Learn more about SEO",
	},
	button: {
		id: "home.blogcard.blogbutton",
		defaultMessage: "SEO blog",
	},
} );

/**
 * Renders a single blog post item.
 *
 * @param {Object} props The props.
 * @returns {ReactElement} The blog post item.
 */
const FeedListItem = ( props ) => (
	<li className={ styles.blogItem }>
		<Link
			to={ props.link.replace(
				"#utm_source=yoast-seo&utm_medium=software&utm_campaign=wordpress-general&utm_content=wordpress-dashboard",
				"",
			) }
			linkTarget="_blank"
			hasExternalLinkIcon={ true }
		>
			{ props.title }
		</Link>
		<p>{ props.description }</p>
	</li>
);

FeedListItem.propTypes = {
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

/**
 * Renders the list of blog posts.
 *
 * @param {Object} props The props.
 * @returns {ReactElement} The blog feed list.
 */
const FeedList = ( props ) => {
	if ( props.retrievingFeed ) {
		return <p className="yst-italic"><FormattedMessage { ...messages.loading } /></p>;
	}
	return (
		<ul className={ styles.blogList }>
			{ props.blogFeed.items.map( item => (
				<FeedListItem
					key={ item.title }
					title={ item.title }
					link={ item.link }
					description={ item.description }
				/>
			) ) }
		</ul>
	);
};

FeedList.propTypes = {
	blogFeed: PropTypes.object.isRequired,
	retrievingFeed: PropTypes.bool.isRequired,
};

/**
 * Renders the blog feed card.
 *
 * @param {Function} getFeed The getFeed function.
 * @param {Object} props The props.
 * @returns {ReactElement} The blog feed card.
 */
const BlogContent = ( { getFeed, ...props } ) => {
	useEffect( () => {
		getFeed();
	}, [ getFeed ] );

	return (
		<Card>
			<div className={ styles.blogCardHeader }>
				<Title as="h2" size="4">
					<FormattedMessage { ...messages.header } />
				</Title>
			</div>
			<Card.Content>
				<FeedList { ...props } />
			</Card.Content>
			<Card.Footer>
				<Button
					as="a"
					href={ getEnv( "SHOP_URL", "https://yoast.test" ) + "/seo-blog/" }
					target="_blank"
					variant="secondary"
				>
					<FormattedMessage { ...messages.button } />
					<ArrowTopRightOnSquareIcon className={ styles.blogButtonIcon } />
				</Button>
			</Card.Footer>
		</Card>
	);
};

BlogContent.propTypes = {
	getFeed: PropTypes.func.isRequired,
	retrievingFeed: PropTypes.bool.isRequired,
	blogFeed: PropTypes.object.isRequired,
	errorFound: PropTypes.bool,
	error: PropTypes.object,
	intl: intlShape.isRequired,
};

BlogContent.defaultProps = {
	error: null,
	errorFound: false,
};

/**
 * Maps the state to the props.
 *
 * @param {Object} state The state.
 * @returns {Object} The props.
 */
export const mapStateToProps = ( state ) => {
	const { blogFeed, blogFeedErrorFound: errorFound, blogFeedError: error, retrievingFeed } = state.ui.home;
	return {
		blogFeed,
		retrievingFeed,
		errorFound,
		error,
	};
};

/**
 * Maps the dispatch to the props.
 *
 * @param {Function} dispatch The dispatch.
 * @returns {Object} Object containing the getFeed function.
 */
export const mapDispatchToProps = ( dispatch ) => ( {
	getFeed: () => dispatch( retrieveFeed( 3 ) ),
} );

const BlogFeed = connect( mapStateToProps, mapDispatchToProps )( BlogContent );

export default injectIntl( BlogFeed );
