import React, { Fragment, useEffect, useState, useContext } from "react";
import useInterval from "../../reactHooks/useInterval";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { prepareInternalRequest, doRequest } from "shared-frontend/functions/api";

import LogoImage from "../../icons/Yoast-logo.svg";
import Processing from "../../images/processing.svg";
import defaults from "../../config/defaults.json";
import { InstallContext } from "./InstallContext";

const messages = defineMessages( {
	checkout: {
		id: "install.checkout",
		defaultMessage: "Checkout",
	},
	stepOne: {
		id: "install.stepOne",
		defaultMessage: "Enter your details",
	},
	stepTwo: {
		id: "install.stepTwo",
		defaultMessage: "Pay",
	},
	stepThree: {
		id: "install.stepThree",
		defaultMessage: "Receive your products",
	},
	loadingTitle: {
		id: "install.loadingTitle",
		defaultMessage: "Processing order...",
	},
	LoadingTitleAfterOneMinute: {
		id: "install.LoadingTitleAfterOneMinute",
		defaultMessage: "This is taking longer than normal...",
	},
	text: {
		id: "install.text",
		defaultMessage: "On a busy day this could take up to a few minutes, please stand by.",
	},
	textAfterOneMinute: {
		id: "install.textAfterOneMinute",
		defaultMessage: "Your order is taking longer than expected to process. " +
			"Apologies for any inconvenience. Please try again later using the link provided in the order confirmation message we sent you.",
	},
} );

const Header = styled.div`
	width: 100%;
	background: linear-gradient(60deg, #5d237a 0%, #a61e69 84%, #a61e69 100%);
	padding: 32px 16px;
	margin-bottom: 32px;
`;

const WideContent = styled.div`
	max-width: ${ defaults.css.breakpoint.medium }px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const HeaderTitle = styled.h3`
	color: white;
	font-size: 2.5em;
	line-height: 1.2;
	font-weight: 200;
	margin: 0;
`;

const Steps = styled.ol`
	width: 100%;
	display: flex;
	align-items: baseline;
	flex-direction: row;
	list-style: none;
	margin: 32px 0;
	padding: 0;
	justify-content: space-between;
	position: relative;
	&:before {
		content:' ';
		z-index: 0;
		border-top: 1px solid rgba(0,0,0,0.2);
		width: 100%;
		position: absolute;
		top: 13px;
	}
	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: none;
	}
`;

const Step = styled.li`
	font-size: 1em;
	z-index: 1;
	padding: 0 8px;
	background-color: white;
	color: #707070;
	margin: 0;
	counter-increment: indicator-step;
	&:before {
		content: counter(indicator-step);
		display: inline-block;
		overflow: hidden;
		width: 20px;
		height: 20px;
		line-height: 20px;
		margin-right: 8px;
		margin-bottom: -5px;
		border-radius: 50%;
		background-color: #707070;
		text-align: center;
		font-size: 11px;
		color: #fff;
	}
`;

const StepActive = styled( Step )`
	color: #303030;
	font-weight: 600;
	&:before {
		background-color: var(--bg-color-purple);
	}
`;

const ProcessingImage = styled.img`
	margin: 48px auto 32px;
	width: 200px;
`;

const Title = styled.h2`
	padding: 0;
	margin: 8px 0;
	color: var(--bg-color-purple);
	font-weight: 300;
	font-size: 2.5em;
`;

const CenterContent = styled.div`
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
	padding: 0 16px;
`;

const TitleAndSpinner = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
	margin-bottom: 32px;
`;

const Spinner = styled.div`
	font-size: 10px;
	text-indent: -9999em;
	width: 3.5em;
	height: 3.5em;
	border-radius: 50%;
	background: var(--bg-color-purple);
	background: -moz-linear-gradient(left, var(--bg-color-purple) 10%, rgba(211,177,177, 0) 42%);
	background: -webkit-linear-gradient(left, var(--bg-color-purple) 10%, rgba(211,177,177, 0) 42%);
	background: -o-linear-gradient(left, var(--bg-color-purple) 10%, rgba(211,177,177, 0) 42%);
	background: -ms-linear-gradient(left, var(--bg-color-purple) 10%, rgba(211,177,177, 0) 42%);
	background: linear-gradient(to right, var(--bg-color-purple) 10%, rgba(211,177,177, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	overflow: hidden;

&:before {
	width: 50%;
	height: 50%;
	background: var(--bg-color-purple);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
&:after {
	background: var(--bg-color-white);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	}
	@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
`;

const Description = styled.p`
	font-weight: 400;
	color: #404040;

`;

/**
 * Renders a component to check if an order exists in the my-yoast database.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const ProcessingOrder = props => {
	const oneMinuteInMilliseconds = 60000;
	const delay                   = 4000;
	const maxPollingTime          = oneMinuteInMilliseconds * 2;

	const [ continueToInstall, setContinueToInstall ] = useState( false );
	const [ pollCount, setPollCount ]                 = useState( 0 );
	const [ polling, setPolling ]                     = useState( false );
	const [ viewPage, setViewPage ]                   = useState( false );

	const [ content, setContent ] = useState( {
		title: messages.loadingTitle,
		text: messages.text,
	} );

	const { setOrderExist, invoiceNumberParam } = useContext( InstallContext );

	/**
	 * Checks if an order exists in the my-yoast database.
	 *
	 * @param  {string} invoiceNumber The invoice number.
	 *
	 * @returns {bool}.
	 */
	 async function doesOrderExist( invoiceNumber ) {
		const request = prepareInternalRequest( `Orders/InvoiceNumber/${invoiceNumber}`, "GET", {} );

		try {
			await doRequest( request );
			const order = await doRequest( request );
			if ( order.status === "pending" ) {
				return false;
			}
		} catch ( error ) {
			return false;
		}

		return true;
	}

	useInterval( async() => {
		const orderExist = await doesOrderExist( invoiceNumberParam );

		setPollCount( prevCount => prevCount + 1 );

		if ( orderExist ) {
			setContinueToInstall( true );
		}
	}, polling ? delay : null );

	useEffect( () => {
		if ( ( pollCount * delay ) >= maxPollingTime ) {
			setContent( {
				title: messages.LoadingTitleAfterOneMinute,
				text: messages.textAfterOneMinute,
			} );

			setPolling( false );
		}
	}, [ pollCount, setPolling, setContent ] );

	useEffect( () => {
		/**
		 * Check if the order exists. If the order exists, redirect to the install page. Else start polling and show the page.
		 *
		 * @Returns {void}
		 */
		async function fetchOrder() {
			const orderExist = await doesOrderExist( invoiceNumberParam );

			if ( orderExist ) {
				setContinueToInstall( true );
				return;
			}

			setPolling( true );
			setViewPage( true );
		}

		if ( invoiceNumberParam ) {
			fetchOrder();
		}
	}, [ setContinueToInstall, setPolling, setViewPage ] );

	if ( continueToInstall ) {
		setOrderExist( true );
	}

	if ( ! viewPage ) {
		return <div />;
	}

	return (
		<Fragment>
			<Header>
				<WideContent>
					<div>
						<img src={ LogoImage } alt="Yoast SEO for everyone" />
						<HeaderTitle><FormattedMessage { ...messages.checkout } /></HeaderTitle>
					</div>
				</WideContent>
			</Header>

			<WideContent>
				<Steps>
					<Step><FormattedMessage { ...messages.stepOne } /></Step>
					<StepActive><FormattedMessage { ...messages.stepTwo } /></StepActive>
					<Step><FormattedMessage { ...messages.stepThree } /></Step>
				</Steps>
			</WideContent>

			<CenterContent>
				<ProcessingImage src={ Processing } alt={ props.intl.formatMessage( content.text ) } />

				<TitleAndSpinner>
					{ polling && <Spinner>Loading</Spinner> }
					<Title><FormattedMessage { ...content.title } /></Title>
				</TitleAndSpinner>

				<Description>
					<FormattedMessage { ...content.text } />
				</Description>

			</CenterContent>
		</Fragment>
	);
};

ProcessingOrder.propTypes = {
	intl: intlShape.isRequired,
};

export default injectIntl( ProcessingOrder );
