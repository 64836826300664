import PropTypes from "prop-types";

export const downloadShape = PropTypes.shape( {
	name: PropTypes.string.isRequired,
	version: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	isWordPressPlugin: PropTypes.bool.isRequired,
	composerHandle: PropTypes.string,
	changelogUrl: PropTypes.string,
} );
