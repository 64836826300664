import _includes from "lodash/includes";

import getEnv from "./getEnv";

/** Product helpers */

export const PLUGIN_MAPPING = {
	wordpress: "plugin",
	typo3: "typo3-extension",
	shopify: "shopify-app",
};

export const GL_NUMBERS = {
	allPluginsPlusAllCourses: "80001",
	allPlugins: "82109",
	premiumNew: "82120",
	premiumOld: "82101",
	local: "82103",
	news: "82104",
	wooCommerce: "82105",
	video: "82102",
	localWooCommerceDeprecated: "82106",
};

/**
 * Sorts the passed array of plugins based on a fixed order (popularity).
 *
 * @param {Array} plugins The plugins to sort.
 * @returns {Array} The sorted array of plugins.
 */
export function sortPluginsByPopularity( plugins ) {
	const pluginsOrder = [
		GL_NUMBERS.allPluginsPlusAllCourses,
		GL_NUMBERS.allPlugins,
		GL_NUMBERS.premiumNew,
		GL_NUMBERS.premiumOld,
		GL_NUMBERS.local,
		GL_NUMBERS.news,
		GL_NUMBERS.wooCommerce,
		GL_NUMBERS.video,
		GL_NUMBERS.localWooCommerceDeprecated,
	];

	// Sorts Yoast plugins based on the index their glNumber have which are defined in pluginsOrder.
	plugins = plugins.sort( ( a, b ) => {
		// If the GL number is not present in the pluginsOrder array, force it to the bottom of the list.
		if ( ! _includes( pluginsOrder, b.glNumber ) ) {
			return -1;
		}
		if ( ! _includes( pluginsOrder, a.glNumber ) ) {
			return 1;
		}

		return pluginsOrder.indexOf( a.glNumber ) - pluginsOrder.indexOf( b.glNumber );
	} );

	return plugins;
}

/**
 * Returns the URL of the shop.
 *
 * @returns {string} The URL of the shop.
 */
export function getShopUrl() {
	return getEnv( "SHOP_URL", "https://yoast.test" );
}

