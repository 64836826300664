import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import Link from "../Link";
import { Card, Title } from "@yoast/ui-library";
import * as styles from "./UpsellCardStyles.scss";

/**
 * A function that returns the UpsellItem, which is a collection of icon, link, and description.
 *
 * @param {Object} props The props for the UpsellItem component.
 *
 * @returns {ReactElement} The UpsellItem.
 */
const UpsellItem = ( props ) => {
	return (
		<li className={ styles.upsellItem }>
			<img src={ props.icon } alt="" className={ styles.upsellImage } />
			<div>
				<Link to={ props.link.url } linkTarget="_blank"  hasExternalLinkIcon={ true }>
					{ props.link.name }
				</Link>
				<p>
					<FormattedMessage
						id={ props.description.id }
						defaultMessage={ props.description.defaultMessage }
					/>
				</p>
			</div>
		</li>
	);
};

UpsellItem.propTypes = {
	icon: PropTypes.string.isRequired,
	link: PropTypes.shape(
		{
			name: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		},
	).isRequired,
	description: PropTypes.shape(
		{
			id: PropTypes.string.isRequired,
			defaultMessage: PropTypes.string.isRequired,
		},
	).isRequired,
};

/**
 * A function that returns the UpsellCard component.
 *
 * @param {Object} props The props required for the UpsellCard component.
 *
 * @returns {ReactElement} The UpsellCard component.
 */
const UpsellCard = ( props ) => {
	return (
		<Card>
			<Title as="h2" size="4">
				<FormattedMessage
					id={ `${ props.id }.${ props.header.id }` }
					defaultMessage={ props.header.defaultMessage }
				/>
			</Title>
			<Card.Content>
				<ul className={ styles.upsellList }>
					{
						props.listPropsArray.map( ( listProps, index ) => {
							return (
								<UpsellItem { ...listProps } key={ `${ props.id }-key-${ index }` } />
							);
						} )
					}
				</ul>
			</Card.Content>
		</Card>
	);
};

UpsellCard.propTypes = {
	id: PropTypes.string,
	header: PropTypes.object.isRequired,
	listPropsArray: PropTypes.array.isRequired,
};

UpsellCard.defaultProps = {
	id: null,
};

export default injectIntl( UpsellCard );
