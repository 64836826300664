/* External dependencies */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

/* Internal dependencies */
import { Button, Modal } from "@yoast/ui-library";
import * as styles from "./ComposerHelpStyles.scss";
import { createComposerToken } from "shared-frontend/redux/actions/composerTokens";
import { fetchComposerTokens } from "shared-frontend/redux/actions/composerTokens";
import { getFirstEnabledComposerToken } from "shared-frontend/redux/selectors/entities/composerTokens";

const messages = defineMessages( {
	header: {
		id: "composer-help.header",
		defaultMessage: "How to install {product} using composer",
	},
	introduction: {
		id: "composer-help.introduction",
		defaultMessage: "Composer is a tool used by many developers to install and update plugins." +
			"Through MyYoast you can use Composer to get easy access to your premium plugins." +
			"Follow the instructions below to get started!",
	},
	createTokenInstruction: {
		id: "composer-help.create-token",
		defaultMessage: "Before you can install {product} using composer you will need to create a" +
			"token to access our secure repository. You can do this on the Account page in the Profile section" +
			"or by clicking the button below.",
	},
	registerTokenInstruction: {
		id: "composer-help.register-token",
		defaultMessage: "You can register your token with composer by running the command below:",
	},
	registerRepositoryInstruction: {
		id: "composer-help.register-token",
		defaultMessage: "You can then add our secure repository by running the following command:",
	},
	requireInstruction: {
		id: "composer-help.register-token",
		defaultMessage: "Now you can install {product} by running:",
	},
	wpAutoloadInstruction: {
		id: "composer-help.require-autoload",
		defaultMessage: "In order to use {product} with Composer you will have to require Composer's {autoload} file." +
			"We recommend adding this to your {wpConfig} file.",
	},
	createToken: {
		id: "profile.create-sites.create",
		defaultMessage: "Create token",
	},
	gotIt: {
		id: "gettingStarted.gotIt",
		defaultMessage: "Got it",
	},
} );


/**
 * Renders the ComposerHelp component.
 *
 * @param {Object} props The props to use.
 * @param {Function} props.onClose The function to execute when the got it button is clicked.
 *
 * @returns {ReactElement} A react component rendering the composer help modal.
 */
function ComposerHelp( props ) {
	// (Re)fetch composer tokens when the modal opens.
	useEffect( () => {
		props.fetchComposerTokens();
	}, [] );

	return (
		<>
			<Modal.Title as="h2" size="2">
				<FormattedMessage { ...messages.header } values={ { product: props.productName } } />
			</Modal.Title>
			<div className={ styles.contentWrapper }>
				<p>
					<FormattedMessage { ...messages.introduction } />
				</p>

				{ ! props.composerToken &&
					<div>
						<FormattedMessage
							{ ...messages.createTokenInstruction }
							values={ { product: props.productName } }
						/>
					</div>
				}

				{ props.composerToken &&
					<div className={ styles.contentWrapper }>
						<div>
							<FormattedMessage { ...messages.registerTokenInstruction } />
							<pre>composer config -g http-basic.my.yoast.com token { props.composerToken.id }</pre>
						</div>
						<div>
							<FormattedMessage { ...messages.registerRepositoryInstruction } />
							<pre>composer config repositories.my-yoast composer https://my.yoast.com/packages/</pre>
						</div>
						<div>
							<FormattedMessage
								{ ...messages.requireInstruction }
								values={ { product: props.productName } }
							/>
							<pre>composer require { props.composerHandle }</pre>
						</div>
						{ props.isWordPressPlugin &&
							<div className={ styles.combinedWrapper }>
								<FormattedMessage
									{ ...messages.wpAutoloadInstruction }
									values={ {
										product: props.productName,
										autoload: <pre>vendor/autoload.php</pre>,
										wpConfig: <pre>wp-config.php</pre>,
									} }
								/>
							</div>
						}
					</div>
				}

			</div>

			<div className={ styles.buttonWrapper }>
				{ ! props.composerToken &&
					<Button type="submit" onClick={ props.createComposerToken }>
						<FormattedMessage { ...messages.createToken } />
					</Button>
				}
				<Button onClick={ props.onClose } variant="secondary">
					<FormattedMessage { ...messages.gotIt } />
				</Button>
			</div>
		</>
	);
}

ComposerHelp.propTypes = {
	onClose: PropTypes.func.isRequired,
	productName: PropTypes.string.isRequired,
	composerHandle: PropTypes.string.isRequired,
	isWordPressPlugin: PropTypes.bool,
	composerToken: PropTypes.shape( {
		id: PropTypes.string.isRequired,
	} ),
	createComposerToken: PropTypes.func.isRequired,
	fetchComposerTokens: PropTypes.func.isRequired,
};

ComposerHelp.defaultProps = {
	composerToken: null,
	isWordPressPlugin: false,
};


/* eslint-disable require-jsdoc */
const mapStateToProps = ( state ) => {
	return {
		composerToken: getFirstEnabledComposerToken( state ),
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		createComposerToken: () => {
			dispatch( createComposerToken( { name: "Personal token" } ) );
		},
		fetchComposerTokens: () => {
			dispatch( fetchComposerTokens() );
		},
	};
};
/* eslint-enable require-jsdoc */

const ComposerHelpContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( ComposerHelp );

export default injectIntl( ComposerHelpContainer );
