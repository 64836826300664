/* External dependencies */
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedDate, FormattedMessage, injectIntl, intlShape } from "react-intl";

/* Internal dependencies */
import ButtonsContainer from "../../general/ButtonsContainer";
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { toSafeInteger } from "lodash/lang";
import { Button, Modal, TextInput } from "@yoast/ui-library";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";


import * as styles from "./styles.scss";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";

const messages = defineMessages( {
	ariaLabel: {
		id: "subscriptionCancel.modal.arialabel",
		defaultMessage: "Cancel subscription",
	},
	header: {
		id: "subscriptionCancel.modal.header",
		defaultMessage: "Cancel subscription?",
	},
	body: {
		id: "subscriptionCancel.modal.body",
		defaultMessage: "Are you sure you want to cancel this subscription? " +
				"If you cancel, you will no longer receive support or security and functionality updates after {term}",
	},
	activeSites: {
		id: "subscriptionCancel.modal.activeSites",
		defaultMessage: "You have {amount} active {amount, plural, one {site} other {sites}} using this subscription.",
	},
	numberOfCurrentSubscriptions: {
		id: "subscriptionCancel.modal.numberSubscriptions",
		defaultMessage: "You have {amount} of this type of subscription.",
	},
	cancel: {
		id: "subscriptionCancel.modal.cancel",
		defaultMessage: "Cancel",
	},
	confirm: {
		id: "subscriptionCancel.modal.confirm",
		defaultMessage: "Confirm cancellation",
	},
	loading: {
		id: "subscriptionCancel.modal.loading",
		defaultMessage: "Cancelling subscription...",
	},
	amount: {
		id: "subscriptionCancel.modal.amount",
		defaultMessage: "Amount",
	},
	selectAmount: {
		id: "subscriptionCancel.modal.selectAmount",
		defaultMessage: "Select amount",
	},
	decreaseAmount: {
		id: "subscriptionCancel.modal.decreaseAmount",
		defaultMessage: "Decrease amount",
	},
	increaseAmount: {
		id: "subscriptionCancel.modal.increaseAmount",
		defaultMessage: "Increase amount",
	},
	activeEnrollments: {
		id: "subscriptionCancel.modal.activeEnrollments",
		defaultMessage: "You have {amount} active {amount, plural, one {enrollment} other {enrollments}} using this subscription.",
	},
} );

const SubscriptionEditModal = ( props ) => {
	const { subscriptionId, subscription, request } = useContext( SubscriptionDetailContext );
	const [ amountToCancel, setAmountToCancel ] = useState( 0 );
	const needsReloadOnSuccess = useRef( false );

	useEffect( props.onClose, [ props.success ] );

	useEffect( () => {
		if ( props.success && needsReloadOnSuccess.current ) {
			request.reload();
		}
	}, [ props.success ] );

	const cancelSubscription = useCallback( () => {
		if ( ! amountToCancel > 0 ) {
			return;
		}

		needsReloadOnSuccess.current = true;
		props.cancelSubscription( subscriptionId, amountToCancel );
	}, [ props.cancelSubscription, amountToCancel, subscriptionId ] );

	/**
	 * Validates the input against the maximum number of subscriptions that is possible to cancel
	 * and sets it in the state.
	 *
	 * @param {Object} event The change event
	 *
	 * @returns {void}
	 */
	const changeItemsToCancel = useCallback( ( event ) => {
		event.preventDefault();
		const value = toSafeInteger( event.target.value );
		// Makes sure the value is between 0 and the maximum number of subscriptions
		setAmountToCancel( Math.min( Math.max( value, 0 ), subscription.limit ) );
	}, [ setAmountToCancel, subscription.limit ] );

	/**
	 * Increase the number of items to cancel unless it is already at the maximum possible.
	 *
	 * @returns {void}
	 */
	const incrementItemsToCancel = useCallback( () => {
		if ( amountToCancel < subscription.limit ) {
			setAmountToCancel( amountToCancel + 1 );
		}
	}, [ amountToCancel, setAmountToCancel, subscription.limit ] );

	/**
	 * Decrease the number of items to cancel unless it is already at one.
	 *
	 * @returns {void}
	 */
	const decrementItemsToCancel = useCallback( () => {
		if ( amountToCancel > 0 ) {
			setAmountToCancel( amountToCancel - 1 );
		}
	}, [ amountToCancel, setAmountToCancel ] );


	const displayNumberButtons = () => {
		if ( subscription.limit ) {
			return <div className={ styles.amountSelector }>
				<label htmlFor="input-number">
					<FormattedMessage
						id={ messages.selectAmount.id }
						defaultMessage={ messages.selectAmount.defaultMessage }
					/>
				</label>

				<Button
					className={ styles.amountButton }
					onClick={ decrementItemsToCancel }
					variant="secondary"
					size="small"
					aria-label={ messages.decreaseAmount.defaultMessage }
				>
					<MinusIcon />
				</Button>
				<TextInput
					id="input-number"
					className={ styles.amountInput }
					value={ amountToCancel }
					onChange={ changeItemsToCancel }
					type="number"
					aria-label={ messages.amount.defaultMessage }
				/>
				<Button
					className={ styles.amountButton }
					onClick={ incrementItemsToCancel }
					variant="secondary"
					size="small"
					aria-label={ messages.increaseAmount.defaultMessage }
				>
					<PlusIcon />
				</Button>
			</div>;
		}
	};

	const confirmButtonText = props.loading ? messages.loading : messages.confirm;
	const isEnabled = props.loading === false && amountToCancel > 0;
	const paymentTermDate = <FormattedDate
		value={ subscription.validUntil }
		year="numeric"
		month="long"
		day="2-digit"
	/>;

	const modalContents = (
		<Modal.Panel>
			<Modal.Title>
				<FormattedMessage { ...messages.header } />
			</Modal.Title>
			<div className={ styles.cancelForm }>
				<p>
					<FormattedMessage
						{ ...messages.body }
						values={ { term: <strong>{ paymentTermDate }</strong> } }
					/>
				</p>
				<p>
					<strong>
						<FormattedMessage
							{ ...messages.activeSites }
							values={ { amount: subscription.connectedSites.length } }
						/>
					</strong>
				</p>
				<p>
					<strong>
						<FormattedMessage
							{ ...messages.activeEnrollments }
							values={ { amount: 0 } }
						/>
					</strong>
				</p>
				<p>
					<FormattedMessage
						{ ...messages.numberOfCurrentSubscriptions }
						values={ { amount: subscription.limit } }
					/>
				</p>
				{ displayNumberButtons() }
				<ErrorDisplay error={ props.error } />
				<ButtonsContainer>
					<Button
						variant="secondary"
						onClick={ props.onClose }
					>
						<FormattedMessage { ...messages.cancel } />
					</Button>
					<Button
						type="submit"
						onClick={ cancelSubscription }
						className={ isEnabled ? styles.enabled : styles.disabled }
						disabled={ ! isEnabled }
						variant="error"
					>
						<FormattedMessage { ...confirmButtonText } />
					</Button>
				</ButtonsContainer>
			</div>
		</Modal.Panel>
	);

	return <Modal isOpen={ props.isOpen } onClose={ props.onClose } position="center">
		{ modalContents }
	</Modal>;
};

SubscriptionEditModal.propTypes = {
	intl: intlShape.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	cancelSubscription: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	error: ErrorPropTypeShape,
};

SubscriptionEditModal.defaultProps = {
	isOpen: false,
	error: null,
};

export default injectIntl( SubscriptionEditModal );
