// extracted by mini-css-extract-plugin
var _1 = "QEp5m";
var _2 = "QcEoP";
var _3 = "TK7Ia";
var _4 = "Eojz2";
var _5 = "EQNQX";
var _6 = "EK7Sd";
var _7 = "ZvwwL";
var _8 = "C0aIU";
var _9 = "a_FYN";
export { _1 as "noResults", _2 as "ordersPageAlert", _3 as "ordersPageContentWrapper", _4 as "ordersPageLink", _5 as "ordersPageLinkIcon", _6 as "search", _7 as "tableBody", _8 as "tableHead", _9 as "transactionStatusCell" }
