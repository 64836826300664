/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import validate from "validate.js";
import _isUndefined from "lodash/isUndefined";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Redirect } from "react-router";
import _debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { speak } from "@wordpress/a11y";

/* Internal dependencies */
import { emailConstraints, passwordRepeatConstraint } from "./CommonConstraints";
import ValidationInputField from "../ValidationInputField";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { validatePasswordStrength } from "../../functions/validatePasswordStrength";
import { Button } from "@yoast/ui-library";
import * as styles from "./LoginFormStyles.scss";

// Messages
const messages = defineMessages( {
	labelEmail: {
		id: "signup.email",
		defaultMessage: "Email address",
	},
	labelPassword: {
		id: "signup.password",
		defaultMessage: "Password",
	},
	labelPasswordRepeat: {
		id: "signup.passwordRepeat",
		defaultMessage: "Repeat password",
	},
	createAccount: {
		id: "signup.create",
		defaultMessage: "Create account",
	},
	duplicateEmail: {
		id: "signup.error.duplicateEmail",
		defaultMessage: "The email address could not be added, it is probably already in use.",
	},
} );

/**
 * Sign up page where the user can sign up to MyYoast.
 */
class Signup extends React.Component {
	/**
	 * Constructor
	 *
	 * @param {object} props The props to create this component.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		// Default state.
		this.state = {
			email: this.props.email,
			password: "",
			passwordRepeat: "",
			passwordValidation: {
				isFilledIn: false,
				score: 0,
				isStrongEnough: false,
				feedback: {
					suggestions: [],
					warning: "",
				},
				errors: [],
				isAllowedPassword: false,
			},
			emailErrors: [],
			passwordRepeatErrors: [],
		};

		this.onUpdateEmail          = this.updateField.bind( this, "email" );
		this.onUpdatePassword       = this.updateField.bind( this, "password" );
		this.onUpdatePasswordRepeat = this.updateField.bind( this, "passwordRepeat" );

		this.validatePassword         = _debounce( this.validatePassword.bind( this ), 500 );
		this.handleSubmit             = this.handleSubmit.bind( this );
		this.announceValidationErrors = _debounce( this.announceValidationErrors, 1000 );
	}

	/**
	 * Updates the specified field in the state,
	 * to be used as callback functions in text input fields.
	 *
	 * @param {string} field The field in the state that should be updated.
	 * @param {string} value The value of the input field.
	 * @param {array} errors The input field related errors.
	 *
	 * @returns {void}
	 */
	updateField( field, value, errors = [] ) {
		// Set the updated field in the state.
		const newState = {
			[ field ]: value,
			errorsInputFields: errors.length > 0,
		};
		this.setState( newState, this.validate );

		if ( field === "password" ) {
			this.validatePassword( value );
		}
	}

	/**
	 * Validates the password field using the zxcvbn module.
	 *
	 * @param {string} value The value of the password field.
	 *
	 * @returns {void}
	 */
	validatePassword( value ) {
		this.setState( { passwordValidation: validatePasswordStrength( value, [] ) } );
	}

	/**
	 * Validates the email, password and password repeat fields
	 * and returns an array of errors if there are any,
	 * and an empty array if none are present.
	 *
	 * @returns {void}
	 */
	validate() {
		let emailErrors = validate.single( this.state.email, emailConstraints( this.props.intl ), { format: "detailed" } );
		emailErrors     = this.state.email.length > 0 && ! _isUndefined( emailErrors ) ? emailErrors : [];

		let passwordRepeatErrors = validate(
			{
				password: this.state.password,
				passwordRepeat: this.state.passwordRepeat,
			},
			{ passwordRepeat: passwordRepeatConstraint( this.props.intl ) },
			{ format: "detailed" },
		);
		passwordRepeatErrors     = ( this.state.passwordRepeat.length > 0 && ! _isUndefined( passwordRepeatErrors ) )
			? [ passwordRepeatErrors[ 0 ].options.message ] : [];

		this.setState(
			prevState => ( { ...prevState, emailErrors: emailErrors, passwordRepeatErrors: passwordRepeatErrors } ),
			this.announceValidationErrors,
		);
	}

	/**
	 * Announces the form validation errors.
	 *
	 * @returns {void}
	 */
	announceValidationErrors() {
		speak( this.getAlertContents( this.state.passwordValidation.feedback.suggestions ).toString() );
	}

	/**
	 * Returns whether the signup form can be submitted.
	 *
	 * @returns {boolean} Whether the signup form can be submitted.
	 */
	canSubmit() {
		return ( ! isEmpty( this.state.email ) ) &&
			( ! isEmpty( this.state.password ) ) &&
			( ! isEmpty( this.state.passwordRepeat ) ) &&
			this.state.emailErrors.length <= 0 &&
			this.state.passwordRepeatErrors.length <= 0 &&
			! this.state.errorsInputFields &&
			this.state.passwordValidation.isAllowedPassword;
	}

	/**
	 * Creates a new MyYoast account using the email address and password as submitted.
	 *
	 * @param { object } event The button click event.
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		if ( ! this.canSubmit() ) {
			return;
		}

		const data = {
			userEmail: this.state.email,
			password: this.state.password,
			repeatPassword: this.state.passwordRepeat,
		};

		this.props.attemptSignup( data );
	}

	/**
	 * Puts all found validation errors in a single array of strings, and returns it.
	 *
	 * @param {Array<string>} passwordErrors The errors found for the password field.
	 *
	 * @returns {Array.<string>} An array of validation errors.
	 */
	getAlertContents( passwordErrors ) {
		const emailErrors          = Object.assign( [], this.state.emailErrors );
		const passwordRepeatErrors = Object.assign( [], this.state.passwordRepeatErrors );

		return emailErrors.concat( passwordErrors, passwordRepeatErrors );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const signupError = this.props.signupError;

		if ( this.props.signupRequestSuccess ) {
			return ( <Redirect to={ "/almost-there" } /> );
		}

		const passwordRepeatErrors = this.state.passwordRepeatErrors;
		return (
			<form onSubmit={ this.handleSubmit } className={ styles.form }>
				<ErrorDisplay error={ signupError } />
				<div>
					<ValidationInputField
						id="email-address"
						autoComplete="on"
						name="email"
						type="text"
						label={ this.props.intl.formatMessage( messages.labelEmail ) }
						onChange={ this.onUpdateEmail }
						value={ this.state.email }
						errors={ this.state.emailErrors }
					/>
				</div>
				<div>
					<ValidationInputField
						id="password"
						name="password"
						type="password"
						label={ this.props.intl.formatMessage( messages.labelPassword ) }
						onChange={ this.onUpdatePassword }
						value={ this.state.password }
						errors={ this.state.passwordValidation.feedback.suggestions }
					/>
				</div>
				<div>
					<ValidationInputField
						id="password-repeat"
						name="repeat password"
						type="password"
						label={ this.props.intl.formatMessage( messages.labelPasswordRepeat ) }
						onChange={ this.onUpdatePasswordRepeat }
						value={ this.state.passwordRepeat }
						errors={ passwordRepeatErrors }
					/>
				</div>
				<div>
					<Button
						className={ styles.button }
						type="submit"
						aria-disabled={ ! this.canSubmit() }
						disabled={ ! this.canSubmit() }
					>
						<FormattedMessage { ...messages.createAccount } />
					</Button>
				</div>
			</form>
		);
	}
}

Signup.propTypes = {
	intl: intlShape.isRequired,
	signupError: ErrorPropTypeShape,
	email: PropTypes.string,
	location: PropTypes.object,
	attemptSignup: PropTypes.func.isRequired,
	signupRequestSuccess: PropTypes.bool.isRequired,
};

Signup.defaultProps = {
	signupError: null,
	email: "",
	location: {},
};

export default injectIntl( Signup );
