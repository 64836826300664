import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { defineMessages, FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { getInvoiceUrl } from "shared-frontend/functions/api";
import formatAmount from "../../../functions/currency";
import { Button, Table, Title } from "@yoast/ui-library";
import * as styles from "./InvoicesModalStyles.scss";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const messages = defineMessages( {
	date: {
		id: "invoice.date",
		defaultMessage: "Date",
	},
	type: {
		id: "invoice.type",
		defaultMessage: "Type",
	},
	amount: {
		id: "invoice.amount",
		defaultMessage: "Amount",
	},
	download: {
		id: "orders.overview.download",
		defaultMessage: "Download",
	},
	closeModal: {
		id: "orders.overview.invoice.close",
		defaultMessage: "Close",
	},
} );

/**
 * Renders the modal for invoices overview and actions.
 *
 * @param {array} invoices The invoices to display.
 * @param {function} onInvoicesClose Function to execute when the modal is closed.
 * @param {string} type The type of invoice.
 * @param {object} intl The intl object.
 * @returns {ReactElement} The modal for invoices.
 */
export const InvoicesModal = ( { invoices, onInvoicesClose, type, intl } ) => {
	const makeInvoiceRow = useCallback( ( invoice ) => {
		const isProductSwitch = type === "productSwitch";
		const isRefund        = !! invoice.refundId;

		const invoiceIsFor = isRefund ? "refund" : "order";
		const invoiceType  = isProductSwitch ? "productSwitch" : invoiceIsFor;

		const id         = isRefund ? invoice.refundId : invoice.resourceId;
		const invoiceURI = getInvoiceUrl( invoiceType, id, invoice.resourceId );

		return (
			<Table.Row key={ id }>
				<Table.Cell data-label={ messages.date.defaultMessage }>
					<FormattedDate value={ invoice.date } day="numeric" month="long" year="numeric" />
				</Table.Cell>
				<Table.Cell data-label={ messages.type.defaultMessage }>
					<span>{ invoice.type }</span>
				</Table.Cell>
				<Table.Cell data-label={ messages.amount.defaultMessage }>
					<FormattedNumber
						value={ formatAmount( invoice.totalAmount ) } style="currency"
						currency={ invoice.currency }
					/>
				</Table.Cell>
				<Table.Cell data-label={ messages.download.defaultMessage }>
					<div className={ styles.downloadButtonsWrapper }>
						<Button
							as="a"
							href={ invoiceURI }
							variant="secondary"
							aria-label={ intl.formatMessage( messages.download ) }
						>
							<ArrowDownTrayIcon className={ styles.downloadButtonIcon } />
							<span>{ intl.formatMessage( messages.download ) }</span>
						</Button>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}, [ type, intl ] );

	return (
		<div className={ classNames( styles.invoicesModalWrapper, "yst-root yst-relative " ) }>
			<div className={ styles.invoicesModalHeader }>
				<Title as="h2" size="4">
					<FormattedMessage
						id="invoice.modal.heading"
						defaultMessage={ "Invoices for order { invoiceNumber }" }
						values={ { invoiceNumber: <strong>{ invoices[ 0 ].invoiceNumber }</strong> } }
					/>
				</Title>
			</div>
			<Table>
				<Table.Head className={ styles.tableHead }>
					<Table.Row>
						<Table.Header>
							<FormattedMessage { ...messages.date } />
						</Table.Header>
						<Table.Header>
							<FormattedMessage { ...messages.type } />
						</Table.Header>
						<Table.Header>
							<FormattedMessage { ...messages.amount } />
						</Table.Header>
						<Table.Header>
							<span className="yst-sr-only">
								<FormattedMessage { ...messages.download } />
							</span>
						</Table.Header>
					</Table.Row>
				</Table.Head>
				<Table.Body className={ styles.tableBody }>
					{ invoices.map( makeInvoiceRow ) }
				</Table.Body>
			</Table>
			<div className={ styles.modalActions }>
				<Button
					onClick={ onInvoicesClose }
					variant="primary"
				>
					<FormattedMessage { ...messages.closeModal } />
				</Button>
			</div>
		</div>
	);
};

InvoicesModal.propTypes = {
	invoices: PropTypes.array.isRequired,
	onInvoicesClose: PropTypes.func.isRequired,
	background: PropTypes.string,
	intl: PropTypes.shape( {
		formatMessage: PropTypes.func.isRequired,
	} ).isRequired,
	type: PropTypes.string.isRequired,
};

InvoicesModal.defaultProps = {
	background: "",
};
