import React from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

// Internal dependencies.
import Sidebar from "../../../components/Sidebar";
import SkipLink from "../../../components/SkipLink";
import RenewalNotificationContainer from "../../../containers/RenewalNotification";
import { getDocumentTitle } from "../helpers";

import * as styles from "./styles.scss";

/**
 * Wraps a component in the main layout.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 * @param {Array}        menuItems        The document title.
 *
 * @returns {ReactElement} The main layout with the wrapped component.
 */
// eslint-disable-next-line react/display-name
export default ( WrappedComponent, documentTitle, menuItems, noBackground ) => props => {
	return (
			<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
				<div className={ styles.layout }>
					<SkipLink>
						<FormattedMessage id="skiplink" defaultMessage="Skip to main content"/>
					</SkipLink>
					<Sidebar menuItems={ menuItems }/>
					<div className={ styles.main }>
						<RenewalNotificationContainer/>
						<div className={ classNames( styles.content, { [styles.noBackground]: !! noBackground } ) }>
							<WrappedComponent { ...props } />
						</div>
					</div>
				</div>
			</DocumentTitle>
	);
};
