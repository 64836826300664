// External dependencies.
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";

// Internal dependencies.
import closeCross from "../icons/times.svg";
import defaults from "../config/defaults.json";


const buttonAnimations = `
	transition: background-color 150ms ease-out;

	&:hover,
	&:focus {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 100px rgba( 0, 0, 0, 0.1 );
	}

	&:active {
		transform: translateY( 1px );
		box-shadow: none;
	}
`;

// Button style archetypes.
const ButtonArchetype = styled.button`
	height: 48px;
	margin: 0;
	// Buttons don't need vertical padding.
	padding: 0 16px;
	border: 0;
	background-color: ${ props => props.enabledStyle ? colors.$color_green_medium_light : colors.$color_grey_disabled };
	color: ${ colors.$color_white };
	box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.2);
	filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
	border-radius: 4px;
	cursor: pointer;
	vertical-align: top;

  	&:disabled {
	  	cursor: not-allowed;
    }

	${ buttonAnimations };
`;

ButtonArchetype.propTypes = {
	onClick: PropTypes.func,
	type: PropTypes.string,
	enabledStyle: PropTypes.bool,
};

ButtonArchetype.defaultProps = {
	type: "button",
	enabledStyle: true,
};

export const IconButtonArchetype = styled( ButtonArchetype )`
	background-repeat: no-repeat;
	background-image: url( ${ props => props.iconSource } );
	background-position: 8px 50%;
	background-size: ${ props => props.iconSize };
	padding-left: 36px;
`;

IconButtonArchetype.propTypes = {
	iconSource: PropTypes.string.isRequired,
	iconSize: PropTypes.string,
	enabledStyle: PropTypes.bool,
};

IconButtonArchetype.defaultProps = {
	iconSize: "24px",
	enabledStyle: true,
};

// Styled ButtonArchetypes.
export const Button = styled( ButtonArchetype )`
	text-shadow: 0px 0px 2px #000;

  	&:disabled {
	  	cursor: not-allowed;
    }
`;


export const LogoutButton = styled( Button )`
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	filter: none;
	height: auto;
	font-weight: 500;
	text-shadow: none;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 2px;

	&:hover, &:focus {
		background: transparent;
		box-shadow: none;
	}
`;


export const CloseButtonTopRight = styled( IconButtonArchetype )`
	background-color: transparent;
	background-position: 50%;
	padding-left: 0px;
	height: 32px;
	width: 32px;
	border-bottom: none;
	box-shadow: none;
	display: inline-block;

	&:hover,
	&:focus {
		box-shadow: none;
		background-color: transparent;
		opacity: .5;
	}
`;

CloseButtonTopRight.defaultProps = {
	iconSource: closeCross,
	iconSize: "24px",
};

export const MobileHeaderButton = styled( IconButtonArchetype )`
	background-color: transparent;
	box-shadow: none;
	display: block;
	position: fixed;
	border: 0;

	&:hover,
	&:focus {
		box-shadow: none;
	}

	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		text-indent: -9999em;
		box-shadow: none;
	}
`;
