// External dependencies.
import React from "react";

// Internal dependencies.
import Menu from "../../components/Menu";
import DebugInfo from "../../components/DebugInfo";

import * as styles from "./styles.scss";

const Sidebar = ( props ) => {
	return (
		<div className={ styles.sidebar }>
			<Menu menuRoutes={ props.menuItems } />
			<DebugInfo />
		</div>
	);
};

export default Sidebar;
