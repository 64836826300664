import React from "react";
import { FormattedMessage } from "react-intl";
import { Title } from "@yoast/ui-library";

import * as styles from "./styles.scss";

export const PageHeader = ( props ) => {
  return (
    <div className={ styles.headerWrapper }>
      <Title>
        <FormattedMessage
          id="pageHeader.title"
          defaultMessage={ props.title.defaultMessage }
        />
      </Title>
      { props.message && <p className={ styles.headerText } >
        <FormattedMessage
          id="pageHeader.message"
          defaultMessage={ props.message.defaultMessage }
        />
      </p> }
      { props.children && <p className={ styles.children }>{ props.children }</p>}
    </div>
  )
}
