import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { speak } from "@wordpress/a11y";
import ProfileForm from "./account/profile/ProfileForm.js";
import ComposerTokens from "./account/profile/ComposerTokens";
import MyYoastModal from "./MyYoastModal";
import CreateToken from "./account/profile/CreateToken";
import ManageToken from "./account/profile/ManageToken";
import SubscribeNewsletter from "./account/profile/SubscribeNewsletter";
import DeleteAccount from "./account/profile/dangerzone/DeleteAccount";
import DownloadAccount from "./account/profile/dangerzone/DownloadAccount";
import PasswordResetForm from "./account/profile/PasswordResetForm";
import { ErrorPropTypeShape } from "../errors/ErrorDisplay";
import * as styles from "./ProfilePageStyles.scss";
import { Button, Link, Title } from "@yoast/ui-library";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { PageHeader } from "./PageHeader";

const messages = defineMessages( {
	title: {
		id: "validation.page.title",
		defaultMessage: "Profile",
	},
	validationFormatEmail: {
		id: "validation.format.email",
		defaultMessage: "{field} must be a valid e-mail address.",
	},
	validationRequired: {
		id: "validation.required",
		defaultMessage: "{field} cannot be empty.",
	},
	duplicateEmail: {
		id: "profile.error.duplicateEmail",
		defaultMessage: "The email address could not be changed, it is probably already in use.",
	},
	labelEmail: {
		id: "profile.label.email",
		defaultMessage: "Email",
	},
	labelDelete: {
		id: "profile.label.delete",
		defaultMessage: "Delete account",
	},
	developerTokens: {
		id: "profile.label.developerTokens",
		defaultMessage: "Developer Tokens",
	},
	dangerZone: {
		id: "profile.label.dangerZone",
		defaultMessage: "Download your data or delete your account",
	},
	saving: {
		id: "profile.saving",
		defaultMessage: "Saving...",
	},
	saved: {
		id: "profile.saved",
		defaultMessage: "Email address saved",
	},
	saveEmail: {
		id: "profile.saveEmail",
		defaultMessage: "Save email address",
	},
	deleteAccount: {
		id: "profile.delete",
		defaultMessage: "Delete your account",
	},
	passwordChange: {
		id: "profile.passwordChange",
		defaultMessage: "Password",
	},
	personalInfo: {
		id: "personal.info",
		defaultMessage: "Personal info",
	},
	profilePageLoaded: {
		id: "menu.account.orders.loaded",
		defaultMessage: "Account profile page loaded",
	},
	newsLetter: {
		id: "newsLetter",
		defaultMessage: "Yoast SEO Newsletter",
	},
	guide: {
		id: "account.guide",
		defaultMessage: "guide",
	},
	hasActiveComposerTokens: {
		id: "profile.composer-introduction-with-active-tokens",
		defaultMessage: "Here you can find a list of the Composer tokens that you have created.",
	},
	hasNotActiveComposerTokens: {
		id: "profile.composer-introduction-without-active-tokens",
		defaultMessage: "Composer is a tool used by many developers to install and update plugins. " +
			"Through MyYoast you can use Composer to get easy access to your premium plugins. " +
			"Please see the Downloads page for additional information.",
	},
} );

/**
 * Returns the rendered Sites Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered Sites component.
 */
class ProfilePage extends React.Component {
	/**
	 * Sets the ProfilePage object.
	 *
	 * Sets (input) validation constraints, including email.
	 *
	 * @param {Object} props The props passed to the component.
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );
		this.moveFocusAfterTokenIsDeleted = this.moveFocusAfterTokenIsDeleted.bind( this );
	}

	/**
	 * Actions to perform when the component mounted.
	 *
	 * @returns {void}
	 */
	componentDidMount() {
		// Announce navigation to assistive technologies.
		const message = this.props.intl.formatMessage( messages.profilePageLoaded );
		speak( message );
	}

	/**
	 * Actions to perform when the component has updated.
	 *
	 * @param {Object} prevProps The previous props.
	 *
	 * @returns {void}
	 */
	componentDidUpdate( prevProps ) {
		if ( prevProps.tokenDeleted !== this.props.tokenDeleted && this.props.tokenDeleted === true ) {
			this.moveFocusAfterTokenIsDeleted();
		}
	}

	/**
	 * Moves focus back to the Create Token button after a token is deleted.
	 *
	 * @returns {void}
	 */
	moveFocusAfterTokenIsDeleted() {
		if ( this.createTokenButton ) {
			this.createTokenButton.focus();
		}
	}

	/**
	 * Whether we are currently disabling the account.
	 *
	 * @returns {boolean} Whether we are currently disabling the account.
	 */
	isDeleting() {
		return this.props.isDeleting;
	}

	/**
	 * Will open a modal if its respective "isOpen" boolean is set to true.
	 *
	 * @returns {*} Returns either a CreateTokenModal, a ManageTokenModal, or null, depending on whether one of these modals is open.
	 */
	getModal() {
		if ( this.props.createTokenModalIsOpen || this.props.manageTokenModalIsOpen ) {
			let modalContent = null;
			let modalIsOpen;
			let onClose;
			let modalAriaLabel;

			if ( this.props.createTokenModalIsOpen ) {
				modalIsOpen = this.props.createTokenModalIsOpen;
				onClose = this.props.onCreateTokenModalClose;
				modalAriaLabel = defineMessages(
					{
						id: "modal.arialabel.create",
						defaultMessage: "Create token",
					},
				);

				modalContent =
					<CreateToken
						onClose={ this.props.onCreateTokenModalClose }
						onCreateClick={ this.props.onCreateTokenClick }
						error={ this.props.tokenError }
					/>;
			} else if ( this.props.manageTokenModalIsOpen ) {
				modalIsOpen = this.props.manageTokenModalIsOpen;
				onClose = this.props.onManageTokenModalClose;
				modalAriaLabel = defineMessages( {
					id: "modal.arialabel.manage",
					defaultMessage: "Manage token",
				} );

				modalContent =
					<ManageToken
						onClose={ this.props.onManageTokenModalClose }
						onSaveTokenClick={ this.props.onSaveTokenClick }
						onDeleteTokenClick={ this.props.onDeleteTokenClick }
						manageTokenData={ this.props.manageTokenData }
						error={ this.props.tokenError }
						tokenDeleted={ this.props.tokenDeleted }
					/>;
			}
			return (
				<MyYoastModal
					isOpen={ modalIsOpen }
					onClose={ onClose }
					modalAriaLabel={ modalAriaLabel }
					className={ styles.composerTokenModal }
				>
					{ modalContent }
				</MyYoastModal>
			);
		}
		return null;
	}

	/**
	 *
	 * @returns {boolean} True when there are active composer tokens among the composertokens for this user. False otherwise.
	 */
	hasActiveComposerTokens() {
		const tokens = this.props.composerTokens && this.props.composerTokens.filter( ( composerToken ) => {
			return composerToken.enabled === true;
		} );

		return tokens.length > 0;
	}

	/**
	 * A function that prepares the DevTools element.
	 *
	 * @returns {(ReactElement|null)} Either a JSX element containing the DevTools section of the profile page, or null,
	 * depending on whether the user has access to this feature via the feature toggle.
	 */
	getComposerTools() {
		const howToInstallUsingComposer = <Link
			className={ styles.externalLink }
			href="https://kb.yoast.com/kb/how-to-install-yoast-plugins-using-composer/"
			target="_blank"
		>
			<span>{ this.props.intl.formatMessage( messages.guide ) }</span>
			<ArrowTopRightOnSquareIcon className={ styles.externalLinkIcon } />
		</Link>;

		return (
			<>
				<div className={ styles.sectionTitle }>
					<Title as="h2" size="4">
						<FormattedMessage
							id={ messages.developerTokens.id }
							defaultMessage={ messages.developerTokens.defaultMessage }
						/>
					</Title>
					<p>
						<FormattedMessage
							id="downloadsPage.byLine.plugins"
							defaultMessage="Need more information on how to create your token? See our { link }."
							values={ {
								link: howToInstallUsingComposer,
							} }
						/>
					</p>
				</div>
				<div className={ styles.sectionContent }>
					<p>
						{ this.hasActiveComposerTokens()
							? <FormattedMessage { ...messages.hasActiveComposerTokens } />
							: <FormattedMessage { ...messages.hasNotActiveComposerTokens } />
						}
					</p>
					<ComposerTokens { ...this.props } hasPaper={ false } />
					<div>
						<Button
							onClick={ this.props.onCreateTokenModalOpen }
							ref={ createTokenButton => ( this.createTokenButton = createTokenButton ) }
							variant="secondary"
						>
							<FormattedMessage
								id="profile.tokens.create"
								defaultMessage="Create token"
							/>
						</Button>
					</div>
				</div>
				{ this.getModal() }
			</>
		);
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<div className={ styles.pageWrapper }>
				<PageHeader title={ messages.title } />
				<section className={ styles.pageSection }>
					<div className={ styles.sectionTitle }>
						<Title as="h2" size="4">
							<FormattedMessage
								id={ messages.personalInfo.id }
								defaultMessage={ messages.personalInfo.defaultMessage }
							/>
						</Title>
					</div>
					<div className={ styles.sectionContent }>
						<ProfileForm { ...this.props } />
					</div>
				</section>
				<section className={ styles.pageSection }>
					<div className={ styles.sectionTitle }>
						<Title as="h2" size="4">
							<FormattedMessage
								id={ messages.passwordChange.id }
								defaultMessage={ messages.passwordChange.defaultMessage }
							/>
						</Title>
						<p>
							<FormattedMessage
								id="profile.description.passwordReset"
								defaultMessage="Your password should be at least 8 characters long,
										contain both uppercase and lowercase letters and one symbol."
							/>
						</p>
					</div>
					<div className={ styles.sectionContent }>
						<PasswordResetForm { ...this.props } />
					</div>
				</section>
				<section className={ styles.pageSection }>
					<div className={ styles.sectionTitle }>
						<Title as="h2" size="4">
							<FormattedMessage
								id={ messages.newsLetter.id }
								defaultMessage={ messages.newsLetter.defaultMessage }
							/>
						</Title>
					</div>
					<div className={ styles.sectionContent }>
						<SubscribeNewsletter email={ this.props.email } />
					</div>
				</section>
				<section className={ styles.pageSection }>
					{ ! this.props.isOnlyProvisionerSubscriptions && this.getComposerTools() }
				</section>
				<section>
					<DownloadAccount />
				</section>
				<section>
					<DeleteAccount
						onDeleteProfile={ this.props.onDeleteProfile }
						isDeleting={ this.props.isDeleting }
					/>
				</section>
			</div>

		);
	}
}

ProfilePage.propTypes = {
	// User data
	intl: intlShape.isRequired,
	email: PropTypes.string.isRequired,
	userFirstName: PropTypes.string,
	userLastName: PropTypes.string,
	image: PropTypes.string,
	isOnlyProvisionerSubscriptions: PropTypes.bool,

	// Profile actions
	isSaving: PropTypes.bool,
	isSaved: PropTypes.bool,
	isDeleting: PropTypes.bool,
	onSaveProfile: PropTypes.func.isRequired,
	onDeleteProfile: PropTypes.func.isRequired,
	saveEmailError: ErrorPropTypeShape,

	// Password actions
	onSavePassword: PropTypes.func,
	isSavingPassword: PropTypes.bool,
	passwordIsSaved: PropTypes.bool,
	isSendingPasswordReset: PropTypes.bool,
	hasSendPasswordReset: PropTypes.bool,
	passWord: PropTypes.string,
	resetSaveMessage: PropTypes.func,
	passwordResetError: ErrorPropTypeShape,

	// Composer tokens
	onCreateTokenModalOpen: PropTypes.func.isRequired,
	onCreateTokenModalClose: PropTypes.func.isRequired,
	createTokenModalIsOpen: PropTypes.bool.isRequired,
	onCreateTokenClick: PropTypes.func.isRequired,
	manageTokenModalIsOpen: PropTypes.bool.isRequired,
	onManageTokenClick: PropTypes.func.isRequired,
	onManageTokenModalClose: PropTypes.func.isRequired,
	onSaveTokenClick: PropTypes.func.isRequired,
	onDeleteTokenClick: PropTypes.func.isRequired,
	manageTokenData: PropTypes.object,
	tokenError: ErrorPropTypeShape,
	tokenDeleted: PropTypes.bool,
	composerTokens: PropTypes.array,
};

ProfilePage.defaultProps = {
	email: "",
	userFirstName: "",
	userLastName: "",
	isSaving: false,
	isSaved: false,
	isSavingPassword: false,
	passwordIsSaved: false,
	isSendingPasswordReset: false,
	hasSendPasswordReset: false,
	isOnlyProvisionerSubscriptions: false,
	createTokenModalIsOpen: false,
	manageTokenModalIsOpen: false,
	manageTokenData: null,
	tokenDeleted: false,
	tokenError: null,
	saveEmailError: null,
	passwordResetError: null,
};

export default injectIntl( ProfilePage );
