// extracted by mini-css-extract-plugin
var _1 = "abhA8";
var _2 = "qCHau";
var _3 = "G9x1X";
var _4 = "VMKBb";
var _5 = "r8sYS";
var _6 = "B1nhh";
var _7 = "FQEIX";
var _8 = "ml4w5";
export { _1 as "layout", _2 as "layoutLogin", _3 as "main", _4 as "page", _5 as "pageCard", _6 as "paper", _7 as "whitePage", _8 as "whitePaper" }
