import React from "react";
import { injectIntl, intlShape, defineMessages, FormattedMessage } from "react-intl";

// Components.
import logo from "../../images/my-yoast-academy-logo.svg";
import icon from "../../images/greeting.png";
import { Title } from "@yoast/ui-library";
import * as styles from "./AlmostThere.scss";

const messages = defineMessages( {
	message: {
		id: "AlmostThere.message",
		defaultMessage: "We just sent you an email, so check your inbox! " +
		"Click the link inside the email to activate your account. After that you can access MyYoast.",
	},
	header: {
		id: "AlmostThere.header",
		defaultMessage: "Almost there!",
	},
} );

/**
 * Shows an "Almost there" login message.
 */
class AlmostThere extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<div className={ styles.firstColumn }>
				<img className={ styles.asideLogo } src={ logo } alt="MyYoast - Yoast Academy" />
				<img className={ styles.asideIcon } src={ icon } alt="" />
				<Title size="2">
					<FormattedMessage { ...messages.header } />
				</Title>
				<p>
					<FormattedMessage { ...messages.message } />
				</p>
			</div>
		);
	}
}

export default injectIntl( AlmostThere );

AlmostThere.propTypes = {
	intl: intlShape.isRequired,
};

AlmostThere.defaultProps = {};
