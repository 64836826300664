import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, intlShape } from "react-intl";
import DownloadItems from "./DownloadItems";
import { speak } from "@wordpress/a11y";
import { Link, Spinner } from "@yoast/ui-library";
import * as styles from "./DownloadsPageStyles.scss";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import ProvisionerDownloadAlert from "./ProvisionerDownloadAlert";
import useRequest from "../../reactHooks/useRequest";
import ErrorDisplay from "../../errors/ErrorDisplay";
import getEnv from "shared-frontend/functions/getEnv";


const messages = defineMessages( {
	downloadsLoaded: {
		id: "downloads.fetching.done",
		defaultMessage: "Downloads page loaded",
	},
	noDownloads: {
		id: "downloads.noDownloads",
		defaultMessage: "You don't have anything to download yet. To get downloads visit our { link }.",
	},
	loadingFailed: {
		id: "downloads.fetching.failed",
		defaultMessage: "Something went wrong while fetching your downloads. Please try again later. " +
			"If the problem persists, [customer_support_link]",
	},
} );

/**
 * A component showing all available downloads for the user.
 * @param {Object} props The component props.
 * @returns {JSX.Element} The downloads page.
 */
export const Downloads = ( props ) => {
	const { data: downloads, isLoading, error } = useRequest( `Customers/${ props.customerId }/downloads`, "GET" );

	useEffect( () => {
		if ( ! isLoading && ! error ) {
			speak( props.intl.formatMessage( messages.downloadsLoaded ) );
		}
	}, [ isLoading, error ] );


	if ( isLoading ) {
		return <Spinner />;
	}

	if ( error ) {
		return <ErrorDisplay
			error={ { message: props.intl.formatMessage( messages.loadingFailed ), type: "error" } }
		/>;
	}

	return ( <>
		<div className={ styles.downloadsPageContentWrapper }>
			<ProvisionerDownloadAlert />

			{ downloads.length === 0 && (
				<p className={ styles.downloadsPageNoDownloads }>
					<FormattedMessage
						{ ...messages.noDownloads }
						values={ {
							link: <Link
								className={ styles.downloadPageLink }
								target="_blank"
								href={ getEnv( "SHOP_URL", "https://yoast.test" ) + "/shop" }
							>
								<span>shop</span>
								<ArrowTopRightOnSquareIcon className={ styles.downloadPageLinkIcon } />
							</Link>,
						} }
					/>
				</p>
			) }
		</div>

		{ downloads.length > 0 &&
			<DownloadItems downloads={ downloads } />
		}
	</> );
};


Downloads.propTypes = {
	intl: intlShape.isRequired,
	customerId: PropTypes.string.isRequired,
};
