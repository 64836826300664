import PropTypes from "prop-types";
import React from "react";
import DownloadItem from "./DownloadItem";
import * as styles from "./DownloadItemsStyles.scss";
import { downloadShape } from "./proptypes";

/**
 * Returns the rendered Products component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered products page.
 */
function DownloadItems( { downloads } ) {
	return (
		<div className={ styles.downloadItemsWrapper }>
			{ downloads.map( download => {
				return <DownloadItem
					key={ download.name }
					download={ download }
				/>;
			} ) }
		</div>
	);
}

export default DownloadItems;

DownloadItems.propTypes = {
	downloads: PropTypes.arrayOf( downloadShape ).isRequired,
};

