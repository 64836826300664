import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Link, Button, Title } from '@yoast/ui-library';
import { FormattedMessage, injectIntl } from "react-intl";
import { BackButton } from "../BackButton";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

import * as styles from "./styles.scss";


/**
 * The SiteHeader component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered component
 * @constructor
 */
function SiteHeader( props ) {
  const adminLink = useCallback( siteUrl => {
	  return siteUrl.endsWith( "/" ) ? `${ siteUrl }wp-admin` : `${ siteUrl }/wp-admin`;
  }, [ props.url ] );

  return (
    <div className={ styles.siteHeader }>
      <div className={ styles.siteName }>
        <Title
          as="h4"
          size="2"
        >
          { props.name }
        </Title>
      </div>
      <div className={ styles.buttonArea }>
        <BackButton />
        { props.adminButton &&
          <Link href={ adminLink( props.url ) }  target="_blank">
            <Button
              type="button"
              variant="secondary"
            >
              <FormattedMessage id="sites.buttons.visitWp" defaultMessage="Open WordPress admin" /> <ArrowTopRightOnSquareIcon />
            </Button>
          </Link>
        }
      </div>
    </div>
  );
}

export default injectIntl( SiteHeader );

SiteHeader.defaultProps = {
  imageUrl: "",
};
SiteHeader.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  adminButton: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
};
