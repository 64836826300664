/**
 * Returns the complete user object from the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Object} The user.
 */
export const getUser = state => state.user;
