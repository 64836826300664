// External dependencies.
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { NavLink, Route } from "react-router-dom";
import { Link } from "@yoast/ui-library";
import * as styles from "./SubNavigationStyles.scss";
import classNames from "classnames";

/**
 * The SubNavigation component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered SubNavigation component.
 */
const SubNavigation = props => {
	return (
		<div className={ styles.subNavigationMenuWrapper }>
			<ul
				className={ classNames( {
					[ styles.subNavigationMenuThreeItems ]: props.itemRoutes.length === 3,
					[ styles.subNavigationMenuTwoItems ]: props.itemRoutes.length === 2,
				} ) }
			>
				{ props.itemRoutes.map( item => {
					const isActive = ( window.location.pathname  === item.path );
					const isDefault = ( window.location.pathname === "/account" && item.path === "/account/orders" );

					return <li key={ item.title } className={ styles.subNavigationMenuItem }>
						<Link
							as={ NavLink }
							className={ classNames( styles.subNavigationMenuItemLink, ( isActive || isDefault ) ? styles.active : "" ) }
							to={ item.path }
						>
							{ item.title }
						</Link>
					</li>;
				} ) }
			</ul>
		</div>
	);
};

SubNavigation.propTypes = {
	itemRoutes: PropTypes.array.isRequired,
};

/**
 * The SubNavigation routes.
 *
 * @param {Object} props The props to use.
 * @returns {ReactElement} The rendered route.
 */
const SubNavigationRoutes = props => {
	return (
		<Fragment>
			{ props.itemRoutes.map( ( route, routeKey ) => {
				return <Route exact={ true } key={ routeKey } path={ route.path } component={ route.component } />;
			} ) }
		</Fragment>
	);
};

SubNavigationRoutes.propTypes = {
	itemRoutes: PropTypes.array.isRequired,
};

export default SubNavigation;
export { SubNavigationRoutes };
