/*
 * Action types
 */

export const CREATE_TOKEN_MODAL_OPEN = "CREATE_TOKEN_MODAL_OPEN";
export const CREATE_TOKEN_MODAL_CLOSED = "CREATE_TOKEN_MODAL_CLOSED";

export const MANAGE_TOKEN_MODAL_OPEN = "MANAGE_TOKEN_MODAL_OPEN";
export const MANAGE_TOKEN_MODAL_CLOSED = "MANAGE_TOKEN_MODAL_CLOSED";


/*
 * Action creators
 */

/**
 * An action creator for the create token modal open action.
 *
 * @returns {Object} The create token modal open action.
 */
export function createTokenModalOpen() {
	return {
		type: CREATE_TOKEN_MODAL_OPEN,
	};
}

/**
 * An action creator for the create token modal open action.
 *
 * @returns {Object} The create token modal open action.
 */
export function createTokenModalClosed() {
	return {
		type: CREATE_TOKEN_MODAL_CLOSED,
	};
}

/**
 * An action creator for the manage token modal open action.
 *
 * @param {Object} data The data object of the token that should be managed in the modal.
 * @param {string} data.id The id of the composer token.
 * @param {string} data.name The name of the composer token.
 * @returns {Object} The manage token modal open action.
 */
export function manageTokenModalOpen( data ) {
	return {
		type: MANAGE_TOKEN_MODAL_OPEN,
		data: data,
	};
}

/**
 * An action creator for the manage token modal closed action.
 *
 * @returns {Object} The manage token modal closed action.
 */
export function manageTokenModalClosed() {
	return {
		type: MANAGE_TOKEN_MODAL_CLOSED,
	};
}
