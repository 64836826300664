import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { speak } from "@wordpress/a11y";
import _debounce from "lodash/debounce";
import { Alert, Button, Link, Title } from "@yoast/ui-library";
import { doRequest, prepareInternalRequest } from "shared-frontend/functions/api";

import { InstallContext } from "./InstallContext";
import SelectOrAddSite from "./SelectOrAddSite";
import NotCompatibleTYPO3Modal from "../modal/NotCompatibleTYPO3Modal";
import NoLicenseModal from "../modal/NoLicenseModal";
import stripCommonWrongSubdirectories from "../../functions/stripCommonWrongSubdirectories";
import * as styles from "./InstallStyles.scss";
import useToggleState from "../../reactHooks/useToggleState";
import useRequest from "../../reactHooks/useRequest";
import { isArray } from "lodash/lang";
import ErrorDisplay from "../../errors/ErrorDisplay";

const messages = defineMessages( {
	title: {
		id: "install.title",
		defaultMessage: "Thank you for your purchase!",
	},
	downloadButton: {
		id: "install.downloadButton",
		defaultMessage: "Download {addon}",
	},
	installManually: {
		id: "install.installManually",
		defaultMessage: "Would you prefer to install the plugin manually, or maybe using Composer? You can find your products in {link}.",
	},
	yourAccountLink: {
		id: "install.youraccountlink",
		defaultMessage: "your MyYoast account",
	},
	siteSelectedForInstallation: {
		id: "install.siteSelectedForInstallation",
		defaultMessage: "You've selected a site to install your products on, use the install button to continue.",
	},
	siteDoesNotExist: {
		id: "install.siteDoesNotExist",
		defaultMessage: "We can't find {link} online. Please enter URL to the existing site.",
	},
	urlCommonWrongSubdirectoryWarning: {
		id: "install.urlCommonWrongSubdirectoryWarning",
		defaultMessage: `It appears you have included "{warning}" in your url. This is allowed
						but not necessary. We will save your site as: {link}.`,
	},
	nextSteps1: {
		id: "install.nextstep.firstLine",
		defaultMessage: "Next, let's get started on installing {addon} on your website!",
	},
	nextSteps2: {
		id: "install.nextstep.secondLine",
		defaultMessage: "Please provide the site you'd like to install it on.",
	},
	loadingFailed: {
		id: "downloads.fetching.failed",
		defaultMessage: "Something went wrong while fetching your downloads. Please try again later. " +
			"If the problem persists, [customer_support_link]",
	},
} );

/* eslint-disable complexity */
/**
 * Install a subscription on a site flow for desktop.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const InstallDesktop = ( props ) => {
	const { setLinkedSite, setDownloadZip, invoiceNumberParam } = useContext( InstallContext );
	const [ selectedSite, setSelectedSite ]                     = useState( "" );

	const [ subdirectoryWarning, setSubdirectoryWarning ] = useState( "" );

	const [ isLoading, , , startLoading, stopLoading ]                                                     = useToggleState( false );
	const [ isNoLicensesModalOpen, , , openNoLicensesModal, closeNoLicensesModal ]                         = useToggleState( false );
	const [ isNotCompatibleTYPO3ModalOpen, , , openNotCompatibleTYPO3Modal, closeNotCompatibleTYPO3Modal ] = useToggleState( false );
	const mounted                                                                                          = useRef( false );

	const downloadsEndpoint = invoiceNumberParam
		? `Orders/${ invoiceNumberParam }/downloads`
		: `Customers/${ props.customerId }/downloads`;

	const {
		      data: availableDownloads,
		      isLoading: fetchingAvailableDownloads,
		      error: availableDownloadsError,
	      } = useRequest( downloadsEndpoint );


	useEffect( () => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, [] );

	/**
	 * Announces that the products can be installed on the site.
	 *
	 * @returns {void}
	 */
	const announceInstallReady = () => {
		if ( selectedSite ) {
			const message = props.intl.formatMessage( messages.siteSelectedForInstallation );
			speak( message );
		}
	};

	useEffect( () => {
		const speakInstallReadyMessageDebounced = _debounce( announceInstallReady, 1000 );
		speakInstallReadyMessageDebounced();
		/**
		 * Make sure the 'speak' is stopped when the component unmounts.
		 *
		 * @returns {void}
		 */
		return speakInstallReadyMessageDebounced.cancel;
	}, [ selectedSite ] );


	useEffect( () => {
		if ( ! isArray( availableDownloads ) || availableDownloads.length === 0 ) {
			return;
		}
		const yoastSeoDownload = availableDownloads.find( download => download.slug === "yoast-seo-wordpress-premium" );
		if ( yoastSeoDownload ) {
			setDownloadZip( yoastSeoDownload.url );
		} else {
			setDownloadZip( availableDownloads[ 0 ].url );
		}
	}, [ availableDownloads ] );

	/**
	 * Handles the error when linking a site to a subscription.
	 * @param {Object} error The API error object.
	 * @returns {void}
	 */
	function handleLinkSiteError( error ) {
		switch ( error.message ) {
			case "No valid subscriptions found.":
				openNoLicensesModal();
				break;
			case "Site type is not supported.":
				openNotCompatibleTYPO3Modal();
				break;
			default:
			// Do nothing.
		}
	}

	/**
	 * Sends a POST request to link the provided site URL to a subscription.
	 * Sets the linkedSite variable to the provided URL.
	 *
	 * @returns {object} The site with the subscription relation.
	 */
	async function linkSiteToSubscription() {
		if ( isLoading ) {
			return;
		}

		startLoading();

		try {
			await doRequest(
				prepareInternalRequest(
					"Subscriptions/link-site/",
					"POST", {
						url: selectedSite,
						invoiceNumber: invoiceNumberParam,
					},
				),
			);

			setLinkedSite( selectedSite );
		} catch ( error ) {
			if ( ! mounted.current ) {
				return;
			}
			handleLinkSiteError( error );
		} finally {
			if ( mounted.current ) {
				stopLoading();
			}
		}
	}

	/**
	 * Sets url on change of inputfield
	 *
	 * @param {string} url The input url from the client
	 *
	 * @returns {void} The stripped url.
	 */
	function onSiteUrlChange( url ) {
		const strippedUrlResult = stripCommonWrongSubdirectories( url );
		setSubdirectoryWarning( strippedUrlResult.strippedSubdirectory );
		setSelectedSite( strippedUrlResult.url );
	}

	const addon           = "Yoast SEO Premium";
	const readyToDownload = Boolean( selectedSite && ! isLoading && ! fetchingAvailableDownloads );

	return (
		<Fragment>
			<div className={ styles.textCenter }>
				<Title
					as="h1"
					size="2"
				>
					<FormattedMessage { ...messages.title } />
				</Title>
				<FormattedMessage { ...messages.nextSteps1 } values={ { addon } } />
				<br />
				<FormattedMessage { ...messages.nextSteps2 } />
			</div>

			{ availableDownloadsError &&
				<ErrorDisplay error={ { message: props.intl.formatMessage( messages.loadingFailed ), type: "error" } } />
			}

			<div className={ styles.ySpacing }>
				<SelectOrAddSite
					existingSites={ props.sites }
					onSiteUrlChange={ onSiteUrlChange }
					pluginName={ addon }
				/>
			</div>

			{ subdirectoryWarning &&
				<Alert variant="info">
					<FormattedMessage
						{ ...messages.urlCommonWrongSubdirectoryWarning }
						values={ { link: selectedSite, warning: subdirectoryWarning } }
					/>
				</Alert>
			}

			<div className={ styles.textCenter }>
				<Button
					id="install-addon-download-action"
					className="install-addon-download-button"
					onClick={ linkSiteToSubscription }
					disabled={ ! readyToDownload }
					aria-disabled={ ! readyToDownload }
				>
					<FormattedMessage { ...messages.downloadButton } values={ { addon } } />
				</Button>
			</div>
			<div className={ styles.textCenter }>
				<FormattedMessage
					{ ...messages.installManually }
					values={ {
						link: <Link as={ NavLink } to={ "/downloads" } id="install-manual-context">
							<FormattedMessage { ...messages.yourAccountLink } />
						</Link>,
					} }
				/>
			</div>

			{ isNoLicensesModalOpen && <NoLicenseModal closeModal={ closeNoLicensesModal } /> }

			{ isNotCompatibleTYPO3ModalOpen && <NotCompatibleTYPO3Modal closeModal={ closeNotCompatibleTYPO3Modal } /> }
		</Fragment>
	);
};

InstallDesktop.propTypes = {
	intl: intlShape.isRequired,
	sites: PropTypes.array,
	loadingSites: PropTypes.bool,
	submitAddSite: PropTypes.func.isRequired,
	plugins: PropTypes.array,
	customerId: PropTypes.string.isRequired,
};

InstallDesktop.defaultProps = {
	sites: [],
	plugins: [],
	loadingSites: false,
};

export default injectIntl( InstallDesktop );
