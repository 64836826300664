import styled from "styled-components";

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2em;
`;

export const ModalHeading = styled.h1`
	font-weight: 300;
	font-size: 1.5em;
	margin: 0;
`;
