/* External dependencies */
import { connect } from "react-redux";

/* Internal dependencies */
import OrderPage from "../components/account/orders/OrdersPage";
import { onSearchQueryChange } from "../redux/actions/search";
import { getOrders } from "shared-frontend/redux/actions/orders";
import { getRefunds } from "shared-frontend/redux/actions/refunds";
import { getSearchQuery } from "shared-frontend/redux/selectors/entities/search";
import { getOrderTransactions } from "shared-frontend/redux/selectors/entities/orders";
import { hasMixedSubscriptions, isOnlyProvisionerSubscriptions } from "../redux/selectors/ui/subscriptions";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return {
		transactions: getOrderTransactions( state ),
		query: getSearchQuery( state ),
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
		hasMixedSubscriptions: hasMixedSubscriptions( state ),
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		onSearchChange: ( query ) => {
			dispatch( onSearchQueryChange( query ) );
		},
		loadData: () => {
			dispatch( getOrders() );
			dispatch( getRefunds() );
			dispatch( getAllSubscriptions() );
		},
	};
};

const OrdersPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( OrderPage );

export default OrdersPageContainer;
