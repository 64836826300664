import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import * as styles from "./styles.scss";

/**
 * The SuggestedAction component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered SuggestedAction component.
 * @constructor
 */
function SuggestedAction( props ) {
	const {
		paragraphs,
		imageSource,
	} = props;

	return (
			<div className={ styles.suggestedActionContainer }>
				{ paragraphs && paragraphs.map( function ( paragraph ) {
					return <p key={ paragraph.props.id }>{ paragraph }</p>;
				} ) }
				{ props.children }
				{ imageSource && <img className={ styles.suggestedActionImage } src={ imageSource } alt=""/> }
			</div>
	);
}

export default injectIntl( SuggestedAction );

SuggestedAction.propTypes = {
	paragraphs: PropTypes.arrayOf( PropTypes.element ),
	imageSource: PropTypes.string,
	children: PropTypes.any,
};

SuggestedAction.defaultProps = {
	paragraphs: [],
	imageSource: null,
	children: null,
};
