import React from "react";
import pageNotFoundImage from "../images/PageNotFound.svg";
import { defineMessages, injectIntl, intlShape, FormattedMessage } from "react-intl";
import { speak } from "@wordpress/a11y";
import * as styles from "./PageNotFoundStyles.scss";
import { Title } from "@yoast/ui-library";

const messages = defineMessages( {
	pageNotFound: {
		id: "page.notfound",
		defaultMessage: "Page not found",
	},
} );

/**
 * A function that returns the Page Not Found component.
 *
 * @returns {ReactElement} The component that contains the Page Not Found page.
 */
class PageNotFound extends React.Component {
	/**
	 * Sets the PageNotFound object.
	 *
	 * @returns {void}
	 */
	constructor() {
		super();
	}

	// eslint-disable-next-line require-jsdoc
	componentDidMount() {
		// Announce navigation to assistive technologies.
		const message = this.props.intl.formatMessage( messages.pageNotFound );
		speak( message );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return <div className={ styles.pageContentWrapper }>
			<Title>
				<FormattedMessage id="page.notfound.header" defaultMessage={ "Oops" } />
			</Title>
			<FormattedMessage id="page.notfound" defaultMessage="The page you are looking for does not exist!" />
			<img className={ styles.pageImage } src={ pageNotFoundImage } alt="" />
		</div>;
	}
}

export default injectIntl( PageNotFound );

PageNotFound.propTypes = {
	intl: intlShape.isRequired,
};
