import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { Button, Card, Title } from "@yoast/ui-library";
import * as styles from "./ComposerTokenStyles.scss";

const messages = defineMessages( {
	manage: {
		id: "composerTokens.overview.manage",
		defaultMessage: "Manage token",
	},
	manageLabel: {
		id: "composerTokens.overview.manageLabel",
		defaultMessage: "Manage this token",
	},
} );

/**
 * A page order list using table abstraction.
 *
 * @param {Object} props Properties to be passed to the table.
 * @returns {ReactElement} A row of order stuff.
 */
function ComposerToken( props ) {
	const manageMessage = props.intl.formatMessage( messages.manage );
	const manageLabel = props.intl.formatMessage( messages.manageLabel );

	return (
		<Card>
			<Title as="h3" size="5">
				{ props.name }
			</Title>
			<Card.Content className={ styles.content }>
				<p>{ props.id }</p>
			</Card.Content>
			<Card.Footer className={ styles.footer }>
				<Button
					aria-label={ manageLabel }
					variant="secondary"
					size="small"
					onClick={ () => {
						props.onManageTokenClick( {
							name: props.name,
							id: props.id,
						} );
					} }
				>
					{ manageMessage }
				</Button>
			</Card.Footer>
		</Card>
	);
}

ComposerToken.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onManageTokenClick: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
};

export default injectIntl( ComposerToken );
