import { createSelector } from "reselect";
import { getSubscriptions } from "shared-frontend/redux/selectors/entities/subscriptions";

/**
 * Checks if all the subscriptions are provisioned and active
 *
 * @param {Object[]} subscriptions The subscriptions to check.
 * @returns {boolean} If all subscriptions are provisioned and active
 */
function hasOnlyActiveProvisionerSubscriptions( subscriptions ) {
	const provisionerSubscriptions = subscriptions.filter( subscription => subscription.provisionerId );
	const notEverySubscriptionIsProvisioned = ! subscriptions.every( subscription => subscription.provisionerId !== null );

	if ( provisionerSubscriptions.length === 0 ) {
		return false;
	}

	if ( notEverySubscriptionIsProvisioned ) {
		return false;
	}

	return provisionerSubscriptions.some( subscription => {
		const provisionerId = subscription.provisionerId;
		const statusNotCancelledOrExpired = ! [ "cancelled", "expired", "refunded", "deleted" ].includes( subscription.status );
		return provisionerId && statusNotCancelledOrExpired;
	} );
}

/**
 * Returns the add subscription modal state.
 *
 * @param {Object} state Application state.
 *
 * @returns {Object} The add subscription modal state.
 */
export function getAddSubscriptionModal( state ) {
	return state.ui.addSubscriptionModal;
}

/**
 * Returns the subscription requesting state.
 *
 * @param {Object} state Application state.
 *
 * @returns {boolean} The subscription requesting state.
 */
export function isRequestingSubscriptions( state ) {
	return state.ui.subscriptions.requesting;
}


/**
 * The ui state for the subscription cancel modal.
 *
 * @param   {Object} state The application state.
 * @returns {Object}       An object containing relevant ui information for the subscription cancel modal.
 */
export const getCancelSubscriptionState = ( state ) => {
	return {
		cancelLoading: state.ui.subscriptionsCancel.loading,
		cancelSuccess: state.ui.subscriptionsCancel.success,
		cancelError: state.ui.subscriptionsCancel.error,
	};
};

export const isOnlyProvisionerSubscriptions = createSelector(
	getSubscriptions,
	subscriptions => {
		const hasSubscriptions = !! subscriptions.length;
		const hasOnlyProvisionedSubscriptions = hasOnlyActiveProvisionerSubscriptions( subscriptions );
		return hasSubscriptions && hasOnlyProvisionedSubscriptions;
	},
);

export const hasShopifySubscription = createSelector(
	getSubscriptions,
	subscriptions => {
		return subscriptions.some( sub => sub.provisionerId === "shopifyP-0089-4993-9864-a09b4502503e" );
	},
);

export const hasProvisionedSubscription = createSelector(
	getSubscriptions,
	subscriptions => {
		return subscriptions.some( sub => sub.provisionerId !== null );
	},
);

export const hasMixedSubscriptions = createSelector(
	getSubscriptions,
	subscriptions => {
		const hasSubscriptions = !! subscriptions.length;

		const hasYoastSubscriptions                = subscriptions.some( subscription => ! subscription.provisionerId );
		const hasUnmanagedProvisionedSubscriptions = subscriptions.some( subscription => {
			const provisionerId = subscription.provisionerId;
			const statusNotCancelledOrExpired = ! [ "cancelled", "expired", "refunded", "deleted" ].includes( subscription.status );
			const doesNotAllowSiteChange = ! subscription.provisionerAllowsSiteChange;

			return provisionerId && statusNotCancelledOrExpired && doesNotAllowSiteChange;
		} );
		return hasSubscriptions && hasYoastSubscriptions && hasUnmanagedProvisionedSubscriptions;
	},
);

export const provisioners = createSelector(
	getSubscriptions,
	subscriptions => {
		const provisionersArray = subscriptions.map( subscription => subscription.provisionerName );

		if ( provisionersArray.length === 0 ) {
			return [];
		}

		// Strip duplicates and return unique values only.
		return [ ...new Set( provisionersArray ) ];
	},
);
