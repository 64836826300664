import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Alert } from "@yoast/ui-library";
import * as styles from "./DownloadsPageStyles.scss";
import { connect } from "react-redux";
import {
	hasMixedSubscriptions as hasMixedSubscriptionsSelector,
	isOnlyProvisionerSubscriptions as isOnlyProvisionerSubscriptionsSelector,
	provisioners as provisionersSelector,
} from "../../redux/selectors/ui/subscriptions";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";


const messages = defineMessages( {
	infoDownloadsOnlyProvisionedSubscriptions: {
		id: "downloadsPage.infoProvisionedNotShopify",
		defaultMessage: "You have sites with one or more of our provisioning partners. " +
			"The download and installation of these products are handled on purchase by them.",
	},
	infoDownloadsMixedProvisionedSubscriptions: {
		id: "downloadsPage.infoMixedProvisionedSubscriptions",
		defaultMessage: "On this page, you can download zip files related to your Yoast SEO products. " +
			"The download and installation of products purchased through third parties " +
			"are handled by their teams respectively.",
	},
} );

/**
 * Returns an Alert if certain conditions are met.
 *
 * @param {Object} props The component props.
 * @param {Function} props.loadSubscriptions The function to load subscriptions.
 * @param {Boolean} props.isOnlyProvisionerSubscriptions Does user have only provisioned subscriptions?
 * @param {Boolean} props.hasMixedSubscriptions Does user have subscriptions mixed between Yoast and any of the provisioners?
 * @param {string[]} props.provisioners The provisioners the user has subscriptions with.
 *
 * @returns {Boolean|false|ReactElement} An info alert or false if conditions are not met.
 */
export const ProvisionerDownloadAlert = (
	{
		loadSubscriptions,
		isOnlyProvisionerSubscriptions,
		hasMixedSubscriptions,
		provisioners,
	},
) => {
	useEffect( () => {
		loadSubscriptions();
	}, [] );

	const onlyProvisionedByWoo = provisioners.every( ( provisioner ) => provisioner === "WooCommerce" );

	if ( onlyProvisionedByWoo ) {
		return null;
	}

	if ( ! isOnlyProvisionerSubscriptions && ! hasMixedSubscriptions ) {
		return null;
	}

	return <Alert
		className={ styles.downloadsPageAlert }
		as="div"
		variant="info"
		role="alert"
	>
		{ isOnlyProvisionerSubscriptions &&
			<FormattedMessage { ...messages.infoDownloadsOnlyProvisionedSubscriptions } /> }
		{ hasMixedSubscriptions &&
			<FormattedMessage { ...messages.infoDownloadsMixedProvisionedSubscriptions } /> }
	</Alert>;
};

ProvisionerDownloadAlert.propTypes = {
	loadSubscriptions: PropTypes.func.isRequired,
	isOnlyProvisionerSubscriptions: PropTypes.bool.isRequired,
	hasMixedSubscriptions: PropTypes.bool.isRequired,
	provisioners: PropTypes.arrayOf( PropTypes.string ).isRequired,
};


/* eslint-disable require-jsdoc */
const mapStateToProps = ( state ) => {
	return {
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptionsSelector( state ),
		hasMixedSubscriptions: hasMixedSubscriptionsSelector( state ),
		provisioners: provisionersSelector( state ),
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		loadSubscriptions: () => dispatch( getAllSubscriptions() ),
	};
};

/* eslint-enable */

export const ProvisionerDownloadAlertContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( ProvisionerDownloadAlert );

export default ProvisionerDownloadAlertContainer;
