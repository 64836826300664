import { connect } from "react-redux";

import DownloadsPage from "../components/Downloads/DownloadsPage.js";
import { onSearchQueryChange } from "../redux/actions/search";
import { getUserId } from "../redux/selectors/ui/user";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return {
		customerId: getUserId( state ),
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		onSearchChange: ( query ) => {
			dispatch( onSearchQueryChange( query ) );
		},
	};
};

const DownloadsPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( DownloadsPage );

export default DownloadsPageContainer;
