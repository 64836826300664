import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import validate from "validate.js";
import _isUndefined from "lodash/isUndefined";
import { emailConstraints } from "../../login/CommonConstraints";
import { TextField } from "@yoast/ui-library";
import { getChangeButtons } from "./FormElements";
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import isEmpty from "lodash/isEmpty";
import * as styles from "./ProfileFormStyles.scss";

/**
 * Format the validation errors in a way that our ErrorDisplay can handle.
 *
 * @param {object[]} errors The errors thrown by the validator.
 *
 * @returns {{attribute: string, message: string}[]} A formatted error.
 */
validate.formatters.custom = ( errors ) => {
	return errors.map( error => {
		return {
			attribute: error.attribute,
			message: error.options.message,
		};
	} );
};

const messages = defineMessages( {
	duplicateEmail: {
		id: "profile.error.duplicateEmail",
		defaultMessage: "The email address could not be changed, it is probably already in use.",
	},
	labelEmail: {
		id: "profile.label.email",
		defaultMessage: "Primary email address",
	},
	labelFirstName: {
		id: "profile.label.firstName",
		defaultMessage: "First name",
	},
	labelLastName: {
		id: "profile.label.lastName",
		defaultMessage: "Last name",
	},
} );

/**
 * Renders the ProfileForm component.
 *
 * @param {object} props Properties object.
 * @returns {ReactElement} The rendered ProfileForm component.
 * @constructor
 */
const ProfileForm = ( props ) => {
	const [ userFirstName, setUserFirstName ] = useState( props.userFirstName );
	const [ userLastName, setUserLastName ]   = useState( props.userLastName );
	const [ email, setEmail ]                 = useState( props.email );
	const [ onDiscard, setOnDiscard ]       = useState( false );
	const [ warnings, setWarnings ]              = useState( {} );

	useEffect( () => {
		props.resetSaveMessage();
	}, [] );

	const validateFields = ( email ) => {
		const constraints = {
			email: emailConstraints( props.intl ),
		};

		const warnings = validate( {
			email: email,
		}, constraints, { format: "custom" } );

		if ( _isUndefined( warnings ) ) {
			return {};
		}

		return {
			variant: "warning",
			message: warnings[ 0 ].message,
		};
	};

	/**
	 * Check if the email is not empty.
	 * @returns {boolean} Whether the email is not empty.
	 */
	const canSave = () => {
		return email.replace( /\s/g, "" ) !== "";
	};

	const discardChanges = () => {
		setUserFirstName( props.userFirstName );
		setUserLastName( props.userLastName );
		setEmail( props.email );
		setOnDiscard( true );
	};

	/**
	 * Check if the form is saved.
	 * @returns {boolean} Whether the form is saved.
	 */
	const isSaved = () => {
		return props.isSaved && ! onDiscard &&
			props.userFirstName === userFirstName &&
			props.userLastName === userLastName &&
			props.email === email;
	};

	/**
	 * Update the email address.
	 * @param {object} event Event object.
	 * @returns {void} Nothing.
	 */
	const onUpdateEmail = ( event ) => {
		const newEmail = event.target.value;
		const warning  = validateFields( newEmail );
		setEmail( newEmail );
		setWarnings( warning );
	};

	const onUpdateName = ( type, event ) => {
		if ( type === "first" ) {
			setUserFirstName( event.target.value );
			return;
		}

		setUserLastName( event.target.value );
	};

	const handleSubmit = ( event ) => {
		event.preventDefault();
		if ( props.isSaving || ! isEmpty( warnings ) ) {
			return;
		}
		const profile = {
			first_name: userFirstName,
			last_name: userLastName,
			email: email,
		};

		setOnDiscard( false );
		props.onSaveProfile( profile );
	};

	return (
		<form onSubmit={ handleSubmit } className={ styles.profileForm }>
			<div>
				<TextField
					id="first-name"
					name="first name"
					type="text"
					label={ props.intl.formatMessage( messages.labelFirstName ) }
					value={ userFirstName }
					onChange={ event => onUpdateName( "first", event ) }
				/>
			</div>
			<div>
				<TextField
					id="last-name"
					name="last name"
					type="text"
					label={ props.intl.formatMessage( messages.labelLastName ) }
					value={ userLastName }
					onChange={ event => onUpdateName( "last", event ) }
				/>
			</div>
			<div>
				<TextField
					id="email-address"
					autoComplete="on"
					name="email"
					type="text"
					label={ props.intl.formatMessage( messages.labelEmail ) }
					value={ email }
					onChange={ onUpdateEmail }
					validation={ warnings }
				/>

				<ErrorDisplay error={ props.saveEmailError } />
			</div>
			{ getChangeButtons(
				isEmpty( warnings ) && canSave(),
				props.isSaving,
				isSaved(),
				discardChanges,
			) }
		</form>
	);
};

ProfileForm.propTypes = {
	intl: PropTypes.object.isRequired,
	onSaveProfile: PropTypes.func.isRequired,
	email: PropTypes.string,
	userFirstName: PropTypes.string,
	userLastName: PropTypes.string,
	isSaving: PropTypes.bool,
	isSaved: PropTypes.bool,
	saveEmailError: ErrorPropTypeShape,
	resetSaveMessage: PropTypes.func.isRequired,
};

ProfileForm.defaultProps = {
	email: "",
	userFirstName: "",
	userLastName: "",
	isSaving: false,
	isSaved: false,
	saveEmailError: null,
};

export default injectIntl( ProfileForm );
