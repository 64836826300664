/* eslint-disable complexity */
import util from "util";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { speak } from "@wordpress/a11y";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import _debounce from "lodash/debounce";

import Sites from "./Sites";
import Search from "../Search/Search";
import AnimatedLoader from "../Loader";
import { PageHeader } from "../PageHeader";
import AddSiteModal from "../modal/AddSiteModal";
import { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import { Button, Alert } from "@yoast/ui-library";
import * as styles from "./SitesPageStyles.scss";

const messages = defineMessages( {
	sitesPageLoaded: {
		id: "menu.sites.loaded", defaultMessage: "Sites page loaded",
	},
	sitesPageTitle: {
		id: "page.sites.title", defaultMessage: "Sites",
	},
	sitesPageDescription: {
		id: "page.sites.description",
		defaultMessage: "You can easily add and manage your websites here. " +
			"Activate your product subscription(s) per site by using the toggles in \"Manage\". " +
			"This ensures that all the features of your product are unlocked.",
	},
	sitesPageNoSites: {
		id: "pages.sites.noSites", defaultMessage: "You have no sites yet. Add a site by clicking the button below.",
	},
	searchResults: {
		id: "sitesSearch.results", defaultMessage: "Number of sites found: %d",
	},
	searchLabel: {
		id: "search.label.sites", defaultMessage: "Search sites",
	},
	addSite: {
		id: "sites.addSiteButton", defaultMessage: "Add a site",
	},
	infoProvisionedAndMixedSubscriptions: {
		id: "sites.infoProvisionedAndMixedSubscriptions",
		defaultMessage: "You have sites with one or more of our provisioning partners. " +
			"Our partners handle installation and activation of our plugin and apps for you, so you don’t need to add and manage those sites here.",
	},
} );

const debouncedSpeak = _debounce( speak, 1000 );

/**
 * Returns sites page actions bar.
 *
 * @param {Function} addSite Add site function.
 * @param {Object} intl Intl object.
 * @returns {ReactElement} A buttons wrapped inside the div.
 */
const SitesPageActions = ( { addSite, intl } ) => {
	return <div className={ styles.sitesPageActions }>
		<Button
			id="add-site__button"
			className={ styles.sitesPageActionsButton }
			onClick={ addSite }
			aria-label={ intl.formatMessage( messages.addSite ) }
		>
			<PlusSmallIcon className={ styles.sitesPageIcon } />
			<FormattedMessage
				id={ messages.addSite.id }
				defaultMessage={ messages.addSite.defaultMessage }
			/>
		</Button>
	</div>;
};

SitesPageActions.propTypes = {
	addSite: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
};

/**
 * Renders a message when user has no sites added.
 * @returns {ReactElement} A message wrapped inside the div.
 */
const SitesPageNoSites = () => {
	return <div className={ styles.sitesPageNoSites }>
		<FormattedMessage { ...messages.sitesPageNoSites } />
	</div>;
};

/**
 * Returns n Alert if certain conditions are met.
 *
 * @param {Boolean} isOnlyProvisionerSubscriptions Does user have only provisioned subscriptions?
 * @param {Boolean} hasMixedSubscriptions Does user have subscriptions mixed between Yoast and any of the provisioners?
 * @param {Object} intl Intl object.
 *
 * @returns {Boolean|false|ReactElement} An info alert or false if conditions are not met.
 */
const SitesPageAlert = ( { isOnlyProvisionerSubscriptions, hasMixedSubscriptions, intl } ) => {
	return ( isOnlyProvisionerSubscriptions || hasMixedSubscriptions ) && (
		<Alert
			className={ styles.sitesPageAlert }
			as="div"
			variant="info"
			role="alert"
		>
			{ intl.formatMessage( messages.infoProvisionedAndMixedSubscriptions ) }
		</Alert>
	);
};

SitesPageAlert.propTypes = {
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool,
	intl: intlShape.isRequired,
};

/**
 * Returns the rendered Sites Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered Sites component.
 */
const SitesPage = props => {
	/**
	 * Announces the search results to assistive technologies.
	 *
	 * @param {Object} nextProps The new props the component has received.
	 *
	 * @returns {void}
	 */
	const speakSearchResultsMessage = () => {
		if ( props.query.length > 0 ) {
			const message = util.format( props.intl.formatMessage( messages.searchResults ), props.sites.length );

			debouncedSpeak( message, "assertive" );
		}
	};

	useEffect( () => {
		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.sitesPageLoaded );

		speak( message );
	}, [] );

	const isFirstRun = useRef( true );
	useEffect( () => {
		if ( isFirstRun.current ) {
			isFirstRun.current = false;
			return;
		}
		/*
		 * While typing or pasting in the search field, `componentWillReceiveProps()`
		 * continously passes a new `query` props. We use this at our advantage
		 * to debounce the call to `speak()`.
		 * Note: remember for <input> and <textarea>, React `onChange` behaves
		 * like the DOM's built-in oninput event handler.
		 */
		speakSearchResultsMessage();
	}, [ props.query ] );

	if ( props.loadingSites ) {
		return <AnimatedLoader />;
	}

	const hasSites   = props.sites.length > 0;
	const isSearch   = props.query.length > 0;
	const showSearch = hasSites || isSearch;

	const hideForWooCommerceProvisioner = props.provisioners.length === 1 && props.provisioners.includes( "WooCommerce" );

	return <>
		<PageHeader title={ messages.sitesPageTitle } message={ messages.sitesPageDescription } />

		{ ( ! hasSites && ! isSearch ) && <SitesPageNoSites /> }

		<SitesPageActions addSite={ props.addSite } intl={ props.intl } />

		<div className={ styles.sitesPageContentWrapper }>
			{ ! hideForWooCommerceProvisioner && <SitesPageAlert { ...props } /> }

			{ showSearch && <Search
				id="search"
				searchLabel={ props.intl.formatMessage( messages.searchLabel ) }
				descriptionId="search-description"
				onChange={ props.onSearchChange }
				query={ props.query }
			/> }
			{ ( props.query.length > 0 && props.sites.length === 0 ) &&
				<p className={ styles.noResults }>
					<FormattedMessage
						id="sites.search.noResults"
						key="sites.search.noResults"
						defaultMessage={ "We could not find any sites matching { query }." }
						values={ { query: <strong>{ props.query }</strong> } }
					/>
				</p> }


			{ hasSites && <Sites
				sites={ props.sites }
				onManage={ props.onManage }
			/> }
		</div>

		<AddSiteModal { ...props } />
	</>;
};

SitesPage.propTypes = {
	addSite: PropTypes.func.isRequired,
	onSearchChange: PropTypes.func.isRequired,
	onConnect: PropTypes.func.isRequired,
	onManage: PropTypes.func.isRequired,
	linkError: ErrorPropTypeShape,
	sites: PropTypes.arrayOf( PropTypes.object ),
	availableSites: PropTypes.arrayOf( PropTypes.object ),
	linkingSiteUrl: PropTypes.string,
	query: PropTypes.string,
	loadingSites: PropTypes.bool,
	modalOpen: PropTypes.bool,
	intl: intlShape.isRequired,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool,
	provisioners: PropTypes.array,
};

SitesPage.defaultProps = {
	sites: [],
	availableSites: [],
	modalOpen: false,
	linkError: null,
	loadingSites: false,
	query: "",
	linkingSiteUrl: "",
	isOnlyProvisionerSubscriptions: false,
	hasMixedSubscriptions: false,
	provisioners: [],
};

export default injectIntl( SitesPage );
