import React from "react";
import { injectIntl, intlShape, FormattedMessage, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import ProfileDetailsBlock from "../account/profile/ProfileDetailsBlock";
import logo from "../../images/my-yoast-academy-logo.svg";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { Title, Button } from "@yoast/ui-library";
import * as styles from "./ProfileDetailsStyles.scss";

const messages = defineMessages( {

	header: {
		id: "enterDetails.header",
		defaultMessage: "Enter your details",
	},
	description: {
		id: "profileDetails.message",
		defaultMessage: "Your MyYoast account has been activated! Fill out this last bit " +
			"of information and you can get started. Don't worry, you can always change these later!",
	},
	goToMyYoast: {
		id: "profileDetails.goToMyYoast",
		defaultMessage: "Continue to MyYoast",
	},
	savingProfile: {
		id: "profileDetails.savingProfile",
		defaultMessage: "Saving your profile...",
	},
} );

/**
 * Component in first login flow, where the user is asked to
 * enter some personal details: first name, last name and profile image.
 */
class ProfileDetails extends React.Component {
	/**
	 * Constructor
	 *
	 * @param {object} props The props.
	 */
	constructor( props ) {
		super( props );

		this.handleSubmit = this.handleSubmit.bind( this );
	}

	/**
	 * Submits the entered first name, last name and profile image to the server.
	 *
	 * @param {string} firstName The entered first name.
	 * @param {string} lastName The entered last name.
	 *
	 * @returns {void}
	 */
	handleSubmit( firstName, lastName ) {
		/* eslint-disable camelcase */
		this.props.attemptSubmitProfile( { first_name: firstName, last_name: lastName } );
		/* eslint-enable camelcase */
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		let saveMessage = messages.goToMyYoast;
		if ( this.props.savingProfile ) {
			saveMessage = messages.savingProfile;
		}

		if ( this.props.profileSaved && isEmpty( this.props.pendingRequests ) && isEmpty( this.props.profileSaveError ) ) {
			/*
			 * Because there are two asynchronous request modifying the user, we can't trust the resulting user state.
			 * Because of this, we have to fetch the user's Profile again and store it in the Redux state.
			 * We don't have to wait for this, so we redirect instantly.
			 */
			if ( this.props.fetchProfile ) {
				this.props.fetchProfile();
			}
			return <Redirect to={ "/" } />;
		}

		return <div className={ styles.firstColumn }>
			<img className={ styles.asideLogo } src={ logo } alt="MyYoast - Yoast Academy" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.description } />
			</p>
			<ErrorDisplay error={ this.props.profileSaveError } />
			<ProfileDetailsBlock
				onSubmit={ this.handleSubmit }
				userFirstName={ this.props.profile.userFirstName }
				userLastName={ this.props.profile.userLastName }
			>
				<Button type="submit">
					<FormattedMessage { ...saveMessage } />
				</Button>
			</ProfileDetailsBlock>
		</div>;
	}
}

export default injectIntl( ProfileDetails );

ProfileDetails.propTypes = {
	intl: intlShape.isRequired,
	attemptSubmitProfile: PropTypes.func.isRequired,
	fetchProfile: PropTypes.func.isRequired,
	savingProfile: PropTypes.bool,
	profileSaved: PropTypes.bool,
	profile: PropTypes.object,
	pendingRequests: PropTypes.array,
	profileSaveError: ErrorPropTypeShape,
};

ProfileDetails.defaultProps = {
	savingProfile: false,
	profileSaved: false,
	profile: {
		email: "",
		userFirstName: "",
		userLastName: "",
	},
	pendingRequests: [],
	profileSaveError: null,
};
