import React, { useContext } from "react";
import { phases, PluginInstallerContext, PluginInstallerContextProvider } from "./PluginInstallerContext";
import SiteToInstallForm from "./SiteToInstallForm";
import * as styles from "./styles.scss";
import YoastLogo from "../../icons/Yoast-logo-without-mission.svg";
import classNames from "classnames";
import PluginInstallProcessor from "./PluginInstallProcessor";

/**
 * Checks which component should be rendered.
 * @returns {ReactComponent} The component to show to the customer for the plugin installer flow.
 */
const PluginInstallerWrapper = () => {
	/**
	 * Determine the active component to render.
	 * @returns {Element} The active component.
	 */
	const ActiveComponent = () => {
		const { phase } = useContext( PluginInstallerContext );

		if ( [
			phases.FETCHING_EMAIL,
			phases.INSTALLING_PLUGIN,
			phases.REVOKING_TOKEN,
			phases.FINISHED_INSTALLING,
			phases.INSTALLATION_FAILURE,
		].includes( phase ) ) {
			return <PluginInstallProcessor />;
		}

		return <SiteToInstallForm />;
	};

	return (
		<>
			<PluginInstallerContextProvider>
				<>
					<div className={ styles.header }>
						<div className={ classNames( styles.headerContainer, styles.wideContent ) }>
							<div className={ styles.headerLogo }>
								<img src={ YoastLogo } alt="Yoast SEO for everyone" />
							</div>
						</div>
					</div>
					<div className={ styles.pluginInstallerContent }>
						<ActiveComponent />
					</div>
				</>
			</PluginInstallerContextProvider>
		</>
	);
};

export default PluginInstallerWrapper;
