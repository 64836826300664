/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import validate from "validate.js";
import _isUndefined from "lodash/isUndefined";
import queryString from "query-string";
import _debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
import { speak } from "@wordpress/a11y";

/* Internal dependencies */
import { passwordRepeatConstraint } from "./CommonConstraints";
import logo from "../../images/my-yoast-academy-logo.svg";
import ValidationInputField from "../ValidationInputField";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { validatePasswordStrength } from "../../functions/validatePasswordStrength";
import * as styles from "./ResetPasswordPageStyles.scss";
import { Title, Button } from "@yoast/ui-library";

const messages = defineMessages( {
	pageTitle: {
		id: "reset.pageTitle",
		defaultMessage: "Reset password",
	},
	labelPassword: {
		id: "reset.newPassword",
		defaultMessage: "New password",
	},
	labelPasswordRepeat: {
		id: "reset.newPasswordRepeat",
		defaultMessage: "Repeat new password",
	},
	resetMessage: {
		id: "reset.message",
		defaultMessage: "Please enter a new password for the MyYoast account linked to username {username}.",
	},
	resetButton: {
		id: "reset.button",
		defaultMessage: "Confirm password change",
	},
	loadingButton: {
		id: "reset.button.loading",
		defaultMessage: "Saving password...",
	},
} );

/**
 * Page to change the password of an account.
 */
class ResetPasswordPage extends React.Component {
	/**
	 * Constructor
	 *
	 * @param {object} props The props Passed to the Reset Password Page component.
	 */
	constructor( props ) {
		super( props );

		const parsedQuery = queryString.parse( this.props.location.search, { arrayFormat: "bracket" } );

		// Default state.
		this.state = {
			userLogin: parsedQuery.login || "",
			key: parsedQuery.key || "",
			username: parsedQuery.login || this.props.username,
			password: "",
			passwordRepeat: "",
			passwordValidation: {
				isFilledIn: false,
				score: 0,
				isStrongEnough: false,
				feedback: {
					suggestions: [],
					warning: "",
				},
				errors: [],
				isAllowedPassword: false,
			},
			passwordRepeatErrors: [],
		};

		this.onUpdatePassword       = this.onUpdate.bind( this, "password" );
		this.onUpdatePasswordRepeat = this.onUpdate.bind( this, "passwordRepeat" );

		this.handleSubmit             = this.handleSubmit.bind( this );
		this.validatePassword         = _debounce( this.validatePassword.bind( this ), 500 );
		this.announceValidationErrors = _debounce( this.announceValidationErrors, 1000 );
	}

	/**
	 * Validates the password field using the zxcvbn module.
	 *
	 * @param {string} value The value of the password field.
	 *
	 * @returns {void}
	 */
	validatePassword( value ) {
		this.setState( { passwordValidation: validatePasswordStrength( value, [] ) } );
	}

	/**
	 * Updates the specified field in the state,
	 * to be used as callback functions in text input fields.
	 *
	 * @param {string} field The field in the state that should be updated.
	 * @param {string} value The new value of the input field.
	 *
	 * @returns {void}
	 */
	onUpdate( field, value ) {
		// Set the updated field in the state.
		const newState = {
			[ field ]: value,
		};
		this.setState( newState, this.validate );

		if ( field === "password" ) {
			this.validatePassword( value );
		}
	}

	/**
	 * Validates the password and password repeat fields
	 * and returns an array of errors if there are any,
	 * and an empty array if none are present.
	 *
	 * @returns {void}
	 */
	validate() {
		let passwordRepeatErrors = validate( {
			password: this.state.password,
			passwordRepeat: this.state.passwordRepeat,
		}, { passwordRepeat: passwordRepeatConstraint( this.props.intl ) }, { format: "detailed" } );

		passwordRepeatErrors = ( this.state.passwordRepeat.length > 0 && ! _isUndefined( passwordRepeatErrors ) )
			? [ passwordRepeatErrors[ 0 ].options.message ] : [];

		this.setState( { passwordRepeatErrors: passwordRepeatErrors }, this.announceValidationErrors );
	}

	/**
	 * Announces the form validation errors.
	 *
	 * @returns {void}
	 */
	announceValidationErrors() {
		speak( this.getAlertContents( this.state.passwordValidation.feedback.suggestions ).toString() );
	}

	/**
	 * Checks whether the form may be submitted.
	 *
	 * @returns {boolean} Whether or not the form may be submitted.
	 */
	canSubmit() {
		return ( ! isEmpty( this.state.password ) ) &&
			( ! isEmpty( this.state.passwordRepeat ) ) &&
			isEmpty( this.props.submitError ) &&
			this.state.passwordRepeatErrors.length <= 0 &&
			this.state.passwordValidation.isAllowedPassword;
	}

	/**
	 * Resets the user's password.
	 *
	 * @param { object } event The button click event.
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		if ( ! this.canSubmit() ) {
			return;
		}

		const data = {
			/* eslint-disable camelcase */
			user_login: this.state.userLogin || "",
			password1: this.state.password,
			password2: this.state.passwordRepeat,
			key: this.state.key || "",
			/* eslint-enable camelcase */
		};

		this.props.attemptResetPassword( data );
	}

	/**
	 * Puts all found validation errors in a single array of strings, and returns it.
	 *
	 * @param {Array<string>} passwordErrors The errors found for the password field.
	 *
	 * @returns {Array.<string>} An array of validation errors.
	 */
	getAlertContents( passwordErrors ) {
		if ( _isUndefined( passwordErrors ) ) {
			passwordErrors = [];
		}

		return passwordErrors.concat( this.state.passwordRepeatErrors );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		if ( this.props.passwordResetSuccess ) {
			return <Redirect to={ "/forgot-password/success" } />;
		}

		let buttonText = messages.resetButton;
		if ( this.props.loading ) {
			buttonText = messages.loadingButton;
		}

		return (
			<div className={ styles.firstColumn }>
				<img src={ logo } alt="MyYoast - Yoast Academy" />
				<Title size="2">
					<FormattedMessage { ...messages.pageTitle } />
				</Title>
				<p>
					<FormattedMessage
						id={ messages.resetMessage.id }
						defaultMessage={ messages.resetMessage.defaultMessage }
						values={ { username: this.state.username } }
					/>
				</p>
				<form onSubmit={ this.handleSubmit }>
					<ErrorDisplay error={ this.props.submitError } />
					<ValidationInputField
						id="password"
						name="password"
						type="password"
						label={ this.props.intl.formatMessage( messages.labelPassword ) }
						onChange={ this.onUpdatePassword }
						errors={ this.state.passwordValidation.feedback.suggestions }
						value={ this.state.password }
					/>
					<ValidationInputField
						id="password-repeat"
						name="repeat password"
						type="password"
						label={ this.props.intl.formatMessage( messages.labelPasswordRepeat ) }
						onChange={ this.onUpdatePasswordRepeat }
						errors={ this.state.passwordRepeatErrors }
						value={ this.state.passwordRepeat }
					/>

					<div className={ styles.actions }>
						<Button
							type="submit"
							disabled={ ! this.canSubmit() }
							aria-disabled={ ! this.canSubmit() }
						>
							<FormattedMessage { ...buttonText } />
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

ResetPasswordPage.propTypes = {
	intl: intlShape.isRequired,
	username: PropTypes.string,
	attemptResetPassword: PropTypes.func.isRequired,
	submitError: ErrorPropTypeShape,
	location: PropTypes.object.isRequired,
	passwordResetSuccess: PropTypes.bool,
	loading: PropTypes.bool,
};

ResetPasswordPage.defaultProps = {
	username: "your username",
	loading: false,
	submitError: null,
	passwordResetSuccess: false,
};

export default injectIntl( ResetPasswordPage );
