import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { push } from "connected-react-router";
import { orderBy } from "lodash/collection";

import { getAllSites } from "shared-frontend/redux/selectors/entities/sites";
import { linkSite, retrieveSites, updateSiteUrl } from "shared-frontend/redux/actions/sites";

import { linkSiteModalClose, linkSiteModalOpen } from "../../redux/actions/sites";
import { Button, Card, Label, Title } from "@yoast/ui-library";
import AddSiteModal from "../modal/AddSiteModal";
import * as styles from "./SitesCardStyles.scss";
import classNames from "classnames";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";

const messages = defineMessages( {
	cardTitle: {
		id: "home.siteCard.title",
		defaultMessage: "Activate your product subscription(s)",
	},
	cardDescription: {
		id: "home.siteCard.description",
		defaultMessage: "Add the URL of your site and activate the product subscription to unlock all the features of your Premium product.",
	},
	cardNoSites: {
		id: "home.siteCard.noSites",
		defaultMessage: "Add your first site to your MyYoast account!",
	},
	cardButtonAddText: {
		id: "home.siteCard.buttonAddText",
		defaultMessage: "Add a site",
	},
	cardButtonManageText: {
		id: "home.siteCard.buttonManageText",
		defaultMessage: "Manage",
	},
	cardButtonManageSitesText: {
		id: "home.siteCard.buttonManageSitesText",
		defaultMessage: "Manage all sites",
	},
} );

/**
 * A components that lists user's sites.
 *
 * @param {Object} props Component props.
 * @returns {JSX.Element} Styled unordered list that contains markup and logic to print sites.
 */
const SitesList = ( { sites } ) => {
	const sitesLength = sites.length;
	return (
		<>
			<Label
				as="p"
				label={ `My sites (${ sitesLength })` }
				className={ styles.sitesLabel }
			/>
			<ul>
				{
					sites.slice( 0, 3 ).map( site => {
						return (
							<li
								key={ site.id }
								className={ styles.sitesListItem }
							>
								<span>{ site.hostname }</span>
								<Button
									as="a"
									variant="tertiary"
									type="button"
									className={ styles.sitesListItem }
									href={ `/sites/${site.id}` }
								>
									Manage
								</Button>
							</li>
						);
					} )
				}
			</ul>
		</>
	);
};

SitesList.propTypes = {
	sites: PropTypes.array.isRequired,
};

/**
 * A function that returns the SitesCard component.
 *
 * @param {Object} props The props required for the SitesCard.
 *
 * @returns {JSX.Element} The component that contains the progress tab of the course page.
 */
const SitesCard = ( props ) => {
	useEffect( () => {
		props.loadSites();
	}, [] );

	return (
		<Card>
			<div className={ styles.sitesCardHeader }>
				<Title as="h2" size="4">
					<FormattedMessage
						id={ messages.cardTitle.id }
						defaultMessage={ messages.cardTitle.defaultMessage }
					/>
				</Title>
				<p>
					<FormattedMessage
						id={ messages.cardDescription.id }
						defaultMessage={ messages.cardDescription.defaultMessage }
					/>
				</p>
			</div>
			<Card.Content>
				{ props.sites.length > 0 && <SitesList sites={ props.sites } /> }
				<div className={ classNames( styles.sitesButtonWrapper, { "yst-mt-4": props.sites.length > 0  } ) }>
					<Button
						id="add-site"
						onClick={ props.onClick }
						aria-label={ props.intl.formatMessage( messages.cardButtonAddText ) }
						className={ styles.sitesButton }
					>
						<PlusSmallIcon className={ styles.sitesButtonIcon } />
						<FormattedMessage
							id={ messages.cardButtonAddText.id }
							defaultMessage={ messages.cardButtonAddText.defaultMessage }
						/>
					</Button>
					{ props.sites.length > 3 &&
						<Button
							id="manage-sites"
							onClick={ () => props.navigateToSites() }
							variant="secondary"
							className={ styles.sitesButton }
						>
							<AdjustmentsHorizontalIcon className={ styles.sitesButtonIcon } />
							<FormattedMessage
								id={ messages.cardButtonManageSitesText.id }
								defaultMessage={ messages.cardButtonManageSitesText.defaultMessage }
							/>
						</Button>
					}
				</div>
				<AddSiteModal { ...props } />
			</Card.Content>
		</Card>
	);
};

SitesCard.propTypes = {
	intl: intlShape.isRequired,
	onClick: PropTypes.func.isRequired,
	loadSites: PropTypes.func.isRequired,
	navigateToSites: PropTypes.func.isRequired,
	sites: PropTypes.array.isRequired,
};

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	const modalOpen = state.ui.sites.addSiteModalOpen;

	const errorFound = state.ui.sites.linkSiteFailed;

	const linkError = state.ui.sites.linkSiteError;

	const linkingSiteUrl = state.ui.sites.linkingSiteUrl;

	const sites = orderBy( getAllSites( state ), "hostname", "DESC" );

	return {
		modalOpen,
		errorFound,
		linkError,
		linkingSiteUrl,
		sites,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		loadSites: () => {
			dispatch( retrieveSites() );
		},
		onClick: () => {
			dispatch( linkSiteModalOpen() );
		},
		addSite: ( url ) => {
			dispatch( linkSiteModalOpen() );
			dispatch( updateSiteUrl( url ) );
		},
		onClose: () => {
			dispatch( linkSiteModalClose() );
		},
		onConnect: ( url, type ) => {
			dispatch( linkSite( url, type ) );
		},
		onChange: ( url ) => {
			dispatch( updateSiteUrl( url ) );
		},
		navigateToSites: () => {
			dispatch( push( "/sites" ) );
		},
	};
};

export const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
	const url = stateProps.linkingSiteUrl;

	const onConnect = ( type, fromHome ) => {
		dispatchProps.onConnect( url, type, fromHome );
	};

	const addSite = () => {
		dispatchProps.addSite( url );
	};

	return Object.assign( {}, ownProps, stateProps, dispatchProps, { onConnect, addSite } );
};

const SitesCardContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)( SitesCard );

export default injectIntl( SitesCardContainer );
