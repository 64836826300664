/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import validate from "validate.js";
import { speak } from "@wordpress/a11y";
import _debounce from "lodash/debounce";
/* Internal dependencies */
import { Button, SelectField, TextField } from "@yoast/ui-library";
import * as styles from "./AddSiteStyles.scss";

const messages = defineMessages( {
	validationFormatURL: {
		id: "validation.format.url",
		defaultMessage: "Please enter a valid URL. Remember to use http:// or https://.",
	},
	validationInvalidCharactersURL: {
		id: "validation.invalid.characters.url",
		defaultMessage: "Please do not enter your credentials in the URL.",
	},
	site: {
		id: "sites.addSite.enterUrl",
		defaultMessage: "Please enter the URL of the site you would like to add to your account.",
	},
	platform: {
		id: "sites.addSite.selectPlatform",
		defaultMessage: "Please select the platform that your website is running on.",
	},
} );

/**
 * Returns the AddSite component.
 *
 * @returns {ReactElement} The AddSite component.
 */
class AddSite extends React.Component {
	/**
	 * Initializes the class with the specified props.
	 *
	 * @param {Object} props The props to be passed to the class that was extended from.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.constraints = {
			url: this.urlConstraints.bind( this ),
		};

		this.state = {
			validationError: null,
			showValidationError: false,
			selectedValue: "wordpress",
		};

		// Defines the debounced function to run the validation check.
		this.runValidationDebounced = _debounce( this.runValidation, 500 );
		// Defines the debounced function to show the validation error.
		this.showValidationMessageDebounced  = _debounce( this.showValidationMessage, 1000 );
		// Defines the debounced function to announce the validation error.
		this.speakValidationMessageDebounced = _debounce( this.speakValidationMessage, 1000 );

		this.addCustomValidatorsToValidate = this.addCustomValidatorsToValidate.bind( this );
		this.onWebsiteURLChange = this.onWebsiteURLChange.bind( this );
		this.handleOnSubmit     = this.handleOnSubmit.bind( this );
		this.handleOnChange     = this.handleOnChange.bind( this );
		this.formatValidation   = this.formatValidation.bind( this );
	}

	/**
	 * Calls onChange function when website url changes.
	 *
	 * @param {Object} event The event returned by the WebsiteURLChange.
	 *
	 * @returns {void}
	 */
	onWebsiteURLChange( event ) {
		const value = event.target.value;
		this.props.onChange( value );
	}

	/**
	 * Runs url validation and shows/hides error if validation returns error.
	 *
	 * @param {string}  url       The url to validate.
	 * @param {boolean} debounced Whether to show the debounced error message.
	 *
	 * @returns {void}
	 */
	runValidation( url, debounced = true ) {
		const validationError = this.validateUrl( url );
		if ( validationError ) {
			this.updateValidationMessage( validationError );
			if ( debounced ) {
				this.showValidationMessageDebounced();
				this.speakValidationMessageDebounced();
			} else {
				this.showValidationMessage();
				this.speakValidationMessage();
			}
		} else {
			this.hideValidationError();
			this.updateValidationMessage( "" );
		}
	}

	/**
	 * Shows the validation error.
	 *
	 * @returns {void}
	 */
	showValidationMessage() {
		this.setState( { showValidationError: true } );
	}

	/**
	 * Updates the validation message.
	 *
	 * @param {string} message The validation message.
	 *
	 * @returns {void}
	 */
	updateValidationMessage( message ) {
		this.setState( {
			validationError: message,
		} );
	}

	/**
	 * Sets the constraints for validation to URL, and sets the message that should be returned if the constraints are not met.
	 *
	 * @returns {Object} Returns the constraints for the URL, and the message.
	 */
	urlConstraints() {
		return {
			url: {
				// Only allow http and https.
				schemes: [ "http", "https" ],
				// Allow local URLs.
				allowLocal: true,
				message: this.props.intl.formatMessage( messages.validationFormatURL ),
			},
			excludeSpecialCharacters: {
				message: this.props.intl.formatMessage( messages.validationInvalidCharactersURL ),
			},
		};
	}

	/**
	 * Validates URL and shows validation error if URL is invalid.
	 *
	 * @param {string} input The URL to be validated.
	 * @returns {string} URL Validation error message.
	 */
	validateUrl( input = "" ) {
		if ( input === "" ) {
			return "";
		}

		this.addCustomValidatorsToValidate();

		const result = validate( { website: input }, { website: this.urlConstraints() }, { format: "detailed" } );

		if ( result && result[ 0 ] !== null ) {
			return result[ 0 ].options.message;
		}

		return "";
	}

	/**
	 * Add custom validators for validateJS's validate().
	 *
	 * @returns {void} Nothing.
	 * @docs https://validatejs.org/
	 */
	addCustomValidatorsToValidate() {
		validate.validators.excludeSpecialCharacters = ( value, options ) => {
			try {
				const url = new URL( value );

				if ( url.username || url.password ) {
					return options.message;
				}
			} catch ( error ) {
				return this.props.intl.formatMessage( messages.validationFormatURL );
			}
		};
	}

	/**
	 * Hides the validation message and cancels a potential debounced error.
	 *
	 * @returns {void}
	 */
	hideValidationError() {
		this.setState( { showValidationError: false }, () => {
			this.showValidationMessageDebounced.cancel();
			this.speakValidationMessageDebounced.cancel();
		} );
	}

	/**
	 * Handles the submit event.
	 *
	 * @param {object} event The submit event.
	 *
	 * @returns {void}
	 */
	handleOnSubmit( event ) {
		event.preventDefault();
		if ( ! this.state.validationError && !! this.props.linkingSiteUrl ) {
			this.props.onConnectClick( this.state.selectedValue );
		}
	}

	/**
	 * Handles the blur event.
	 *
	 * @param {string} value The blur event.
	 *
	 * @returns {void}
	 */
	handleOnChange( value ) {
		this.setState( {
			selectedValue: value,
		} );
	}

	/**
	 * Sends a message to the ARIA live assertive region.
	 *
	 * @returns {void}
	 */
	speakValidationMessage() {
		const message = this.props.intl.formatMessage( messages.validationFormatURL );
		speak( message, "assertive" );
	}

	/**
	 * Populates the URL input field with the search term, if any, and runs validation.
	 *
	 * @returns {void}
	 */
	componentDidMount() {
		if ( this.props.query ) {
			this.props.onChange( this.props.query );
			this.runValidation( this.props.query, false );
		}
	}

	/**
	 * Validates the URL when entering a value in the URL input field.
	 *
	 * @param {object} nextProps The new props the component will receive.
	 *
	 * @returns {void}
	 */
	UNSAFE_componentWillReceiveProps( nextProps ) {
		if ( this.props.linkingSiteUrl !== nextProps.linkingSiteUrl ) {
			this.runValidationDebounced( nextProps.linkingSiteUrl, false );
		}
	}

	/**
	 * Cancels debounced functions when the component unmounts.
	 *
	 * @returns {void}
	 */
	componentWillUnmount() {
		this.showValidationMessageDebounced.cancel();
		this.speakValidationMessageDebounced.cancel();
	}

	/**
	 * Formats the validation error so that UI lib TextField can display it.
	 *
	 * @returns {{variant: string, message: (string|null)}} The formatted validation error.
	 */
	formatValidation() {
		let error;

		if ( this.props.error ) {
			error = this.props.error.message;
		} else {
			error = this.state.validationError;
		}

		return {
			variant: "error",
			message: error,
		};
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const isEnabled = ! this.state.validationError && !! this.props.linkingSiteUrl && ! this.props.error;

		return (
			<div id="add-site-modal">
				<form onSubmit={ this.handleOnSubmit } className={ styles.form }>
					<TextField
						type="url"
						id="add-site-input"
						label="Site URL"
						description={ this.props.intl.formatMessage( messages.site ) }
						placeholder="https://example-site.com"
						value={ this.props.linkingSiteUrl }
						onChange={ this.onWebsiteURLChange }
						validation={ this.formatValidation() }
					/>
					<SelectField
						id="add-site-select-platform"
						name="selectPlatform"
						value={ this.state.selectedValue }
						label="Platform"
						description={ this.props.intl.formatMessage( messages.platform ) }
						onChange={ this.handleOnChange }
						options={ [
							{ value: "wordpress", label: "WordPress" },
							{ value: "typo3", label: "TYPO3" },
						] }
					/>
					<div>
						<Button
							type="submit"
							disabled={ ! isEnabled }
							aria-disabled={ ! isEnabled }
						>
							<FormattedMessage id="sites.addSite.connect" defaultMessage="Add site" />
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

AddSite.propTypes = {
	intl: intlShape.isRequired,
	linkingSiteUrl: PropTypes.string,
	onCancelClick: PropTypes.func.isRequired,
	onConnectClick: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	query: PropTypes.string.isRequired,
};

AddSite.defaultProps = {
	linkingSiteUrl: "",
};

export default injectIntl( AddSite );
